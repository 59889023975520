import { Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import CenterBox from "components/common/centerBox";
import loadingSvg from "assets/images/icons/loading.svg";
import React from "react";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import ImageUploader from "components/common/imageUploader/custom";
import DataLabel from "components/common/dataLabel";
import defaultImage from "assets/images/default/default_x4.jpeg";

import { useState } from "react";
import {
  checkEmail,
  checkPassword,
  checkPhone,
  replaceId,
  replaceKorean,
  replaceNumber,
  replacePassword,
} from "utils/replaceText";
import CustomDialog from "components/common/customDialog";
import { getToday } from "utils";
import { useEffect } from "react";
import { useRegisterMember } from "api/userManagement";
import { customedAxios } from "api";
import { LoadingWrapper } from "components/common/imageUploader/styled";

const UserSign = () => {
  const [selectedText, setSelectedText] = useState("students");
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [nameCheck, setNameCheck] = useState("");
  const [email, setEmail] = useState("");
  const [emailCheck, setEmailCheck] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCheck, setPhoneCheck] = useState("");
  
  const { dialogComponent, handleDialogOpen } = CustomDialog(
    "회원 등록",
    "회원 정보를 등록하시겠습니까?"
    );
    useEffect(() => {
      localStorage.removeItem("imgIdx");
    }, []);
  
    
    
    const { mutate, data, reset } = useRegisterMember();
    const { uploadComponent, imageUploaded } =
    ImageUploader(undefined);
  
  useEffect(() => {
    if (data !== undefined) {
      if (data.status.code === 200) {
        localStorage.setItem("popupText", "회원이 등록되었습니다.");
        window.location.replace("/userManagement");
      } else if (data.status.code === 400) {
        alert(data.status.message);
      }
      reset();
    }
  }, [data, email, phone, reset]);

  return (
    <CenterBox>
      {isLoading && <LoadingWrapper>
        <img src={loadingSvg} alt="" />
      </LoadingWrapper>}
      {dialogComponent}
      <MainCard>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={4}>
            {uploadComponent}
            {/* <img src={sampleImage1} alt="image" style={{ width: "100%" }} /> */}
          </Grid>
          <Grid item xs={8}>
            <Grid
              item
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item xs={5}>
                <DataLabel
                  title={"회원 유형"}
                  children={
                    <Select
                      sx={{ width: "calc(100% - 120px)" }}
                      id="demo-simple-select"
                      value={selectedText}
                      onChange={(e) => {
                        setSelectedText(e.target.value);
                      }}
                    >
                      <MenuItem value={"expert"}>전문가</MenuItem>
                      <MenuItem value={"students"}>학습자</MenuItem>
                    </Select>
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <DataLabel
                  title={"가입일"}
                  children={
                    <TextField
                      value={getToday()}
                      disabled
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
            </Grid>
            <Grid item sx={{ paddingTop: "24px" }}>
              <DataLabel
                title={"이름"}
                children={
                  <TextField
                    placeholder="이름을 입력해주세요."
                    error={nameCheck}
                    helperText={nameCheck && "* 이름을 확인해주세요."}
                    value={name}
                    onChange={(e) => {
                      setName(replaceKorean(e.target.value));
                    }}
                    sx={{ width: "calc(100% - 120px)" }}
                  ></TextField>
                }
              />
            </Grid>
            <Grid item sx={{ paddingTop: "24px" }}>
              <DataLabel
                title={"회원 ID"}
                children={
                  <TextField
                    value={email}
                    placeholder="ID(이메일)를 입력해주세요."
                    error={emailCheck}
                    helperText={emailCheck && "* ID(이메일)를 확인해주세요."}
                    onChange={(e) => {
                      setEmail(replaceId(e.target.value));
                    }}
                    sx={{ width: "calc(100% - 120px)" }}
                  ></TextField>
                }
              />
            </Grid>
            <Grid item sx={{ paddingTop: "24px" }}>
              <DataLabel
                title={"회원 PW"}
                children={
                  <TextField
                    value={password}
                    error={passwordCheck}
                    helperText={passwordCheck && "* 비밀번호를 확인해주세요."}
                    placeholder="8~12자, 영문/숫자/특수문자 모두 포함"
                    onChange={(e) => {
                      setPassword(replacePassword(e.target.value));
                    }}
                    sx={{ width: "calc(100% - 120px)" }}
                  ></TextField>
                }
              />
            </Grid>
            <Grid item sx={{ paddingTop: "24px" }}>
              <DataLabel
                title={"휴대폰 번호"}
                children={
                  <TextField
                    value={phone}
                    placeholder="휴대폰 번호를 입력해주세요."
                    error={phoneCheck}
                    helperText={phoneCheck && "* 휴대폰 번호를 확인해주세요."}
                    onChange={(e) => {
                      setPhone(replaceNumber(e.target.value.slice(0, 11)));
                    }}
                    sx={{ width: "calc(100% - 120px)" }}
                  ></TextField>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
      <Grid sx={{ width: "100%", paddingTop: "24px", textAlign: "right" }}>
        <Button
          variant={"contained"}
          onClick={() => {
            let check = false;
            if (name === "") {
              setNameCheck(true);
              check = true;
            } else {
              setNameCheck(false);
            }
            if (email === "" || checkEmail(email)) {
              setEmailCheck(true);
              check = true;
            } else {
              setEmailCheck(false);
            }
            if (password === "" || checkPassword(password)) {
              setPasswordCheck(true);
              check = true;
            } else {
              setPasswordCheck(false);
            }
            if (phone === "" || checkPhone(phone)) {
              setPhoneCheck(true);
              check = true;
            } else {
              setPhoneCheck(false);
            }
            if (!check) {
              handleDialogOpen(() => {
                if (imageUploaded === 0) {
                  async function createFile(path, name, type) {
                    let response = await fetch(path);
                    let data = await response.blob();
                    let metadata = { type: type };
                    return new File([data], name, metadata);
                  }
                  const config = {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  };
                  createFile(defaultImage, "default.jpeg", "image/jpeg").then(
                    async (file) => {
                      const formData = new FormData();
                      formData.append("image", file);
                      setIsLoading(true);
                      customedAxios
                        .post(`/image/imageUpload`, formData, config)
                        .then((response) => {
                          setIsLoading(false);
                          localStorage.setItem("imgIdx", response.data.imgdata.imgIdx);
                          customedAxios
                            .post(`member/existingMemberEmail`, {
                              email: email,
                            })
                            .then((response) => {
                              if (response.data.status.code === 200) {
                                mutate({
                                  m_type: selectedText,
                                  m_name: name,
                                  m_email: email,
                                  m_phone: phone,
                                  m_passwd: password,
                                });
                              } else if (response.data.status.code === 400) {
                                alert(response.data.status.message);
                              }
                            });
                        });
                    }
                  );
                } else {
                  customedAxios
                    .post(`member/existingMemberEmail`, { email: email })
                    .then((response) => {
                      if (response.data.status.code === 200) {
                        mutate({
                          m_type: selectedText,
                          m_name: name,
                          m_email: email,
                          m_phone: phone,
                          m_passwd: password,
                        });
                      } else if (response.data.status.code === 400) {
                        alert(response.data.status.message);
                      }
                    });
                }
              });
            }
          }}
        >
          저장
        </Button>
      </Grid>
    </CenterBox>
  );
};

export default UserSign;

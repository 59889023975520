// action - state management
import * as actionTypes from "./actions";

export const initialState = {
  ad_idx: -1,
  ad_name: "",
  ad_username: "",
  ad_phone: "",
  ad_email: "",
  token: "",
};
// export const initialState = {
//   ad_idx: 12,
//   ad_name: "Sunyong Lee",
//   ad_username: "test",
//   ad_phone: "010-2634-6322",
//   ad_email: "led0323337@ninefive.com",
//   token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImFkX2lkeCI6MTIsImFkX25hbWUiOiJTdW55b25nIExlZSIsImFkX3VzZXJuYW1lIjoidGVzdCIsImFkX3Bob25lIjoiMDEwLTI2MzQtNjMyMiIsImFkX2VtYWlsIjoibGVkMDMyMzMzN0BuaW5lZml2ZS5jb20ifSwiaWF0IjoxNjk4Mzk2MjA2LCJleHAiOjE2OTg0MTc4MDZ9.GXisItklJHUCsbtcXlwzUpZmrRGwgqF1Rv2iFU291P8",
// };

// ==============================|| loginUserData REDUCER ||============================== //

const loginUserDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_USER:
      return {
        ...state,
        ad_idx: action.ad_idx,
        ad_name: action.ad_name,
        ad_username: action.ad_username,
        ad_phone: action.ad_phone,
        ad_email: action.ad_email,
        token: action.token,
      };
    case actionTypes.LOGOUT_USER:
      return {
        ...state,
        opened: action.opened,
      };
    default:
      return state;
  }
};

export default loginUserDataReducer;

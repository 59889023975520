import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useState } from "react";
import { TextField } from "@mui/material";
import CustomSnackbar from "components/common/customSnackbar";
import { useEffect } from "react";
import { checkPassword } from "utils/replaceText";
import { useChangeMemberPassword } from "api/userManagement";

const PasswordDialog = () => {
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [ispasswordEmpty, setPasswordEmpty] = useState(false);
  const [ispasswordSame, setPasswordSame] = useState(false);
  const { data, mutate, reset } = useChangeMemberPassword();
  const { snackbarComponent, handleSnackOpen } = CustomSnackbar();
  useEffect(() => {
    if (data !== undefined && data.status.code === 200) {
      handleSnackOpen("비밀번호 재설정이 완료되었습니다.");
      reset();
    }
  }, [data, reset, handleSnackOpen]);

  useEffect(() => {
    setPassword("");
    setPasswordCheck("");
    setPasswordEmpty(false);
    setPasswordSame(false);
  }, [open]);

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const actionClose = () => {
    var isPass = true;
    if (password === "") {
      setPasswordEmpty(true);
      isPass = false;
    } else {
      if (checkPassword(password)) {
        setPasswordEmpty(true);
        isPass = false;
      } else {
        setPasswordEmpty(false);
      }
    }
    if (password !== passwordCheck || passwordCheck === "") {
      setPasswordSame(true);
      isPass = false;
    } else {
      setPasswordSame(false);
    }
    if (isPass) {
      setOpen(false);
      mutate({ newPassword: password });
    }
  };
  const passwordComponent = (
    <>
      {snackbarComponent}
      <Dialog
        onClose={handleClose}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ m: 0, p: 2, fontSize: "16px", fontWeight: "bold" }}
        >
          비밀번호 재설정
        </DialogTitle>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <TextField
            error={ispasswordEmpty}
            helperText={ispasswordEmpty && "* 비밀번호를 확인해주세요."}
            autoFocus
            margin="dense"
            id="name"
            label="새 비밀번호"
            placeholder="8~12자, 영문/숫자/특수문자 모두 포함"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            fullWidth
            variant="standard"
          />
          <TextField
            error={ispasswordSame}
            helperText={ispasswordSame && "* 비밀번호가 일치하지 않습니다."}
            autoFocus
            margin="dense"
            id="name"
            label="비밀번호 확인"
            type="password"
            value={passwordCheck}
            onChange={(e) => {
              setPasswordCheck(e.target.value);
            }}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>취소</Button>
          <Button onClick={actionClose} autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
  return { handleDialogOpen, passwordComponent };
};

export default PasswordDialog;

import TestList from "views/test/Main";
import TestAISimulationAccuracy from "views/test/TestAISimulationAccuracy";
import TestDiffrenceAnalysis from "views/test/TestDiffrenceAnalysis";
import TestLandmarkAccuracy from "views/test/TestLandmarkAccuracy";
import TestSimilarityAccuracy from "views/test/TestSimilarityAccuracy";

// ==============================|| AUTHENTICATION ROUTING ||============================== //


const TestRoutes = {
  path: 'test',
  children: [
    {
      path: "",
      element: <TestList />
    },
    {
      path: "landmarkaccuracy",
      element: <TestLandmarkAccuracy />
    },
    {
      path: "similarityaccuracy",
      element: <TestSimilarityAccuracy />
    },
    {
      path: "diffrenceanalysis",
      element: <TestDiffrenceAnalysis />
    },
    {
      path: "aisimulationaccuracy",
      element: <TestAISimulationAccuracy />
    },
  ],
};

export default TestRoutes;

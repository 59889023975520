export function getToday() {
  var date = new Date();
  var year = date.getFullYear();
  var month = ("0" + (1 + date.getMonth())).slice(-2);
  var day = ("0" + date.getDate()).slice(-2);

  return year + "." + month + "." + day;
}

export function leftPad(value) {
  if (value >= 10) {
    return value;
  }

  return `0${value}`;
}

export function dateToStringFormat(source, delimiter = ".") {
  const year = source.getFullYear();
  const month = leftPad(source.getMonth() + 1);
  const day = leftPad(source.getDate());

  return [year, month, day].join(delimiter);
}
export function phoneAppendHyphen(phone) {
  return phone
    .replace(/[^0-9]/g, "")
    .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
}
export const faceList = [
  "달걀형",
  "사각형",
  "역삼각형",
  "사다리꼴형",
  "둥근형",
  "긴얼굴형",
];

export const eyebrowList = [
  "각진형",
  "세미아치형",
  "아치형",
  "일자형",
  "일자아치형",
  "각진일자형",
];

export function getS3Image(url) {
  return process.env.REACT_APP_IMAGE_BASE_URL + url;
}

import styled from "@emotion/styled";

export const CenterBoxStyle = styled.div`
  height: 100%;
  width: 70%;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > .MuiPaper-root {
    width: 100%;
  }
`;

import { customedAxios } from "api";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

export const useGetPigmentList = (searchValue) => {
  const { data, refetch } = useQuery("getPigmentList", () => {
    return customedAxios
      .get(
        `/pigment/getPigmentList?page=1&pageSize=1000&startDate=${searchValue.startDate}&endDate=${searchValue.endDate}&searchType=${searchValue.selectedText}&searchValue=${searchValue.searchText}`
      )
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch };
};

export const useDeletePigment = () => {
  const { data, mutate, reset } = useMutation(({ list }) => {
    return customedAxios
      .delete(`/pigment/deletePigment`, {
        data: { list },
      })
      .then((response) => {
        return response.data;
      });
  });
  return { mutate, data, reset };
};

export const useGetPigmentByIdx = () => {
  const [searchParams] = useSearchParams();
  const p_idx = Number(searchParams.get("p_idx"));
  const { data, refetch } = useQuery("getPigmentByIdx", () => {
    return customedAxios
      .get(`/pigment/getPigmentByIdx?p_idx=${p_idx}`)
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch };
};

export const useUpdatePigment = () => {
  const [searchParams] = useSearchParams();
  const p_idx = Number(searchParams.get("p_idx"));
  const img_idx =
    localStorage.getItem("imgIdx") == null
      ? 1
      : Number(localStorage.getItem("imgIdx"));
  const { data, mutate } = useMutation(
    ({
      p_name,
      p_productName,
      p_productDescription,
      p_productUrl,
      p_color1,
      p_color2,
      p_color3,
      p_color4,
      p_color5,
      p_color6,
      p_color7,
      p_color8,
      p_color9,
      p_color10,
    }) => {
      return customedAxios
        .put(`/pigment/updatePigment`, {
          p_idx,
          img_idx,
          p_name,
          p_productName,
          p_productDescription,
          p_productUrl,
          p_color1,
          p_color2,
          p_color3,
          p_color4,
          p_color5,
          p_color6,
          p_color7,
          p_color8,
          p_color9,
          p_color10,
        })
        .then((response) => {
          localStorage.removeItem("imgIdx");
          return response.data;
        });
    }
  );
  return { mutate, data };
};

export const useRegisterPigment = () => {
  const img_idx =
    localStorage.getItem("imgIdx") == null
      ? 1
      : Number(localStorage.getItem("imgIdx"));
  const { data, mutate, reset } = useMutation(
    ({
      p_name,
      p_productName,
      p_productDescription,
      p_productUrl,
      p_color1,
      p_color2,
      p_color3,
      p_color4,
      p_color5,
      p_color6,
      p_color7,
      p_color8,
      p_color9,
      p_color10,
    }) => {
      return customedAxios
        .post(`/pigment/register`, {
          img_idx,
          p_name,
          p_productName,
          p_productDescription,
          p_productUrl,
          p_color1,
          p_color2,
          p_color3,
          p_color4,
          p_color5,
          p_color6,
          p_color7,
          p_color8,
          p_color9,
          p_color10,
        })
        .then((response) => {
          localStorage.removeItem("imgIdx");
          return response.data;
        });
    }
  );
  return { mutate, reset, data };
};

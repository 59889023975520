import React from "react";
import { Box } from "@mui/system";
import CustomTable from "components/common/tables/customCheckTable";
import MainCard from "ui-component/cards/MainCard";
import { Button } from "@mui/material";
import CustomDialog from "components/common/customRemoveDialog";
import CustomSnackbar from "components/common/customSnackbar";
import { useEffect } from "react";
import UseAdminManagement from "hooks/adminManagement/useAdminManagement";

const AdminManagement = () => {
  const { snackbarComponent, handleSnackOpen } = CustomSnackbar();
  const { dialogComponent, handleDialogOpen } = CustomDialog(
    "관리자 계정 삭제",
    "선택한 항목을 삭제하시겠습니까?"
  );
  const {
    tableColumn,
    tableData,
    tableCheckAction,
    removeClickAction,
    selectedId,
    memberList,
  } = UseAdminManagement(handleSnackOpen, handleDialogOpen);

  useEffect(() => {
    const popupText = localStorage.getItem("popupText");
    if (popupText != null) {
      localStorage.removeItem("popupText");
      handleSnackOpen(popupText);
    }
  }, [handleSnackOpen]);

  return (
    <Box>
      {dialogComponent}
      {snackbarComponent}
      <MainCard sx={{ marginTop: "24px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "24px",
          }}
        >
          <Button variant="contained" color="error" onClick={removeClickAction}>
            삭제
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              localStorage.removeItem("adminDetailInfo");
              window.location.href = "/adminManagement/adminDetail";
            }}
          >
            관리자 등록
          </Button>
        </Box>
        {memberList !== undefined && <CustomTable
          selectedId={selectedId}
          tableCheckAction={tableCheckAction}
          data={tableData}
          column={tableColumn}
        />}
      </MainCard>
    </Box>
  );
};

export default AdminManagement;

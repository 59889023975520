import { useGetCaseByIdx } from "api/caseManagement";
import { useState } from "react";
import { useEffect } from "react";

const useCaseDetail = () => {
  const [caseData, setCaseData] = useState();
  const [name, setName] = useState("");
  const [face, setFace] = useState("");
  const [eyebrow, setEyebrow] = useState("");
  const [date, setDate] = useState("");
  const [manager, setManager] = useState("이선용");
  const [fit, setFit] = useState("");
  const [count, setCount] = useState("");
  const { data } = useGetCaseByIdx();
  const [caseCheck, setCaseCheck] = useState(false);
  useEffect(() => {
    if (data !== undefined) {
      if (data.status.code === 200) {
        setCaseData(data.status.result);
      }
    }
  }, [data]);

  useEffect(() => {
    if (caseData !== undefined) {
      setName(caseData.c_name);
      setFace(caseData.c_face);
      setDate(caseData.c_regDate);
      setManager(caseData.ad_name);
      setFit(caseData.expertTrainingCnt);
      setCount(caseData.totTrainingCnt);
      setEyebrow(caseData.c_eyebrow);
      setCaseCheck(caseData.c_practice === 'Y');
    }
  }, [caseData]);

  return {
    caseData,
    name,
    setName,
    face,
    setFace,
    date,
    setDate,
    manager,
    setManager,
    fit,
    setFit,
    count,
    setCount,
    eyebrow,
    setEyebrow,
    caseCheck,
    setCaseCheck
  };
};

export default useCaseDetail;

import { Chip } from "@mui/material";
import {
  useGetWaitingExpertList,
  useUpdateExpertStatus,
} from "api/userManagement";
import { useEffect } from "react";
import { useState } from "react";
import { dateToStringFormat, leftPad, phoneAppendHyphen } from "utils";

const useExpertAccept = (
  handleSnackOpen,
  acceptDialogShow,
  cancelDialogShow
) => {
  const [searchValue, setSearchValue] = useState({
    startDate: "",
    endDate: "",
    selectedText: "",
    searchText: "",
  });
  const searchAction = (startDate, endDate, selectedText, searchText) => {
    setSearchValue({
      startDate:
        startDate === undefined || startDate === null
          ? ""
          : startDate.$y +
            "-" +
            leftPad(startDate.$M + 1) +
            "-" +
            leftPad(startDate.$D),
      endDate:
        endDate == null
          ? ""
          : endDate.$y +
            "-" +
            leftPad(endDate.$M + 1) +
            "-" +
            leftPad(endDate.$D),
      selectedText,
      searchText,
    });
  };

  const dropdownList = [
    { value: "m_name", text: "이름" },
    { value: "m_email", text: "아이디" },
  ];
  const DateTextList = ["가입일", "시작일", "종료일"];
  const tableColumn = [
    { field: "id", headerName: "No", width: 100 },
    { field: "m_name", headerName: "이름", width: 150 },
    { field: "m_email", headerName: "아이디(이메일)", width: 250 },
    {
      field: "m_phone",
      headerName: "휴대폰 번호",
      width: 150,
      renderCell: (param) => {
        return phoneAppendHyphen(param.value);
      },
    },

    { field: "m_regDate", headerName: "가입일", width: 150 },
    {
      field: "m_expert_allow",
      headerName: "상태",
      width: 150,
      renderCell: (param) => {
        return param.value === "L" ? (
          <Chip label="대기" />
        ) : (
          <Chip label="반려" color="error" />
        );
      },
    },
  ];
  const [selectedId, setSelectedId] = useState([]);
  const [tableData, setTableData] = useState([]);
  const { data: memberList, refetch } = useGetWaitingExpertList(searchValue);
  const { data: updateResponse, mutate } = useUpdateExpertStatus();

  useEffect(() => {
    refetch();
  }, [searchValue, refetch]);

  useEffect(() => {
    if (updateResponse !== undefined) {
      if (updateResponse.status.code === 200) {
        refetch();
      }
    }
  }, [updateResponse, refetch]);

  useEffect(() => {
    if (memberList !== undefined) {
      var tableData = memberList.result;
      tableData.map((value, index) => {
        tableData[index].id = value.m_idx;
        tableData[index].id = index + 1;
        var regDate = new Date(value.m_regDate.split("T")[0]);
        tableData[index].m_regDate = dateToStringFormat(regDate, "-");
        return 0;
      });
      setTableData(tableData);
    }
  }, [memberList]);
  const removeAction = (status) => {
    var list = [];
    selectedId.map((value) => {
      list.push(tableData[value - 1].m_idx);
      return 0;
    });
    mutate({ status: status, list: list });
    // let removeTableData = tableData;
    // selectedId.map(
    //   (value) =>
    //     (removeTableData = removeTableData.filter((item) => item.id !== value))
    // );
    // setTableData(removeTableData);
    setSelectedId([]);
  };
  const tableCheckAction = (ids) => {
    setSelectedId(ids);
  };

  const acceptClickAction = () => {
    if (selectedId.length === 0) {
      handleSnackOpen("선택한 항목이 없습니다.");
    } else {
      acceptDialogShow(() => {
        removeAction("Y");
      });
    }
  };
  const cancelClickAction = () => {
    if (selectedId.length === 0) {
      handleSnackOpen("선택한 항목이 없습니다.");
    } else {
      cancelDialogShow(() => {
        removeAction("N");
      });
    }
  };

  return {
    searchAction,
    dropdownList,
    DateTextList,
    tableColumn,
    tableData,
    selectedId,
    tableCheckAction,
    acceptClickAction,
    cancelClickAction,
    memberList,
  };
};

export default useExpertAccept;

import { Button, Chip, Divider, Grid, TextField } from "@mui/material";
import CenterBox from "components/common/centerBox";
import React from "react";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import DataLabel from "components/common/dataLabel";
import { useState } from "react";
import { useEffect } from "react";
import { Box } from "@mui/system";
import { dateToStringFormat, getToday } from "utils";
import CustomDialog from "components/common/customDialog";
import { useGetInquiryByIdx, useRegisterInquiryAnswer } from "api/serviceManagement";
import { useSelector } from "react-redux";

const ServiceDetail = () => {
  const [date, setDate] = useState("");
  const [student, setStudent] = useState("");
  const [name, setName] = useState("");
  const [detail, setDetail] = useState("");
  const [answer, setAnswer] = useState("");
  const [expert, setExpert] = useState("");
  const [result, setResult] = useState("");
  const [isSign, setIsSign] = useState(false);
  const { dialogComponent, handleDialogOpen } = CustomDialog(
    isSign ? "1:1 문의 답변 등록" : "1:1 문의 답변 수정",
    isSign ? "답변을 등록하시겠습니까?" : "수정된 내용으로 저장하시겠습니까?"
  );

  const { data } = useGetInquiryByIdx();
  const { data:answerResponse,mutate } = useRegisterInquiryAnswer();
  const loginUserData = useSelector((state) => state.loginUserData);

  useEffect(() => {
    if (answerResponse !== undefined) {
      if (answerResponse.status.code === 200) {
        localStorage.setItem(
          "popupText",
          isSign ? "답변 등록이 완료되었습니다." : "문의 답변이 수정되었습니다."
        );
        window.location.replace("/serviceManagement");
      } else if (
        answerResponse.status.code === 400 &&
        answerResponse.status.message.includes("jwt")
      ) {
        alert("로그인 권한이 만료되었습니다. 다시 로그인해주세요.");
        window.location.replace("/");
      }
    }
  }, [answerResponse, isSign]);
  

  useEffect(() => {
    if (data !== undefined) {
      setDate(
        dateToStringFormat(new Date(data.status.result.i_regDate.split("T")[0]))
      );
      setStudent(data.status.result.m_name);
      setName(data.status.result.i_title);
      setDetail(data.status.result.i_contents);
      if (data.status.result.i_status === "N") {
        setIsSign(true);
        setResult("");
        setAnswer(getToday());
        setExpert(loginUserData.ad_name);
      } else {
        setIsSign(false);
        setAnswer(
          dateToStringFormat(
            new Date(data.status.result.i_answerDate.split("T")[0])
          )
        );
        setResult(data.status.result.i_answer);
        setExpert(data.status.result.ad_name);
      }
    }
  }, [data, loginUserData]);

  return (
    <CenterBox>
      {dialogComponent}
      {data !== undefined && (
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid item xs={3.5}>
                  <DataLabel
                    title={"작성일"}
                    children={
                      <TextField
                        inputProps={{ readOnly: true }}
                        value={date}
                        onChange={(e) => {
                          setDate(e.target.value);
                        }}
                        sx={{ width: "calc(100% - 100px)" }}
                      ></TextField>
                    }
                  />
                </Grid>
                <Grid item xs={3.5}>
                  <DataLabel
                    title={"작성자"}
                    children={
                      <TextField
                        inputProps={{ readOnly: true }}
                        value={student}
                        onChange={(e) => {
                          setStudent(e.target.value);
                        }}
                        sx={{ width: "calc(100% - 100px)" }}
                      ></TextField>
                    }
                  />
                </Grid>
                <Grid item xs={3.5} sx={{ "& >div": { height: "100%" } }}>
                  <DataLabel
                    title={"상태"}
                    children={
                      <Box
                        sx={{
                          width: "calc(100% - 100px)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                          background: "#f8fafc",
                          border: "1px solid rgba(0, 0, 0, 0.26)",
                          borderRadius: "12px",
                        }}
                      >
                        {!isSign ? (
                          <Chip label="답변완료" color="primary" />
                        ) : (
                          <Chip label="답변예정" />
                        )}
                      </Box>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  "& >div": { width: "100%" },
                }}
              >
                <DataLabel
                  title={"제목"}
                  children={
                    <TextField
                      inputProps={{ readOnly: true }}
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      sx={{ width: "calc(100% - 100px)" }}
                    ></TextField>
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  "& >div": { width: "100%" },
                }}
              >
                <DataLabel
                  title={"내용"}
                  children={
                    <TextField
                      inputProps={{ readOnly: true }}
                      multiline
                      minRows={6}
                      maxRows={6}
                      value={detail}
                      onChange={(e) => {
                        setDetail(e.target.value);
                      }}
                      sx={{ width: "calc(100% - 100px)" }}
                    ></TextField>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ margin: "24px 0" }} />
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid item xs={3.5}>
                  <DataLabel
                    title={"답변등록일"}
                    children={
                      <TextField
                        inputProps={{ readOnly: true }}
                        value={answer}
                        onChange={(e) => {
                          setAnswer(e.target.value);
                        }}
                        sx={{ width: "calc(100% - 100px)" }}
                      ></TextField>
                    }
                  />
                </Grid>
                <Grid item xs={3.5}>
                  <DataLabel
                    title={"작성자"}
                    children={
                      <TextField
                        inputProps={{ readOnly: true }}
                        value={expert}
                        onChange={(e) => {
                          setExpert(e.target.value);
                        }}
                        sx={{ width: "calc(100% - 100px)" }}
                      ></TextField>
                    }
                  />
                </Grid>
                <Grid item xs={3.5}></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  "& >div": { width: "100%" },
                }}
              >
                <DataLabel
                  title={"내용"}
                  children={
                    <TextField
                      multiline
                      placeholder="내용을 입력해주세요"
                      minRows={6}
                      maxRows={6}
                      value={result}
                      onChange={(e) => {
                        setResult(e.target.value);
                      }}
                      sx={{ width: "calc(100% - 100px)" }}
                    ></TextField>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </MainCard>
      )}
      <Grid sx={{ width: "100%", paddingTop: "24px", textAlign: "right" }}>
        <Button
          variant={"contained"}
          disabled={result === ""}
          onClick={() => {
            handleDialogOpen(() => {
              mutate({
                i_answer: result,
              });
            });
          }}
        >
          {isSign ? "등록" : "저장"}
        </Button>
      </Grid>
    </CenterBox>
  );
};

export default ServiceDetail;

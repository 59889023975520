import LoginBox from "components/login/loginBox";
import useLoginAction from "hooks/login/useLoginAction";
import React from "react";
const Login = () => {
  const { idCheck, loginAction, passwordCheck } = useLoginAction();
  return (
    <LoginBox
      idCheck={idCheck}
      passwordCheck={passwordCheck}
      loginAction={loginAction}
    />
  );
};

export default Login;

import styled from "@emotion/styled";
import { useDrawingArea } from "@mui/x-charts";

export const MemberStatChartStyle = styled.div`
  width: 100%;
  position: relative;
  > div:first-of-type {
    /* width: 130px; */
    text-align: left;
  }
`;

export const CenterTopText = styled.text`
  text-anchor: "middle";
  font-size: 20;
`;

const StyledText = styled("text")(() => ({
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 20,
}));

export const PieTopLabel = ({ children }) => {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2 - 12}>
      {children}
    </StyledText>
  );
};
export const PieBottomLabel = ({ children }) => {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2 + 12}>
      {children}
    </StyledText>
  );
};

import { Link } from "react-router-dom";

import TestHeader from "../Components/Header";

const TestList = () => {
  const Style = () => {
    return (
      <style jsx='true'>
        {`
          ul li + li {
            margin-top: 10px
          }
        `}
      </style>
    )
  }
  return (
    <>
      <TestHeader />
      <Style />
      <div>
        <ul>
          <li>
            <Link to={'/test/landmarkaccuracy'}>시술 이미지 데이터랜드마크 처리 정확도</Link>
          </li>
          <li>
            <Link to={'/test/similarityaccuracy'}>얼굴형 이미지 유사도 판별 알고리즘 정확도</Link>
          </li>
          <li>
            <Link to={'/test/diffrenceanalysis'}>학습자 시술물과 최적 시술 사례간 유사도 평가 및 차이점 분석 알고리즘</Link>
          </li>
          <li>
            <Link to={'/test/aisimulationaccuracy'}>AI 시술 시뮬레이션 구현 정확도 </Link>
          </li>
        </ul>
      </div>
    </>
  )
}
export default TestList; 

import {
  Button,
  ButtonGroup,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { SearchInputStyle, SearchStyle } from "./styled";
import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import dayjs from "dayjs";

const Search = ({ searchAction, dropdownList, DateTextList }) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchText, setSearchText] = useState("");
  const [selectedButton, setSelectedButton] = useState("");

  const startDateAction = (value) => {
    setStartDate(value);
    setSelectedButton("");
  };
  const endDateAction = (value) => {
    setEndDate(value);
    setSelectedButton("");
  };

  const setDateDayAction = (days) => {
    var result = new Date();
    result.setDate(result.getDate() - days);
    setStartDate(dayjs(result));
    setEndDate(dayjs(new Date()));
  };

  const setDateMonthAction = (month) => {
    var result = new Date();
    result.setMonth(result.getMonth() - month);
    setStartDate(dayjs(result));
    setEndDate(dayjs(new Date()));
  };

  const resetAction = () => {
    setStartDate(null);
    setEndDate(null);
    setSearchText("");
    setSelectedButton("");
  };

  return (
    <MainCard>
      <Grid container item spacing={3}>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <SearchStyle>
            <Typography
              sx={{ width:'72px', paddingTop: "8px", fontWeight: "bold" }}
            >
              {DateTextList[0]}
            </Typography>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              label={DateTextList[1]}
            >
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  format="YYYY-MM-DD"
                  slotProps={{ textField: { inputProps: { readOnly: true } } }}
                  label={DateTextList[1]}
                  value={startDate}
                  onChange={(newValue) => startDateAction(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
            <Divider
              sx={{
                width: 18,
                margin: "0 12px",
                paddingTop: "8px",
              }}
            />
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              label={DateTextList[2]}
            >
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  format="YYYY-MM-DD"
                  slotProps={{ textField: { inputProps: { readOnly: true } } }}
                  minDate={startDate}
                  label={DateTextList[2]}
                  value={endDate}
                  onChange={(newValue) => endDateAction(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              sx={{ marginLeft: 4, paddingTop: "8px" }}
            >
              <Button
                sx={{
                  backgroundColor:
                    selectedButton === "1" ? "#2196f3 !important" : "",
                  color: selectedButton === "1" ? "black !important" : "",
                }}
                onClick={() => {
                  setDateDayAction(0);
                  setSelectedButton("1");
                }}
              >
                금일
              </Button>
              <Button
                sx={{
                  backgroundColor:
                    selectedButton === "2" ? "#2196f3 !important" : "",
                  color: selectedButton === "2" ? "black !important" : "",
                }}
                onClick={() => {
                  setDateDayAction(7);
                  setSelectedButton("2");
                }}
              >
                1주일
              </Button>
              <Button
                sx={{
                  backgroundColor:
                    selectedButton === "3" ? "#2196f3 !important" : "",
                  color: selectedButton === "3" ? "black !important" : "",
                }}
                onClick={() => {
                  setDateMonthAction(1);
                  setSelectedButton("3");
                }}
              >
                1개월
              </Button>
              <Button
                sx={{
                  backgroundColor:
                    selectedButton === "4" ? "#2196f3 !important" : "",
                  color: selectedButton === "4" ? "black !important" : "",
                }}
                onClick={() => {
                  setDateMonthAction(3);
                  setSelectedButton("4");
                }}
              >
                3개월
              </Button>
              <Button
                sx={{
                  backgroundColor:
                    selectedButton === "5" ? "#2196f3 !important" : "",
                  color: selectedButton === "5" ? "black !important" : "",
                }}
                onClick={() => {
                  setDateMonthAction(6);
                  setSelectedButton("5");
                }}
              >
                6개월
              </Button>
            </ButtonGroup>
          </SearchStyle>
          <Grid sx={{ paddingTop: "8px" }}>
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              sx={{ mr: "12px" }}
              onClick={() => {
                searchAction(startDate, endDate, "p_productName", searchText);
              }}
            >
              조회
            </Button>
            <Button
              variant="outlined"
              startIcon={<RestartAltIcon />}
              onClick={resetAction}
            >
              초기화
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchInputStyle>
            <Typography
              sx={{ width:'72px', fontWeight: "bold" }}
            >
              제품명
            </Typography>
            <Grid item xs={11.5}>
              <TextField
                sx={{ width: "100%" }}
                value={searchText}
                placeholder="검색어를 입력해주세요."
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </Grid>
          </SearchInputStyle>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default Search;

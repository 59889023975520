import styled from "@emotion/styled";

export const ShapeMultiWrap = styled.div`
    width: 600px;
`;
export const ShapeWrap = styled.div`
  position: relative;
  display: inline-flex;
  padding: 12px 24px;
  padding-bottom: 2px;
  background: lightgray;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > div {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }
  > p {
    margin: 0;
    margin-top: 4px;
  }
  > svg {
    position: absolute;
    right: 15px;
    top: 5px;
    transform: scale(1.2);
    background: white;
    border-radius: 50%;
  }
  .MuiSlider-valueLabelOpen {
    background: none;
    padding-bottom: 0;
    color: "black";
  }
  > div > div {
    position: relative;
    margin-left: 24px;
    height: 114px;
    display: flex;
    align-items: flex-end;
  }
  > div > div:first-of-type {
    margin: 0 24px 0 0;
    /* width: 64px !important; */
    width: 100px !important;
  }
  > div > div > p {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    margin: 0;
  }
  > div > div > div {
    background: white;
    border: 1px dashed black;
    width: 90px;
    height: 90px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

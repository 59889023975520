import MainLayout from "layout/MainLayout";
import UserSign from "views/userManagement/userSign";
import WithdrawalList from "views/userManagement/WithdrawalList";
import ExpertAccept from "views/userManagement/expertAccept";
import UserDetail from "views/userManagement/userDetail";
import CaseManagement from "views/caseManagement";
import CaseDetail from "views/caseManagement/caseDetail";
import CaseSign from "views/caseManagement/caseSign";
import StudentManagement from "views/studentManagement";
import StudentDetail from "views/studentManagement/studentDetail";
import ExpertManagement from "views/expertManagement";
import ExpertDetail from "views/expertManagement/expertDetail";
import NeedleManagement from "views/neddleManagement";
import NeddleDetail from "views/neddleManagement/neddleDetail";
import NeddleAppend from "views/neddleManagement/neddleAppend";
import ColorManagement from "views/colorManagement";
import ColorDetail from "views/colorManagement/colorDetail";
import ColorAppend from "views/colorManagement/colorAppend";
import FeedbackManagement from "views/feedbackManagement";
import QAManagement from "views/qaManagement";
import QADetail from "views/qaManagement/qaDetail";
import ServiceManagement from "views/serviceManagement";
import ServiceDetail from "views/serviceManagement/serviceDetail";
import NoticeManagement from "views/noticeManagement";
import NoticeDetail from "views/noticeManagement/noticeDetail";
import AdminDetail from "views/adminManagement/adminDetail";
import AdminManagement from "views/adminManagement";
import UserManagement from "views/userManagement";
import DashboardDefault from "views/dashboard/Default";

const MainRoutes = {
  element: <MainLayout />,
  children: [
    {
      path: "dashboard",
      children: [
        {
          path: "default",
          element: <DashboardDefault />,
        },
      ],
    },
    {
      path: "userManagement",
      children: [
        {
          path: "",
          element: <UserManagement />,
        },
        {
          path: "userDetail",
          element: <UserDetail />,
        },
        {
          path: "userSign",
          element: <UserSign />,
        },
        {
          path: "WithdrawalList",
          element: <WithdrawalList />,
        },
        {
          path: "ExpertAccept",
          element: <ExpertAccept />,
        },
      ],
    },
    {
      path: "caseManagement",
      children: [
        { path: "", element: <CaseManagement /> },
        { path: "caseDetail", element: <CaseDetail /> },
        { path: "caseSign", element: <CaseSign /> },
      ],
    },
    {
      path: "studentManagement",
      children: [
        { path: "", element: <StudentManagement /> },
        { path: "studentDetail", element: <StudentDetail /> },
        { path: "expertManagement", element: <ExpertManagement /> },
        {
          path: "expertManagement/expertDetail",
          element: <ExpertDetail />,
        },
      ],
    },
    {
      path: "neddleManagement",
      children: [
        { path: "", element: <NeedleManagement /> },
        { path: "neddleDetail", element: <NeddleDetail /> },
        { path: "neddleAppend", element: <NeddleAppend /> },
        { path: "colorManagement", element: <ColorManagement /> },
        { path: "colorManagement/colorDetail", element: <ColorDetail /> },
        { path: "colorManagement/colorAppend", element: <ColorAppend /> },
      ],
    },
    {
      path: "feedbackManagement",
      children: [{ path: "", element: <FeedbackManagement /> }],
    },
    {
      path: "QAManagement",
      children: [
        { path: "", element: <QAManagement /> },
        { path: "qaDetail", element: <QADetail /> },
      ],
    },
    {
      path: "serviceManagement",
      children: [
        { path: "", element: <ServiceManagement /> },
        { path: "serviceDetail", element: <ServiceDetail /> },
        { path: "noticeManagement", element: <NoticeManagement /> },
        { path: "noticeManagement/noticeDetail", element: <NoticeDetail /> },
      ],
    },
    {
      path: "adminManagement",
      children: [
        { path: "", element: <AdminManagement /> },
        { path: "adminDetail", element: <AdminDetail /> },
      ],
    },
  ],
};

export default MainRoutes;

import { Button } from "@mui/material";
import { useDeleteNotice, useGetNoticeList } from "api/noticeManagement";
import { useEffect } from "react";
import { useState } from "react";
import { dateToStringFormat, leftPad } from "utils";

const UseNoticeManagement = (handleSnackOpen, handleDialogOpen) => {
  const [searchValue, setSearchValue] = useState({
    startDate: "",
    endDate: "",
    selectedText: "",
    searchText: "",
  });
  const searchAction = (startDate, endDate, selectedText, searchText) => {
    setSearchValue({
      startDate:
        startDate === undefined || startDate === null
          ? ""
          : startDate.$y +
            "-" +
            leftPad(startDate.$M + 1) +
            "-" +
            leftPad(startDate.$D),
      endDate:
        endDate == null
          ? ""
          : endDate.$y +
            "-" +
            leftPad(endDate.$M + 1) +
            "-" +
            leftPad(endDate.$D),
      selectedText,
      searchText,
    });
  };
  const { data: memberList, refetch } = useGetNoticeList(searchValue);
  const { data: quitResponse, mutate } = useDeleteNotice();
  useEffect(() => {
    refetch();
  }, [searchValue, refetch]);

  useEffect(() => {
    if (quitResponse !== undefined) {
      if (quitResponse.status.code === 200) {
        refetch();
      }
    }
  }, [quitResponse, refetch]);
  const dropdownList = [
    { value: "no_title", text: "제목" },
    { value: "ad_name", text: "작성자" },
  ];
  const DateTextList = ["작성일", "시작일", "종료일"];
  const tableColumn = [
    { field: "id", headerName: "No", width: 150 },
    { field: "no_title", headerName: "제목", width: 250, flex: 1 },
    { field: "ad_name", headerName: "작성자", width: 250 },
    { field: "no_regDate", headerName: "작성일", width: 250 },
    {
      field: "manage",
      headerName: "관리",
      width: 100,
      sortable: false,
      renderCell: (param) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              localStorage.setItem(
                "noticeDetailInfo",
                JSON.stringify(param.row)
              );
              window.location.href =
                "/serviceManagement/noticeManagement/noticeDetail?no_idx="+param.row.no_idx;
            }}
          >
            상세
          </Button>
        );
      },
    },
  ];
  const [selectedId, setSelectedId] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (memberList !== undefined) {
      var tableData = memberList.result;

      tableData.map((value, index) => {
        tableData[index].id = index + 1;
        var date = new Date(value.no_regDate.split("T")[0]);
        tableData[index].no_regDate = dateToStringFormat(date, "-");
        return 0;
      });
      setTableData(tableData);
    }
  }, [memberList]);

  const removeAction = () => {
    var list = [];
    selectedId.map((value) => {
      list.push(tableData[value - 1].no_idx);
      return 0;
    });
    mutate({ list: list });
    setSelectedId([]);
    handleSnackOpen("삭제 완료되었습니다.");
  };
  const tableCheckAction = (ids) => {
    setSelectedId(ids);
  };

  const removeClickAction = () => {
    if (selectedId.length === 0) {
      handleSnackOpen("선택한 항목이 없습니다.");
    } else {
      handleDialogOpen(removeAction);
    }
  };

  return {
    searchAction,
    dropdownList,
    DateTextList,
    tableColumn,
    tableData,
    tableCheckAction,
    removeClickAction,
    selectedId,
    memberList,
  };
};

export default UseNoticeManagement;

// imports the React Javascript Library
import React from "react";
import { Grid, Typography } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useState } from "react";
import loadingSvg from "assets/images/icons/loading.svg";
import CustomImage from "../customImage";
import { CustomCircleWrap, CustomImageLabel, LoadingWrapper } from "./styled";
import { useImageUpload } from "api/imageUpload";
import { useEffect } from "react";

const ImageUpload = (init) => {
  const [imageUploaded, setImageUploaded] = useState(
    init === undefined ? 0 : 1
  );
  const [selectedFile, setSelectedFile] = useState(
    init === undefined ? "" : init
  );

  useEffect(() => {
    setImageUploaded(init === undefined ? 0 : 1);
    setSelectedFile(init === undefined ? "" : init);
  }, [init]);

  const { data, mutate, isLoading, reset } = useImageUpload();
  useEffect(() => {
    if (data !== undefined) {
      if (data.status.code === 200) {
        localStorage.setItem("imgIdx", data.imgdata.imgIdx);
        reset();
      } else if (data.status.code === 400) {
        alert(data.status.message);
        setImageUploaded(0);
        setSelectedFile("");
        reset();
      }
    }
  }, [data, reset]);

  useEffect(() => {
    if (data !== undefined) {
      if (data.status.code === 200) {
        localStorage.setItem("imgIdx", data.imgdata.imgIdx);
      }
    }
  }, [data]);

  const uploadClick = (e) => {
    var file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      setSelectedFile([reader.result]);
    };
    setImageUploaded(imageUploaded + 1);
    setSelectedFile(e.target.files[0]);

    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    mutate({ formData });
    e.target.value = "";
  };

  const uploadComponent = (
    <>
      {isLoading && (
        <LoadingWrapper>
          <img src={loadingSvg} alt="" />
        </LoadingWrapper>
      )}

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        position="relative"
        width={"200px"}
        height={"200px"}
        margin="0 auto"
      >
        <Grid item>
          {selectedFile !== "" ? (
            <CustomImage maxSize={200} src={selectedFile} />
          ) : (
            <CustomCircleWrap>
              <label htmlFor="contained-button-file">이미지 업로드</label>
            </CustomCircleWrap>
          )}
          <input
            style={{
              opacity: 0,
              display: "none",
            }}
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            onChange={uploadClick}
          />
          {selectedFile !== "" && (
            <CustomImageLabel htmlFor="contained-button-file">
              <CameraAltIcon />
            </CustomImageLabel>
          )}
        </Grid>
        {window.location.href.includes("userSign") && (
          <Typography
            sx={{
              position: "absolute",
              bottom: "-48px",
            }}
          >
            * 프로필 이미지 미등록 시,
            <br />
            기본 이미지로 설정됩니다
          </Typography>
        )}
      </Grid>
    </>
  );

  return { imageUploaded, uploadComponent };
};

export default ImageUpload;

import styled from "@emotion/styled";

export const SearchStyle = styled.div`
  width: auto;
  display: inline-flex;
  align-items: center;
`;

export const SearchInputStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .MuiTextField-root {
    flex: 1;
  }
`;

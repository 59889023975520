import { useGetPopularCase } from "api/dashboard";
import { useState } from "react";
import { useEffect } from "react";

const UseCaseAction = () => {
  const [sampleData, setSampleData] = useState([]);
  const { data } = useGetPopularCase();

  useEffect(() => {
    var sampleData = [];
    if (data !== undefined) {
      data.result.map((value) => {
        var imageSource = process.env.REACT_APP_IMAGE_BASE_URL + value.img_name;
        var title = value.c_name;
        var subText = `${value.c_face} / ${value.c_eyebrow}`;
        var writer = `전문가명 : ${value.ad_name}`;
        sampleData.push({
          indexNumber: value.c_idx,
          imageSource: imageSource,
          title: title,
          subText: subText,
          writer: writer,
        });
        return 0;
      });
      setSampleData(sampleData);
    }
  }, [data]);

  const clickAction = (sampleCode) => {
    if (sampleCode === undefined) {
      window.location.href = "/caseManagement";
    } else {
      window.location.href = "/caseManagement/caseDetail?c_idx=" + sampleCode;
    }
  };
  return { sampleData, clickAction };
};

export default UseCaseAction;

import { customedAxios } from "api";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

export const useGetMemberList = (searchValue) => {
  const { data, refetch } = useQuery("getMemberList", () => {
    return customedAxios
      .get(
        `/member/getMemberList?page=1&pageSize=1000&startDate=${searchValue.startDate}&endDate=${searchValue.endDate}&searchType=${searchValue.selectedText}&searchValue=${searchValue.searchText}&m_type=${searchValue.selectedType}&m_status=Y`
      )
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch };
};

export const usePutQuitMember = () => {
  const { data, mutate } = useMutation(({ list }) => {
    return customedAxios
      .put(`/member/quitMember`, {
        list,
      })
      .then((response) => {
        return response.data;
      });
  });
  return { mutate, data };
};

export const useGetQuitMemberList = (searchValue) => {
  const { data, refetch } = useQuery("getQuitMemberList", () => {
    return customedAxios
      .get(
        `/member/getQuitMemberList?page=1&pageSize=1000&startDate=${searchValue.startDate}&endDate=${searchValue.endDate}&searchType=${searchValue.selectedText}&searchValue=${searchValue.searchText}`
      )
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch };
};

export const useGetWaitingExpertList = (searchValue) => {
  const { data, refetch } = useQuery("getWaitingExpertList", () => {
    return customedAxios
      .get(
        `/member/getWaitingExpertList?page=1&pageSize=1000&startDate=${searchValue.startDate}&endDate=${searchValue.endDate}&searchType=${searchValue.selectedText}&searchValue=${searchValue.searchText}`
      )
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch };
};

export const useUpdateExpertStatus = () => {
  const { data, mutate } = useMutation(({ status, list }) => {
    return customedAxios
      .put(`/member/updateExpertStatus`, {
        status,
        list,
      })
      .then((response) => {
        return response.data;
      });
  });
  return { mutate, data };
};

export const useChangeMemberPassword = () => {
  const [searchParams] = useSearchParams();
  const m_idx = searchParams.get("m_idx");
  const { data, mutate, reset } = useMutation(({ newPassword }) => {
    return customedAxios
      .put(`member/changeMemberPassword`, {
        m_idx,
        newPassword,
      })
      .then((response) => {
        return response.data;
      });
  });
  return { mutate, data, reset };
};

export const useGetMemberByIdx = (m_idx) => {
  const { data } = useQuery("getMemberByIdx", () => {
    return customedAxios
      .get(`/member/getMemberByIdx?m_idx=${m_idx}`)
      .then((response) => {
        return response.data;
      });
  });
  return { data };
};

export const useUpadteMember = () => {
  const [searchParams] = useSearchParams();
  const m_idx = Number(searchParams.get("m_idx"));
  const m_imgIdx =
    localStorage.getItem("imgIdx") == null
      ? 1
      : Number(localStorage.getItem("imgIdx"));
  const { data, mutate,reset } = useMutation(
    ({ m_type, m_name, m_email, m_phone }) => {
      return customedAxios
        .put(`member/updateMember`, {
          m_idx,
          m_type,
          m_name,
          m_email,
          m_phone,
          m_imgIdx,
        })
        .then((response) => {
          localStorage.removeItem("imgIdx");
          return response.data;
        });
    }
  );
  return { mutate, data, reset };
};

export const useRegisterMember = () => {
      const { data, mutate, reset } = useMutation(
        ({ m_type, m_name, m_email, m_phone, m_passwd }) => {
          const m_imgIdx =
            localStorage.getItem("imgIdx") == null
              ? 1
              : Number(localStorage.getItem("imgIdx"));
      return customedAxios
        .post(`member/register`, {
          m_snsType: "homepage",
          m_type,
          m_name,
          m_email,
          m_passwd,
          m_phone,
          m_imgIdx,
        })
        .then((response) => {
          return response.data;
        });
    }
  );
  return { mutate, reset, data };
};

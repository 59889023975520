import { Button, Chip } from "@mui/material";
import { useDeleteQna, useGetQnaList } from "api/qaManagement";
import { useEffect } from "react";
import { useState } from "react";
import { dateToStringFormat, leftPad } from "utils";

const UseQAManagement = (handleSnackOpen, handleDialogOpen) => {
  const [searchValue, setSearchValue] = useState({
    startDate: "",
    endDate: "",
    selectedText: "",
    searchText: "",
  });
  const searchAction = (startDate, endDate, selectedText, searchText) => {
    setSearchValue({
      startDate:
        startDate === undefined || startDate === null
          ? ""
          : startDate.$y +
            "-" +
            leftPad(startDate.$M + 1) +
            "-" +
            leftPad(startDate.$D),
      endDate:
        endDate == null
          ? ""
          : endDate.$y +
            "-" +
            leftPad(endDate.$M + 1) +
            "-" +
            leftPad(endDate.$D),
      selectedText,
      searchText,
    });
  };
  const { data: memberList, refetch } = useGetQnaList(searchValue);
  const { data: quitResponse, mutate } = useDeleteQna();
  useEffect(() => {
    refetch();
  }, [searchValue, refetch]);

  useEffect(() => {
    if (quitResponse !== undefined) {
      if (quitResponse.status.code === 200) {
        refetch();
      }
    }
  }, [quitResponse, refetch]);
  const dropdownList = [
    { value: "q_title", text: "제목" },
    { value: "m_name", text: "작성자" },
    { value: "expert_name", text: "전문가" },
  ];
  const DateTextList = ["작성일", "시작일", "종료일"];
  const tableColumn = [
    { field: "id", headerName: "No", width: 150 },
    {
      field: "q_title",
      headerName: "제목",
      width: 250,
      flex: 1,
    },
    { field: "m_name", headerName: "작성자", width: 150 },
    { field: "q_regDate", headerName: "작성일", width: 150 },
    { field: "q_answerDate", headerName: "답변일", width: 150 },
    {
      field: "q_status",
      headerName: "상태",
      width: 150,
      renderCell: (param) => {
        return param.value === "Y" ? (
          <Chip label="답변완료" color="primary" />
        ) : (
          <Chip label="답변예정" />
        );
      },
    },
    { field: "expert_name", headerName: "전문가", width: 150 },
    {
      field: "manage",
      headerName: "관리",
      width: 100,
      sortable: false,
      renderCell: (param) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              window.location.href =
                "/qaManagement/qaDetail?q_idx=" + param.row.q_idx;
            }}
          >
            상세
          </Button>
        );
      },
    },
  ];
  const [selectedId, setSelectedId] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (memberList !== undefined) {
      var tableData = memberList.result;

      tableData.map((value, index) => {
        tableData[index].id = index + 1;
        var regDate = new Date(value.q_regDate.split("T")[0]);
        tableData[index].q_regDate = dateToStringFormat(regDate, "-");
        if (tableData[index].q_status === 'Y') {
          var answerDate = new Date(value.q_answerDate.split("T")[0]);
          tableData[index].q_answerDate = dateToStringFormat(answerDate, "-");
        } else {
          tableData[index].q_answerDate = '-';
          tableData[index].expert_name = "-";
          
        }
          
        return 0;
      });
      setTableData(tableData);
    }
  }, [memberList]);

  const removeAction = () => {
    var list = [];
    selectedId.map((value) => {
      list.push(tableData[value - 1].q_idx);
      return 0;
    });
    mutate({ list: list });
    setSelectedId([]);
    handleSnackOpen("삭제 완료되었습니다.");
  };
  const tableCheckAction = (ids) => {
    setSelectedId(ids);
  };

  const removeClickAction = () => {
    if (selectedId.length === 0) {
      handleSnackOpen("선택한 항목이 없습니다.");
    } else {
      handleDialogOpen(removeAction);
    }
  };

  return {
    selectedId,
    searchAction,
    dropdownList,
    DateTextList,
    tableColumn,
    tableData,
    tableCheckAction,
    removeClickAction,
    memberList,
  };
};

export default UseQAManagement;

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { ChromePicker } from "react-color";

const ColorPicker = (items, setItems) => {
  const [color, setColor] = useState("#ffffff");
  const [open, setOpen] = useState(false);

  const handleColorPickerOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const colorChangeAction = (color) => {
    setColor(color.hex);
  };
  const colorSectionAction = () => {
    if (items.length <= 9) {
      let same = false;
      items.map((value) => {
        if (value.color === color) {
          same = true;
        }
        return 0;
      });
      if (!same)
        setItems([
          ...items,
          {
            id: items.length + 1,
            color: color,
          },
        ]);
    }
    setOpen(false);
  };

  const colorComponent = (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ m: 0, p: 2, fontSize: "16px", fontWeight: "bold" }}
      >
        색상 선택
      </DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <ChromePicker color={color} onChange={colorChangeAction} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>취소</Button>
        <Button onClick={colorSectionAction} autoFocus>
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );

  return { handleColorPickerOpen, colorComponent };
};

export default ColorPicker;

import { customedAxios } from "api";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

export const useGetAdminList = () => {
  const { data, refetch } = useQuery("getAdminList", () => {
    return customedAxios
      .get(`/admin/getAdminList?page=1&pageSize=1000`)
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch };
};

export const useDeleteAdmin = () => {
  const { data, mutate, reset } = useMutation(({ list }) => {
    return customedAxios
      .delete(`/admin/deleteAdmin`, {
        data: { list },
      })
      .then((response) => {
        return response.data;
      });
  });
  return { mutate, data, reset };
};

export const useGetAdminByIdx = () => {
  const [searchParams] = useSearchParams();
  const idx = Number(searchParams.get("idx"));
  const { data, refetch } = useQuery("getAdmin", () => {
    return customedAxios.get(`/admin/getAdmin?idx=${idx}`).then((response) => {
      return response.data;
    });
  });
  return { data, refetch };
};

export const useUpdateAdmin = () => {
  const [searchParams] = useSearchParams();
  const ad_idx = Number(searchParams.get("idx"));
  const { data, mutate, reset } = useMutation(
    ({ ad_name, ad_username, ad_passwd, ad_phone, ad_email }) => {
      return customedAxios
        .put(
          `/admin/updateAdmin`,
          {
            ad_idx,
            ad_name,
            ad_username,
            ad_passwd,
            ad_phone,
            ad_email,
          }
        )
        .then((response) => {
          return response.data;
        });
    }
  );
  return { mutate, data, reset };
};

export const useRegisterAdmin = () => {
  const { data, mutate, reset } = useMutation(
    ({ ad_name,ad_username,ad_passwd,ad_phone,ad_email, }) => {
      return customedAxios
        .post(
          `/admin/register`,
          {
            ad_name,
            ad_username,
            ad_passwd,
            ad_phone,
            ad_email,
          }
        )
        .then((response) => {
          return response.data;
        });
    }
  );
  return { mutate, data, reset };
};

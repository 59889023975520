import Search from "components/common/searchUser";
import UseUserManagement from "hooks/userManagement/useUserManagement";
import React from "react";
import { Box } from "@mui/system";
import CustomTable from "components/common/tables/customCheckTable";
import MainCard from "ui-component/cards/MainCard";
import { Button } from "@mui/material";
import CustomDialog from "components/common/customDialog";
import CustomSnackbar from "components/common/customSnackbar";
import { useEffect } from "react";

const UserManagement = () => {
  const { snackbarComponent, handleSnackOpen } = CustomSnackbar();
  const { dialogComponent, handleDialogOpen } = CustomDialog(
    "회원 탈퇴",
    "선택한 회원을 탈퇴 처리하시겠습니까?"
  );
  const {
    searchAction,
    dropdownList,
    DateTextList,
    tableColumn,
    tableData,
    selectedId,
    tableCheckAction,
    removeClickAction,
    memberList,
  } = UseUserManagement(handleSnackOpen, handleDialogOpen);

  useEffect(() => {
    const popupText = localStorage.getItem("popupText");
    if (popupText != null) {
      handleSnackOpen(popupText);
      localStorage.removeItem("popupText");
    }
  }, [handleSnackOpen]);

  return (
    <Box>
      {dialogComponent}
      {snackbarComponent}
      <Search
        dropdownList={dropdownList}
        searchAction={searchAction}
        DateTextList={DateTextList}
      />
      <MainCard sx={{ marginTop: "24px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "24px",
          }}
        >
          <Button variant="contained" color="error" onClick={removeClickAction}>
            탈퇴처리
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              window.location.href = "/userManagement/userSign";
            }}
          >
            회원 등록
          </Button>
        </Box>
        {memberList !== undefined &&
        <CustomTable
          tableCheckAction={tableCheckAction}
          selectedId={selectedId}
          data={tableData}
          column={tableColumn}
        />}
      </MainCard>
    </Box>
  );
};

export default UserManagement;

import { customedAxios } from "api";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

export const useGetFeedbackList = (searchValue) => {
  const { data, refetch } = useQuery("getFeedbackList", () => {
    return customedAxios
      .get(
        `/feedback/getFeedbackList?page=1&pageSize=1000&startDate=${searchValue.startDate}&endDate=${searchValue.endDate}&searchType=${searchValue.selectedText}&searchValue=${searchValue.searchText}`
      )
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch };
};

export const useDeleteFeedback = () => {
  const { data, mutate, reset } = useMutation(({ list }) => {
    return customedAxios
      .delete(`/feedback/deleteFeedback`, {
        data: { list },
      })
      .then((response) => {
        return response.data;
      });
  });
  return { mutate, data, reset };
};
export const useGetFeedbackByIdx = () => {
  const [searchParams] = useSearchParams();
  const f_idx = Number(searchParams.get("f_idx"));
  const { data, refetch } = useQuery("getFeedbackByIdx", () => {
    return customedAxios
      .get(`/feedback/getFeedbackByIdx?f_idx=${f_idx}`)
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch };
};

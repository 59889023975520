const menuItems = {
  items: [
    {
      id: "utilities",
      title: "",
      type: "group",
      children: [
        {
          id: "userManagement",
          title: "회원 관리",
          url: "/userManagement",
          type: "collapse",
          children: [
            {
              id: "userManagement",
              title: "전체 회원 관리",
              url: "/userManagement",
              type: "item",
              children: [
                {
                  id: "userDetail",
                  title: "회원 상세 정보",
                  url: "/userManagement/userDetail",
                  type: "item",
                },
                {
                  id: "userSign",
                  title: "회원 등록",
                  url: "/userManagement/userSign",
                  type: "item",
                },
              ],
            },
            {
              id: "WithdrawalList",
              title: "탈퇴 회원 목록",
              url: "/userManagement/WithdrawalList",
              type: "item",
            },
            {
              id: "ExpertAccept",
              title: "전문가 승인 대기 목록",
              url: "/userManagement/ExpertAccept",
              type: "item",
            },
          ],
        },
        {
          id: "caseManagement",
          title: "케이스 관리",
          url: "/caseManagement",
          type: "item",
          children: [
            {
              id: "caseDetail",
              title: "케이스 상세 정보",
              url: "/caseDetail",
              type: "item",
            },
            {
              id: "caseSign",
              title: "케이스 등록",
              url: "/caseSign",
              type: "item",
            },
          ],
        },
        {
          id: "studentManagement",
          title: "실습 관리",
          url: "/studentManagement",
          type: "collapse",
          children: [
            {
              id: "studentManagement",
              title: "학습자 실습 관리",
              url: "/studentManagement",
              type: "item",
              children: [
                {
                  id: "studentDetail",
                  title: "실습 상세 정보",
                  url: "/studentManagement/studentDetail",
                  type: "item",
                },
              ],
            },
            {
              id: "expertManagement",
              title: "전문가 사례 관리",
              url: "/studentManagement/expertManagement",
              type: "item",
              children: [
                {
                  id: "expertDetail",
                  title: "전문가 사례 상세 정보",
                  url: "/studentManagement/expertManagement/expertDetail",
                  type: "item",
                },
              ],
            },
          ],
        },
        {
          id: "neddleManagement",
          title: "실습 tool 관리",
          url: "/neddleManagement",
          type: "collapse",
          children: [
            {
              id: "neddleManagement",
              title: "니들 관리",
              url: "/neddleManagement",
              type: "item",
              children: [
                {
                  id: "neddleDetail",
                  title: "니들 상세 정보",
                  url: "/neddleManagement/neddleDetail",
                  type: "item",
                },
                {
                  id: "neddleAppend",
                  title: "니들 등록",
                  url: "/neddleManagement/neddleAppend",
                  type: "item",
                },
              ],
            },
            {
              id: "colorManagement",
              title: "색소 관리",
              url: "/neddleManagement/colorManagement",
              type: "item",
              children: [
                {
                  id: "colorDetail",
                  title: "색소 상세 정보",
                  url: "/neddleManagement/colorManagement/colorDetail",
                  type: "item",
                },
                {
                  id: "colorAppend",
                  title: "색소 등록",
                  url: "/neddleManagement/colorManagement/colorAppend",
                  type: "item",
                },
              ],
            },
          ],
        },
        {
          id: "feedbackManagement",
          title: "피드백 관리",
          url: "/feedbackManagement",
          type: "item",
        },
        {
          id: "qaManagement",
          title: "Q&A 관리",
          url: "/qaManagement",
          type: "item",
          children: [
            {
              id: "qaDetail",
              title: "Q&A 상세 정보",
              url: "/qaManagement/qaDetail",
              type: "item",
            },
          ],
        },
        {
          id: "serviceManagement",
          title: "고객센터",
          url: "/serviceManagement",
          type: "collapse",
          children: [
            {
              id: "serviceManagement",
              title: "1:1 문의",
              url: "/serviceManagement",
              type: "item",
              children: [
                {
                  id: "serviceDetail",
                  title: "1:1 문의 상세 정보",
                  url: "/serviceManagement/serviceDetail",
                  type: "item",
                },
              ],
            },
            {
              id: "noticeManagement",
              title: "공지사항",
              url: "/serviceManagement/noticeManagement",
              type: "item",
              children: [
                {
                  id: "noticeDetail",
                  title:
                    localStorage.getItem("noticeDetailInfo") === null
                      ? "공지사항 등록"
                      : "공지사항 상세 정보",
                  url: "/serviceManagement/noticeManagement/noticeDetail",
                  type: "item",
                },
              ],
            },
          ],
        },
        {
          id: "adminManagement",
          title: "관리자 설정",
          url: "/adminManagement",
          type: "item",
          children: [
            {
              id: "adminDetail",
              title:
                localStorage.getItem("adminDetailInfo") === null
                  ? "관리자 등록"
                  : "관리자 수정",
              url: "/adminManagement/adminDetail",
              type: "item",
            },
          ],
        },
      ],
    },
  ],
};

export default menuItems;

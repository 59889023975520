import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LOGIN_USER } from "store/actions";

const AuthHigherOrderComponent = ({ component }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    if (loginData != null) {
      dispatch({
        type: LOGIN_USER,
        ad_idx: loginData.ad_idx,
        ad_name: loginData.ad_name,
        ad_username: loginData.ad_username,
        ad_phone: loginData.ad_phone,
        ad_email: loginData.ad_email,
        token: loginData.token,
      });
    } else {
      if (window.location.pathname !== "/") {
        window.location.href = "/";
      }
    }
  }, [dispatch]);
  return <>{component}</>;
};

export default AuthHigherOrderComponent;

import { Button, Grid, TextField } from "@mui/material";
import CenterBox from "components/common/centerBox";
import InfoIcon from "@mui/icons-material/Info";
import React from "react";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import DataLabel from "components/common/dataLabel";
import ImageUploader from "components/common/imageUploader/customCircle";
import CustomDialog from "components/common/customDialog";
import CustomSnackbar from "components/common/customSnackbar";
import { useState } from "react";
import { ShapeMultiWrap, ShapeWrap } from "./styled";
import CancelIcon from "@mui/icons-material/Cancel";
import { EmptyWrap } from "../colorAppend/styled";
import ColorPicker from "components/colorManagement/colorPicker";
import { useGetPigmentByIdx, useUpdatePigment } from "api/colorManagement";
import { useEffect } from "react";
import { getS3Image } from "utils";

const ColorDetail = () => {
  const [thumbnail, setThumbnail] = useState(undefined);
  const [product, setProduct] = useState("");
  const [explain, setExplain] = useState("");
  const [link, setLink] = useState("");
  const { dialogComponent, handleDialogOpen } = CustomDialog(
    "색소 정보 수정",
    "수정된 내용으로 저장하시겠습니까?"
  );
  const { snackbarComponent } = CustomSnackbar();
  const [items, setItems] = useState([]);

  const { handleColorPickerOpen, colorComponent } = ColorPicker(
    items,
    setItems
  );
  const { data } = useGetPigmentByIdx();
  const { data: pigmentChange, mutate } = useUpdatePigment();
  const { uploadComponent } = ImageUploader(thumbnail);
  useEffect(() => {
    if (data !== undefined) {
      if (data.status.code === 200) {
        setProduct(data.status.result.p_productName);
        setExplain(data.status.result.p_productDescription);
        setLink(data.status.result.p_productUrl);
        setThumbnail(getS3Image(data.status.result.img_name));
        localStorage.setItem("imgIdx", data.status.result.img_idx);
        var defaultItems = [];
        for (let i = 1; i < 11; i++) {
          if (data.status.result[`p_color${i}`] !== null) {
            defaultItems.push({
              id: i,
              color: data.status.result[`p_color${i}`],
            });
          }
        }
        setItems(defaultItems);
      }
    }
  }, [data]);

  useEffect(() => {
    if (pigmentChange !== undefined) {
      if (pigmentChange.status.code === 200) {
        localStorage.setItem("popupText", "색소 정보가 수정되었습니다.");
        window.location.replace("/neddleManagement/colorManagement");
      }
    }
  }, [pigmentChange]);

  return (
    <>
      {dialogComponent}
      {snackbarComponent}
      {colorComponent}
      <CenterBox>
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={4}>
              {uploadComponent}
              {/* <img src={sampleImage1} alt="image" style={{ width: "100%" }} /> */}
            </Grid>
            <Grid item xs={8}>
              <Grid item sx={{ paddingTop: "24px" }}>
                <DataLabel
                  title={"제품명"}
                  children={
                    <TextField
                      value={product}
                      placeholder="제품명을 입력해주세요. (최대 50자)"
                      onChange={(e) => {
                        setProduct(e.target.value);
                      }}
                      inputProps={{ maxLength: 50 }}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
              <Grid item sx={{ paddingTop: "24px" }}>
                <DataLabel
                  title={"제품 설명"}
                  children={
                    <TextField
                      value={explain}
                      placeholder="제품 설명을 입력해주세요. (최대 200자)"
                      onChange={(e) => {
                        setExplain(e.target.value);
                      }}
                      multiline
                      minRows={5}
                      maxRows={5}
                      inputProps={{
                        maxLength: 200,
                      }}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
              <Grid item sx={{ paddingTop: "24px" }}>
                <DataLabel
                  title={"제품 링크"}
                  children={
                    <TextField
                      value={link}
                      placeholder="제품 구매 링크를 입력해주세요."
                      onChange={(e) => {
                        setLink(e.target.value);
                      }}
                      multiline
                      minRows={2}
                      maxRows={3}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
              <Grid item sx={{ paddingTop: "24px", paddingBottom: "12px" }}>
                <DataLabel
                  title={"색상"}
                  children={
                    <Grid sx={{ width: "calc(100% - 120px)" }}>
                      <Button
                        variant="contained"
                        onClick={handleColorPickerOpen}
                      >
                        + 추가
                      </Button>
                    </Grid>
                  }
                />
              </Grid>
              {items.length === 0 && (
                <EmptyWrap>
                  <InfoIcon />
                  <p>색상을 추가해주세요.</p>
                </EmptyWrap>
              )}
              <ShapeMultiWrap>
                {items.map((value, index) => {
                  return (
                    <ShapeWrap>
                      <div
                        style={{
                          backgroundColor: value.color,
                        }}
                      ></div>
                      <p>{value.color}</p>
                      <CancelIcon
                        onClick={() => {
                          var newItems = items;
                          newItems.splice(index, 1);
                          setItems([...newItems]);
                        }}
                      />
                    </ShapeWrap>
                  );
                })}
              </ShapeMultiWrap>
            </Grid>
          </Grid>
        </MainCard>

        <Grid sx={{ width: "100%", paddingTop: "24px", textAlign: "right" }}>
          <Button
            variant="contained"
            disabled={
              product === "" ||
              explain === "" ||
              link === "" ||
              items.length === 0
            }
            onClick={() => {
              handleDialogOpen(() => {
                mutate({
                  p_name: '',
                  p_productName: product,
                  p_productDescription: explain,
                  p_productUrl: link,
                  p_color1: items[0] === undefined ? null : items[0].color,
                  p_color2: items[1] === undefined ? null : items[1].color,
                  p_color3: items[2] === undefined ? null : items[2].color,
                  p_color4: items[3] === undefined ? null : items[3].color,
                  p_color5: items[4] === undefined ? null : items[4].color,
                  p_color6: items[5] === undefined ? null : items[5].color,
                  p_color7: items[6] === undefined ? null : items[6].color,
                  p_color8: items[7] === undefined ? null : items[7].color,
                  p_color9: items[8] === undefined ? null : items[8].color,
                  p_color10: items[9] === undefined ? null : items[9].color,
                });
              });
            }}
          >
            저장
          </Button>
        </Grid>
      </CenterBox>
    </>
  );
};

export default ColorDetail;

import Search from "components/common/search";
import React from "react";
import { Box } from "@mui/system";
import CustomTable from "components/common/tables/customCheckSizeTable";
import MainCard from "ui-component/cards/MainCard";
import { Button } from "@mui/material";
import CustomDialog from "components/common/customRemoveDialog";
import CustomSnackbar from "components/common/customSnackbar";
import { useEffect } from "react";
import UseNoticeManagement from "hooks/noticeManagement/useNoticeManagement";

const NoticeManagement = () => {
  const { snackbarComponent, handleSnackOpen } = CustomSnackbar();
  const { dialogComponent, handleDialogOpen } = CustomDialog(
    "공지사항 삭제",
    "선택한 항목을 삭제하시겠습니까?"
  );
  const {
    searchAction,
    dropdownList,
    DateTextList,
    tableColumn,
    tableData,
    tableCheckAction,
    removeClickAction,
    selectedId,
    memberList,
  } = UseNoticeManagement(handleSnackOpen, handleDialogOpen);

  useEffect(() => {
    const popupText = localStorage.getItem("popupText");
    if (popupText != null) {
      localStorage.removeItem("popupText");
      handleSnackOpen(popupText);
    }
  }, [handleSnackOpen]);

  return (
    <Box>
      {dialogComponent}
      {snackbarComponent}
      <Search
        dropdownList={dropdownList}
        searchAction={searchAction}
        DateTextList={DateTextList}
      />
      <MainCard sx={{ marginTop: "24px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "24px",
          }}
        >
          <Button variant="contained" color="error" onClick={removeClickAction}>
            삭제
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              localStorage.removeItem("noticeDetailInfo");
              window.location.href =
                "/serviceManagement/noticeManagement/noticeDetail";
            }}
          >
            공지 등록
          </Button>
        </Box>
        {
          memberList !==undefined && <CustomTable
          selectedId={selectedId}
          tableCheckAction={tableCheckAction}
          data={tableData}
          column={tableColumn}
        />}
      </MainCard>
    </Box>
  );
};

export default NoticeManagement;

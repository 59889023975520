import React from "react";
import { useState } from "react";

const CustomImage = ({ maxSize, src }) => {
  const [type, setType] = useState("square");
  const image = new Image();
  image.src = src;
  image.onload = () => {
    if (image.width > image.height) {
      setType("vertical");
    } else {
      setType("horizontal");
    }
  };

  return (
    <div
      style={{
        width: type === "vertical" ? maxSize + "px" : "auto",
        height: type === "vertical" ? "auto" : maxSize + "px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={src}
        alt=""
        style={{
          width: type === "vertical" ? maxSize + "px" : "auto",
          height: type === "vertical" ? "auto" : maxSize + "px",
          borderRadius: "16px",
        }}
      />
    </div>
  );
};

export default CustomImage;

import { Chip } from "@mui/material";
import { useGetQuitMemberList } from "api/userManagement";
import { useEffect } from "react";
import { useState } from "react";
import { dateToStringFormat, leftPad } from "utils";

const useWithdrawalList = () => {
  const [searchValue, setSearchValue] = useState({
    startDate: "",
    endDate: "",
    selectedText: "",
    searchText: "",
  });
  const searchAction = (startDate, endDate, selectedText, searchText) => {
    setSearchValue({
      startDate:
        startDate === undefined || startDate === null
          ? ""
          : startDate.$y +
            "-" +
            leftPad(startDate.$M + 1) +
            "-" +
            leftPad(startDate.$D),
      endDate:
        endDate == null
          ? ""
          : endDate.$y +
            "-" +
            leftPad(endDate.$M + 1) +
            "-" +
            leftPad(endDate.$D),
      selectedText,
      searchText,
    });
  };
  const dropdownList = [
    { value: "m_name", text: "이름" },
    { value: "m_email", text: "아이디" },
  ];
  const { data: memberList, refetch } = useGetQuitMemberList(searchValue);
  const DateTextList = ["탈퇴일", "시작일", "종료일"];
  const tableColumn = [
    { field: "id", headerName: "No", width: 100 },
    { field: "m_name", headerName: "이름", width: 150 },
    { field: "m_email", headerName: "아이디(이메일)", width: 250 },
    { field: "trainingCnt", headerName: "실습 완료", width: 150 },
    { field: "qnaCnt", headerName: "Q&A 작성", width: 150 },
    { field: "m_regDate", headerName: "가입일", width: 150 },
    {
      field: "m_type",
      headerName: "회원 유형",
      width: 150,
      renderCell: (param) => {
        return param.value === "students" ? (
          <Chip label="학습자" />
        ) : (
          <Chip label="전문가" color="primary" />
        );
      },
    },
    {
      field: "m_withdrawDate",
      headerName: "탈퇴일",
      width: 150,
    },
  ];
  useEffect(() => {
    refetch();
  }, [searchValue, refetch]);

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if (memberList !== undefined) {
      var tableData = memberList.result;
      tableData.map((value, index) => {
        tableData[index].id = value.m_idx;
        tableData[index].id = index + 1;
        var regDate = new Date(value.m_regDate.split("T")[0]);
        if (value.m_withdrawDate !== null) {
          var withdrawDate = new Date(value.m_withdrawDate.split("T")[0]);
          tableData[index].m_withdrawDate = dateToStringFormat(
            withdrawDate,
            "-"
          );
        } else {
          tableData[index].m_withdrawDate = "-";
        }
        tableData[index].m_regDate = dateToStringFormat(regDate, "-");
        return 0;
      });
      setTableData(tableData);
    }
  }, [memberList]);

  return {
    searchAction,
    dropdownList,
    DateTextList,
    tableColumn,
    tableData,
    memberList,
  };
};

export default useWithdrawalList;

import { Button, Grid, TextField } from "@mui/material";
import CenterBox from "components/common/centerBox";
import React from "react";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import DataLabel from "components/common/dataLabel";
import { useState } from "react";
import { useEffect } from "react";
import CustomImage from "components/common/customImage";
import { useGetTrainingByIdx } from "api/studentManagement";
import { dateToStringFormat } from "utils";

const StudentDetail = () => {
  const [studentData, setStudentData] = useState();
  const [thumbnail, setThumbnail] = useState("");
  const [face, setFace] = useState("");
  const [eyebrow, setEyebrow] = useState("");
  const [name, setName] = useState("");
  const [time, setTime] = useState("");
  const [score, setScore] = useState("");
  const [student, setStudent] = useState("");
  const [date, setDate] = useState("");

  const { data } = useGetTrainingByIdx();
  useEffect(() => {
    if (data !== undefined) {
      if (data.status.code === 200) {
        setStudentData(data.status.result);
      }
    }
  }, [data]);

  useEffect(() => {
    if (studentData !== undefined) {
      setThumbnail(
        process.env.REACT_APP_IMAGE_BASE_URL + studentData.training_img_name
      );
      setName(studentData.c_name);
      setFace(studentData.c_face);
      setStudent(studentData.m_name);
      setTime(studentData.t_time);
      setScore(studentData.t_point);
      setDate(
        dateToStringFormat(new Date(studentData.t_regDate.split("T")[0]))
      );
      setEyebrow(studentData.c_eyebrow);
    }
  }, [studentData]);
  return (
    <CenterBox>
      <MainCard>
        <Grid container spacing={gridSpacing}>
          <Grid
            item
            xs={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CustomImage maxSize={250} src={thumbnail} />
          </Grid>
          <Grid item xs={8}>
            <Grid item>
              <DataLabel
                title={"케이스명"}
                children={
                  <TextField
                    disabled
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    sx={{ width: "calc(100% - 120px)" }}
                  ></TextField>
                }
              />
            </Grid>
            <Grid
              item
              sx={{
                paddingTop: "24px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={5}>
                <DataLabel
                  title={"얼굴형"}
                  children={
                    <TextField
                      disabled
                      value={face}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <DataLabel
                  title={"눈썹 유형"}
                  children={
                    <TextField
                      disabled
                      value={eyebrow}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                paddingTop: "24px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={5}>
                <DataLabel
                  title={"실습 완료일"}
                  children={
                    <TextField
                      disabled
                      value={date}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <DataLabel
                  title={"학습자"}
                  children={
                    <TextField
                      disabled
                      value={student}
                      onChange={(e) => {
                        setStudent(e.target.value);
                      }}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                paddingTop: "24px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={5}>
                <DataLabel
                  title={"소요시간"}
                  children={
                    <TextField
                      disabled
                      value={time}
                      onChange={(e) => {
                        time(e.target.value);
                      }}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <DataLabel
                  title={"점수"}
                  children={
                    <TextField
                      disabled
                      value={score}
                      onChange={(e) => {
                        setScore(e.target.value);
                      }}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
      <Grid sx={{ width: "100%", paddingTop: "24px", textAlign: "right" }}>
        <Button
          variant={"contained"}
          onClick={() => {
            window.history.back();
          }}
        >
          확인
        </Button>
      </Grid>
    </CenterBox>
  );
};

export default StudentDetail;

import {
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CenterBox from "components/common/centerBox";
import useUserDetail from "hooks/userManagement/useUserDetail";
import React from "react";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import DataLabel from "components/common/dataLabel";
import ImageUploader from "components/common/imageUploader/custom";
import CustomDialog from "components/common/customDialog";
import CustomSnackbar from "components/common/customSnackbar";
import PasswordDialog from "components/userManagement/passwordDialog";
import { checkEmail, checkKorean, checkPhone } from "utils/replaceText";
import { dateToStringFormat } from "utils";
import { useUpadteMember } from "api/userManagement";
import { useEffect } from "react";

const UserDetail = () => {
  const { dialogComponent, handleDialogOpen } = CustomDialog(
    "회원 정보 수정",
    "수정된 내용으로 저장하시겠습니까?"
  );
  const { snackbarComponent } = CustomSnackbar();
  const {
    userData,
    selectedText,
    setSelectedText,
    name,
    email,
    phone,
    count,
    QA,
    imageSource,
    setName,
    setEmail,
    setPhone,
    setCount,
    setQA,
  } = useUserDetail();
  const { handleDialogOpen: passwordPopupShow, passwordComponent } =
    PasswordDialog();
  const { mutate, data ,reset} = useUpadteMember();
  const { uploadComponent } = ImageUploader(imageSource);

  useEffect(() => {
    if (data !== undefined) {
      if (data.status.code === 200) {
        localStorage.setItem("popupText", "회원 정보가 수정되었습니다.");
        window.location.replace("/userManagement");
      } else if (data.status.code === 400) {
        alert(data.status.message);
      }
      reset();
    }
  }, [data, email, phone, reset]);

  return (
    <>
      {dialogComponent}
      {snackbarComponent}
      {passwordComponent}
      {userData && (
        <CenterBox>
          <Typography
            sx={{
              fontSize: "16px",
              marginBottom: "12px",
              width: "100%",
              fontWeight: "bold",
            }}
          >
            회원정보
          </Typography>
          <MainCard>
            <Grid container spacing={gridSpacing}>
              <Grid item xs={4}>
                {uploadComponent}
                {/* <img src={sampleImage1} alt="image" style={{ width: "100%" }} /> */}
              </Grid>
              <Grid item xs={8}>
                <Grid
                  item
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid item xs={5}>
                    <DataLabel
                      title={"회원 유형"}
                      children={
                        <Select
                          sx={{ width: "calc(100% - 120px)" }}
                          id="demo-simple-select"
                          value={selectedText}
                          onChange={(e) => {
                            setSelectedText(e.target.value);
                          }}
                        >
                          <MenuItem value={"expert"}>전문가</MenuItem>
                          <MenuItem value={"students"}>학습자</MenuItem>
                        </Select>
                      }
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <DataLabel
                      title={"가입일"}
                      children={
                        <TextField
                          value={dateToStringFormat(
                            new Date(userData.m_regDate)
                          )}
                          disabled
                          sx={{ width: "calc(100% - 120px)" }}
                        ></TextField>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item sx={{ paddingTop: "24px" }}>
                  <DataLabel
                    title={"이름"}
                    children={
                      <TextField
                        value={name}
                        placeholder="이름을 입력해주세요."
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        sx={{ width: "calc(100% - 120px)" }}
                      ></TextField>
                    }
                  />
                </Grid>
                <Grid item sx={{ paddingTop: "24px" }}>
                  <DataLabel
                    title={"회원 ID"}
                    children={
                      <TextField
                        value={email}
                        placeholder="ID(이메일)를 입력해주세요."
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        sx={{ width: "calc(100% - 120px)" }}
                      ></TextField>
                    }
                  />
                </Grid>
                <Grid item sx={{ paddingTop: "24px" }}>
                  <DataLabel
                    title={"휴대폰 번호"}
                    children={
                      <TextField
                        value={phone}
                        placeholder="휴대폰 번호를 입력해주세요."
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                        sx={{ width: "calc(100% - 120px)" }}
                      ></TextField>
                    }
                  />
                </Grid>
                <Grid sx={{ paddingTop: "24px", textAlign: "right" }}>
                  <Button variant="contained" onClick={passwordPopupShow}>
                    회원 PW 재설정
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </MainCard>

          <Typography
            sx={{
              fontSize: "16px",
              marginBottom: "12px",
              marginTop: "24px",
              width: "100%",
              fontWeight: "bold",
            }}
          >
            회원 활동 내역
          </Typography>
          <MainCard>
            <Grid container spacing={gridSpacing}>
              <Grid item xs={8}>
                <Grid item>
                  <DataLabel
                    title={"완료한 실습"}
                    children={
                      <TextField
                        value={count + "개"}
                        disabled
                        onChange={(e) => {
                          setCount(e.target.value);
                        }}
                        sx={{ width: "calc(100% - 120px)" }}
                      ></TextField>
                    }
                  />
                </Grid>
                <Grid item sx={{ paddingTop: "24px" }}>
                  <DataLabel
                    title={"작성한 Q&A"}
                    children={
                      <TextField
                        value={QA + "개"}
                        disabled
                        onChange={(e) => {
                          setQA(e.target.value);
                        }}
                        sx={{ width: "calc(100% - 120px)" }}
                      ></TextField>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </MainCard>
          <Grid sx={{ width: "100%", paddingTop: "24px", textAlign: "right" }}>
            <Button
              variant="contained"
              disabled={
                name === "" ||
                email === "" ||
                phone === "" ||
                count === "" ||
                QA === "" ||
                checkKorean(name) ||
                checkEmail(email) ||
                checkPhone(phone)
              }
              onClick={() => {
                handleDialogOpen(() => {
                  // window.history.back();
                  mutate({
                    m_type: selectedText,
                    m_name: name,
                    m_email: email,
                    m_phone: phone,
                  });
                });
              }}
            >
              저장
            </Button>
          </Grid>
        </CenterBox>
      )}
    </>
  );
};

export default UserDetail;

import Spinner from "assets/images/test/Spinner.gif";

const Loading = () => {

  const boxStyle = {
    display: 'flex', 
    placeContent: 'center center', 
    flexWrap: 'wrap',
    width: '100vw', 
    height: '100vh', 
    position: 'absolute', 
    top: 0, 
    left: 0, 
    backgroundColor: "#aaa", 
    opacity: 0.3,
    zIndex: 9999
  }
  
  return (
    <div style={boxStyle}>
      <img src={Spinner} alt="spinner" style={{ width: '100px', height: '100px'}} />  
    </div>
  )
}

export default Loading;

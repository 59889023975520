import { customedAxios } from "api";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

export const useGetNeddleList = (searchValue) => {
  const { data, refetch } = useQuery("getNeddleList", () => {
    return customedAxios
      .get(
        `/needle/getNeedleList?page=1&pageSize=1000&startDate=${searchValue.startDate}&endDate=${searchValue.endDate}&searchType=${searchValue.selectedText}&searchValue=${searchValue.searchText}`
      )
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch };
};

export const useDeleteNeddle = () => {
  const { data, mutate, reset } = useMutation(({ list }) => {
    return customedAxios
      .delete(`/needle/deleteNeedle`, {
        data: { list },
      })
      .then((response) => {
        return response.data;
      });
  });
  return { mutate, data, reset };
};

export const useGetNeedleByIdx = () => {
  const [searchParams] = useSearchParams();
  const n_idx = Number(searchParams.get("n_idx"));
  const { data, refetch } = useQuery("getNeedleByIdx", () => {
    return customedAxios
      .get(`/needle/getNeedleByIdx?n_idx=${n_idx}`)
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch };
};

export const useUpdateNeddle = () => {
  const [searchParams] = useSearchParams();
  const n_idx = Number(searchParams.get("n_idx"));
  const m_imgIdx =
    localStorage.getItem("imgIdx") == null
      ? 1
      : Number(localStorage.getItem("imgIdx"));
  const { data, mutate } = useMutation(
    ({
      n_name,
      n_productName,
      n_productDescription,
      n_productUrl,
      needleShapeData,
    }) => {
      return customedAxios
        .put(`/needle/updateNeedle`, {
          n_idx,
          n_name,
          n_productName,
          n_productDescription,
          n_productUrl,
          needleShapeData,
          img_idx: m_imgIdx,
        })
        .then((response) => {
          localStorage.removeItem("imgIdx");
          return response.data;
        });
    }
  );
  return { mutate, data };
};

export const useRegisterNeddle = () => {
  const n_imgIdx =
    localStorage.getItem("imgIdx") == null
      ? 1
      : Number(localStorage.getItem("imgIdx"));
  const { data, mutate, reset } = useMutation(
    ({
      n_name,
      n_productName,
      n_productDescription,
      n_productUrl,
      shapeData,
    }) => {
      return customedAxios
        .post(`/needle/register`, {
          n_name,
          n_productName,
          n_productDescription,
          n_productUrl,
          shapeData,
          img_idx: n_imgIdx,
        })
        .then((response) => {
          localStorage.removeItem("imgIdx");
          return response.data;
        });
    }
  );
  return { mutate, reset, data };
};

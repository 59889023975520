import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "../CustomNoRowsOverlay";

export default function CustomCheckSizeTable({
  tableCheckAction,
  selectedId,
  data,
  column,
}) {
  const customColumn = column;
  const handleCellClick = (param, event) => {
    event.stopPropagation();
  };

  const handleRowClick = (param, event) => {
    event.stopPropagation();
  };
  customColumn.map((value) => {
    value.flex = 1;
    return value;
  });

  return (
    <div style={{ height: "auto", width: "100%" }}>
      <DataGrid
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        sx={{
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: " center",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-cell--textLeft": {
            justifyContent: " center",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "&.MuiDataGrid-root .MuiDataGrid-virtualScrollerContent": {
            minHeight:
              data.length === 0 ? "200px !important" : "100% !important",
          },
        }}
        rows={data}
        autoHeight={true}
        columns={customColumn}
        disableColumnMenu
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        checkboxSelection
        rowSelectionModel={selectedId}
        onRowSelectionModelChange={tableCheckAction}
        onCellClick={handleCellClick}
        onRowClick={handleRowClick}
      />
    </div>
  );
}

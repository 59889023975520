import { useGetQnaList } from "api/dashboard";
import { useEffect } from "react";
import { useState } from "react";
import { dateToStringFormat } from "utils";

const UseQnaAction = () => {
  const { data } = useGetQnaList(0, 3);
  const [sampleData, setSampleData] = useState([]);
  useEffect(() => {
    var sampleData = [];
    if (data !== undefined) {
      data.result.map((value) => {
        var imageSource = process.env.REACT_APP_IMAGE_BASE_URL + value.img_name;
        var title = value.q_title;
        var writer = `학습자명 : ${value.m_name}`;
        var date =
          dateToStringFormat(new Date(value.q_regDate.split("T")[0])) +
          " " +
          new Date(value.q_regDate).getUTCHours() +
          ":" +
          new Date(value.q_regDate).getUTCMinutes();
        var textBadge = value.q_status === "N";
        sampleData.push({
          indexNumber: value.q_idx,
          imageSource: imageSource,
          title: title,
          date: date,
          writer: writer,
          textBadge: textBadge,
        });
        return 0;
      });
      setSampleData(sampleData);
    }
  }, [data]);
  const clickAction = (sampleCode) => {
    if (sampleCode === undefined) {
      window.location.href = "/qaManagement";
    } else {
      window.location.href = "/qaManagement/qaDetail?q_idx=" + sampleCode;
    }
  };
  return { sampleData, clickAction };
};

export default UseQnaAction;

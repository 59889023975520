import { useState, useEffect, useRef } from "react";
import TestHeader from "../Components/Header";
import { Button, Typography } from "@mui/material";

import axios from "axios";
import Loading from "../Components/Loading";
import ResultTable from "./Table/ResultTable";

const TestSimilarityAccuracy = () => {
  // useState
  const [loading, setLoading] = useState(false);
  const [resultItems, setResultItems] = useState([]);
  
  const fileRef = useRef(null);

  useEffect(() => {
    console.log("begin TestSimilarityAccuracy");
  }, [])

  const boxStyle = {
    marginTop: '50px', 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center', 
    gap:'30px'
  }

  const handleImageChange = (e) => {
    setLoading(true);
    const files = Array.from(e.target.files);
    console.log(files);
  
    // 각 API 요청에 대한 프로미스 배열 생성
    const uploadPromises = files.map((file) => handleSubmit(file));
  
    // Promise.all을 사용하여 모든 프로미스가 해결될 때까지 기다림
    Promise.all(uploadPromises)
      .then(() => {
        console.log("모든 요청 완료");
        // 모든 요청이 완료된 후 추가 작업 수행 가능
        setLoading(false);
      })
      .catch((error) => {
        console.error("요청 중 오류 발생:", error);
        setLoading(false);
      });
  };
  

  const handleSubmit = (file) => {
    console.log("handleSubmit 시작");
    const formData = new FormData();
    console.log(file);
    formData.append('image', file);
  
    // axios에서 반환된 프로미스 반환
    return axios({
      method: "post",
      url: "http://3.39.63.82:8080/ai/uploadImage",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((result) => {
        console.log(result);
        // 새 결과로 resultItems 상태 업데이트
        setResultItems((prevResultItems) => [...prevResultItems, result.data.result]);
      })
      .catch((error) => {
        console.error(error);
        // 필요하다면 여기서 에러 처리 가능
      });
  };

  const filterToFace = (face) => {
    return resultItems.filter(item => item.c_face === face)
  }

  return (
    <>
      <TestHeader />
      <div style={boxStyle}>
        <div>
          <Typography variant="h4" sx={{mt: 2, fontFamily: 'monospace', fontSize:'20px'}}>
            얼굴형 이미지 유사도 판별 알고리즘 정확도
          </Typography>
        </div>
        <div>
          <Button variant="contained" onClick={() => {
            fileRef.current.click();
          }}>파일 선택</Button>
          <input ref={fileRef} style={{display: 'none'}} type="file" multiple onChange={handleImageChange}/>
        </div>

        <div style={{
          display: 'flex',
          gap: '30px'
        }}>
          <ResultTable title="달걀형" items={filterToFace("달걀형")} />
          <ResultTable title="사각형" items={filterToFace("사각형")} />
          <ResultTable title="역삼각형" items={filterToFace("역삼각형")} />
          <ResultTable title="사다리꼴형" items={filterToFace("사다리꼴형")} />
          <ResultTable title="둥근형" items={filterToFace("둥근형")} />
          <ResultTable title="긴형" items={filterToFace("긴형")} />
        </div>
      </div>
      
      { loading ? <Loading /> : null}
    </>
      
  )
}
export default TestSimilarityAccuracy; 

import { useSignIn } from "api/login";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

const useLoginAction = () => {
  const dispatch = useDispatch();
  const [idCheck, setIdCheck] = useState(false);
  const [id, setId] = useState("");
  const [passwordCheck, setPasswordCheck] = useState(false);
  const [password, setPassword] = useState("");
  const { mutate, data } = useSignIn();

  useEffect(() => {
    if (id !== "" && password !== "") mutate({ id, password });
  }, [id, password, mutate]);

  useEffect(() => {
    if (data !== undefined) {
      if (data.status.code === 400) {
        if (data.status.err.message === "계정이 존재하지 않습니다.") {
          setIdCheck(true);
          setPasswordCheck(true);
        }else if (data.status.err.message === "패스워드가 올바르지 않습니다.") {
          setPasswordCheck(true);
          setIdCheck(false);
        } else {
          setIdCheck(false);
          setPasswordCheck(false);
        }
        
      }
      if (data.status.code === 200) {
        localStorage.setItem(
          "loginData",
          JSON.stringify({
            ad_idx: data.result.ad_idx,
            ad_name: data.result.ad_name,
            ad_username: data.result.ad_username,
            ad_phone: data.result.ad_phone,
            ad_email: data.result.ad_email,
            token: data.result.token,
          })
        );
        window.location.replace("/dashboard/default");
      }
    }
  }, [data, dispatch]);

  const loginAction = (id, password) => {
    setId(id);
    setPassword(password);
  };
  return {
    idCheck,
    passwordCheck,
    loginAction,
  };
};

export default useLoginAction;

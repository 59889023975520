import { createRoot } from "react-dom/client";

// third party
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import App from "App";
import { store } from "store";

// style + assets
import "assets/scss/style.scss";
import config from "./config";
import { QueryClient, QueryClientProvider } from "react-query";
import AuthHigherOrderComponent from "auth/hoc";

const queryClient = new QueryClient();

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename={config.basename}>
        <AuthHigherOrderComponent component={<App />} />
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>
);

import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import LogoImage from "assets/images/icons/Logo_01.png";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { LoginBoxStyle } from "./styled";
import { replacePassword } from "utils/replaceText";

const LoginBox = ({ loginAction, idCheck, passwordCheck }) => {
  const [idText, setidText] = useState("");
  const [passwordText, setPasswordText] = useState("");

  return (
    <LoginBoxStyle>
      <img src={LogoImage} alt="132" />
      <Typography sx={{ mt: 1 }}>관리자 페이지</Typography>
      <TextField
        inputProps={{ pattern: "/[^0-9]/g" }}
        placeholder="아이디를 입력해주세요"
        value={idText}
        onChange={(e) => {
          setidText(replacePassword(e.target.value));
        }}
        size="small"
        error={idCheck}
        sx={{
          mt: 4,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
        }}
      />
      <h1>{idCheck && "아이디를 확인해주세요"}</h1>
      <TextField
        placeholder="비밀번호를 입력해주세요"
        value={passwordText}
        onChange={(e) => {
          setPasswordText(e.target.value);
        }}
        size="small"
        type="password"
        error={passwordCheck}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      />
      <h1>{passwordCheck && "비밀번호를 확인해주세요"}</h1>
      <Button
        variant="contained"
        disabled={ idText === '' || passwordText === '' }
        onClick={() => {
          loginAction(idText, passwordText);
        }}
      >
        로그인
      </Button>
      <Typography
        sx={{ mt: 8, color: "gray", position: "absolute", bottom: 12 }}
      >
        * 비밀번호 분실 시 상위 관리자에게 비밀번호 변경을 요청해주세요
      </Typography>
    </LoginBoxStyle>
  );
};

export default LoginBox;

import { useGetInquiryList } from "api/dashboard";
import { useEffect } from "react";
import { useState } from "react";
import { dateToStringFormat } from "utils";

const UseServiceAction = () => {
  const { data } = useGetInquiryList(0,3);
  const [sampleData, setSampleData] = useState([]);
  useEffect(() => {
    var sampleData = [];
    if (data !== undefined) {
      data.result.map((value) => {
        var title = value.i_title;
        var writer = `${value.m_name}(${value.m_type === "students"?'학습자':'전문가'})`;
        var date =
          dateToStringFormat(new Date(value.i_regDate.split('T')[0])) +
          " " +
          new Date(value.i_regDate).getUTCHours() +
          ":" +
          new Date(value.i_regDate).getUTCMinutes();
        var textBadge = value.i_status === "N";
        sampleData.push({
          indexNumber: value.i_idx,
          title: title,
          date: date,
          writer: writer,
          textBadge: textBadge,
        });
        return 0;
      });
      setSampleData(sampleData);
    }
  }, [data]);

  const clickAction = (sampleCode) => {
    if (sampleCode === undefined) {
      window.location.href = "/serviceManagement";
    } else {
      window.location.href =
        "/serviceManagement/serviceDetail?i_idx=" + sampleCode;
    }
  };
  return { sampleData, clickAction };
};

export default UseServiceAction;

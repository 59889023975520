import { customedAxios } from "api";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

export const useGetInquiryList = (searchValue) => {
  const { data, refetch } = useQuery("getInquiryList", () => {
    return customedAxios
      .get(
        `/inquiry/getInquiryList?page=1&pageSize=1000&startDate=${searchValue.startDate}&endDate=${searchValue.endDate}&searchType=${searchValue.selectedText}&searchValue=${searchValue.searchText}`
      )
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch };
};

export const useDeleteInquiry = () => {
  const { data, mutate, reset } = useMutation(({ list }) => {
    return customedAxios
      .delete(`/inquiry/deleteInquiry`, {
        data: { list },
      })
      .then((response) => {
        return response.data;
      });
  });
  return { mutate, data, reset };
};

export const useGetInquiryByIdx = () => {
  const [searchParams] = useSearchParams();
  const i_idx = Number(searchParams.get("i_idx"));
  const { data, refetch,remove } = useQuery("getInquiryByIdx", () => {
    return customedAxios
      .get(`/inquiry/getInquiryByIdx?i_idx=${i_idx}`)
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch, remove };
};

export const useRegisterInquiryAnswer = () => {
  const [searchParams] = useSearchParams();
  const loginUserData = useSelector((state) => state.loginUserData);
  const i_idx = Number(searchParams.get("i_idx"));
  const config = {
    headers: {
      Authorization: "Bearer " + loginUserData.token,
    },
  };
  const { data, mutate, reset } = useMutation(
    ({ i_answer }) => {
      return customedAxios
        .post(
          `/inquiry/answerInquiry`,
          {
            i_idx,
            i_answer,
          },
          config
        )
        .then((response) => {
          return response.data;
        });
    }
  );
  return { mutate, reset, data };
};

import { customedAxios } from "api";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

export const useGetCaseList = (searchValue) => {
  const { data, refetch } = useQuery("getCaseList", () => {
    return customedAxios
      .get(
        `/case/getCaseList?page=1&pageSize=1000&startDate=${searchValue.startDate}&endDate=${searchValue.endDate}&searchType=${searchValue.selectedText}&searchValue=${searchValue.searchText}`
      )
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch };
};

export const useDeleteCase = () => {
  const { data, mutate, reset } = useMutation(({ list }) => {
    return customedAxios
      .delete(`/case/deleteCase`, {
        data: { list },
      })
      .then((response) => {
        return response.data;
      });
  });
  return { mutate, data, reset };
};

export const useGetCaseByIdx = () => {
  const [searchParams] = useSearchParams();
  const c_idx = Number(searchParams.get("c_idx"));
  const { data, refetch } = useQuery("getCaseByIdx", () => {
    return customedAxios
      .get(`/case/getCaseByIdx?c_idx=${c_idx}`)
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch };
};

export const useUpdateCase = () => {
  const loginUserData = useSelector((state) => state.loginUserData);
  const config = {
    headers: {
      Authorization: "Bearer " + loginUserData.token,
    },
  };
  const [searchParams] = useSearchParams();
  const c_idx = Number(searchParams.get("c_idx"));
  const { data, mutate, reset } = useMutation(
    ({ img_idx, c_name, c_face, c_eyebrow, c_practice }) => {
      return customedAxios
        .put(
          `/case/updateCase`,
          {
            c_idx,
            img_idx,
            c_name,
            c_face,
            c_eyebrow,
            c_practice: c_practice ? "Y" : "N",
          },
          config
        )
        .then((response) => {
          return response.data;
        });
    }
  );
  return { mutate, data, reset };
};

export const useRegisterCase = () => {
  const loginUserData = useSelector((state) => state.loginUserData);
  const config = {
    headers: {
      Authorization: "Bearer " + loginUserData.token,
    },
  };
  const { data, mutate, reset } = useMutation(
    ({ c_name, c_face, c_eyebrow, c_practice }) => {
      return customedAxios
        .post(
          `/case/register`,
          {
            img_idx: localStorage.getItem("imgIdx"),
            c_name,
            c_face,
            c_eyebrow,
            c_practice: c_practice?'Y':'N',
          },
          config
        )
        .then((response) => {
          return response.data;
        });
    }
  );
  return { mutate, data, reset };
};

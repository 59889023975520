import { Typography } from "@mui/material";
import React from "react";
import { DataLabelStyle } from "./styled";

const DataLabel = ({ title, children }) => {
  return (
    <DataLabelStyle>
      <Typography>{title}</Typography>
      {children}
    </DataLabelStyle>
  );
};

export default DataLabel;

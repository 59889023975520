import Search from "components/common/search";
import React from "react";
import { Box } from "@mui/system";
import CustomTable from "components/common/tables/customCheckTable";
import MainCard from "ui-component/cards/MainCard";
import { Button } from "@mui/material";
import CustomDialog from "components/common/customDialog";
import CustomSnackbar from "components/common/customSnackbar";
import useExpertAccept from "hooks/userManagement/useExpertAccept";

const ExpertAccept = () => {
  const { snackbarComponent, handleSnackOpen } = CustomSnackbar();
  const { dialogComponent: acceptDialog, handleDialogOpen: acceptDialogShow } =
    CustomDialog("전문가 승인", "승인하시겠습니까?");
  const { dialogComponent: cancelDialog, handleDialogOpen: cancelDialogShow } =
    CustomDialog("전문가 승인", "반려하시겠습니까?");
  const {
    searchAction,
    dropdownList,
    DateTextList,
    tableColumn,
    selectedId,
    tableData,
    memberList,
    tableCheckAction,
    acceptClickAction,
    cancelClickAction,
  } = useExpertAccept(handleSnackOpen, acceptDialogShow, cancelDialogShow);

  return (
    <Box>
      {acceptDialog}
      {cancelDialog}
      {snackbarComponent}
      <Search
        dropdownList={dropdownList}
        searchAction={searchAction}
        DateTextList={DateTextList}
      />
      <MainCard sx={{ marginTop: "24px" }}>
        <Box
          sx={{
            display: "flex",
            paddingBottom: "24px",
          }}
        >
          <Button
            variant="contained"
            color="success"
            onClick={acceptClickAction}
          >
            승인
          </Button>
          <Button
            sx={{ marginLeft: "12px" }}
            variant="contained"
            color="error"
            onClick={cancelClickAction}
          >
            반려
          </Button>
        </Box>
        {memberList !== undefined && <CustomTable
          tableCheckAction={tableCheckAction}
          selectedId={selectedId}
          data={tableData}
          column={tableColumn}
        />}
      </MainCard>
    </Box>
  );
};

export default ExpertAccept;

import { Button } from "@mui/material";
import { useDeleteAdmin, useGetAdminList } from "api/adminManagement";
import { useEffect } from "react";
import { useState } from "react";
import { dateToStringFormat, phoneAppendHyphen } from "utils";

const UseAdminManagement = (handleSnackOpen, handleDialogOpen) => {
  const tableColumn = [
    { field: "id", headerName: "No", width: 100 },
    {
      field: "ad_name",
      headerName: "이름",
      width: 250,
    },
    { field: "ad_username", headerName: "ID", width: 150 },
    {
      field: "ad_phone",
      headerName: "휴대전화",
      width: 150,
      renderCell: (param) => {
        return phoneAppendHyphen(param.value);
      },
    },
    { field: "ad_email", headerName: "이메일", width: 150 },
    { field: "ad_regDate", headerName: "생성일", width: 150 },
    {
      field: "manage",
      headerName: "관리",
      width: 100,
      sortable: false,
      renderCell: (param) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              localStorage.setItem(
                "adminDetailInfo",
                JSON.stringify(param.row)
              );
              window.location.href =
                "/adminManagement/adminDetail?idx=" + param.row.ad_idx;
            }}
          >
            수정
          </Button>
        );
      },
    },
  ];
  const [selectedId, setSelectedId] = useState([]);
  const [tableData, setTableData] = useState([]);
  const { data: memberList,refetch } = useGetAdminList();
  const { data: deleteData, mutate, reset } = useDeleteAdmin();
  
  useEffect(() => {
    if (memberList !== undefined) {
      var tableData = memberList.status.result;

      tableData.map((value, index) => {
        tableData[index].id = index + 1;
        var date = new Date(value.ad_regDate.split("T")[0]);
        tableData[index].ad_regDate = dateToStringFormat(date, "-");
        return 0;
      });
      setTableData(tableData);
    }
  }, [memberList]);

  useEffect(() => {
    if (deleteData !== undefined) {
      if (deleteData.status.code === 200) {
        refetch();
        reset();
      }
    }
  }, [deleteData, refetch, reset]);

  const removeAction = () => {
    var list = [];
    selectedId.map((value) => {
      list.push(tableData[value - 1].ad_idx);
      return 0;
    });
    mutate({ list });
    setSelectedId([]);
    handleSnackOpen("삭제 완료되었습니다.");
  };

  const tableCheckAction = (ids) => {
    setSelectedId(ids);
  };

  const removeClickAction = () => {
    if (selectedId.length === 0) {
      handleSnackOpen("선택한 항목이 없습니다.");
    } else {
      handleDialogOpen(removeAction);
    }
  };

  return {
    tableColumn,
    tableData,
    tableCheckAction,
    removeClickAction,
    selectedId,
    memberList,
  };
};

export default UseAdminManagement;

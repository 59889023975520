import { customedAxios } from "api";
import { useMutation } from "react-query";

// Content-Type 설정
const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const useAIUpload = () => {
  const { data, mutate,isLoading, reset } = useMutation(({ formData }) => {
    return customedAxios
      .post(`/ai/uploadImage`, formData, config)
      .then((response) => {
        return response.data;
      });
  });
  return { mutate, data, isLoading, reset };
};

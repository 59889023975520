import { useEffect, useState } from "react";

// material-ui
import { Grid } from "@mui/material";

// project imports
import PopularCard from "components/dashboard/PopularCard";
import TotalGrowthBarChart from "components/dashboard/TotalGrowthBarChart";
import { gridSpacing } from "store/constant";
import Sample from "components/sample";
import UseStudentAction from "hooks/dashboard/useStudentAction";
import SampleHorizontal from "components/sampleHorizontal";
import UseExpertAction from "hooks/dashboard/useExpertAction";
import UseQnaAction from "hooks/dashboard/useQnaAction";
import UseServiceAction from "hooks/dashboard/useServiceAction";
import UseCaseAction from "hooks/dashboard/useCaseAction";

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Dashboard = () => {
  const [isLoading, setLoading] = useState(true);

  const { clickAction: studentClickAction, sampleData: studentSampleData } =
    UseStudentAction();
  const { clickAction: expertClickAction, sampleData: expertSampleData } =
    UseExpertAction();
  const { clickAction: QnaClickAction, sampleData: QnaSampleData } =
    UseQnaAction();
  const { clickAction: serviceClickAction, sampleData: serviceSampleData } =
    UseServiceAction();
  const { clickAction: caseClickAction, sampleData: caseSampleData } =
    UseCaseAction();
  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item md={6}>
            <PopularCard isLoading={isLoading} />
          </Grid>
          <Grid item md={6}>
            <TotalGrowthBarChart isLoading={isLoading} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item md={6} xs={6}>
            <Sample
              clickAction={studentClickAction}
              sampleData={studentSampleData}
              titleText="최근 진행된 실습"
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <Sample
              clickAction={expertClickAction}
              sampleData={expertSampleData}
              titleText="최근 등록된 최적 사례"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <SampleHorizontal
            clickAction={caseClickAction}
            sampleData={caseSampleData}
            titleText="인기 있는 실습 케이스"
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item md={6} xs={6}>
            <Sample
              clickAction={QnaClickAction}
              sampleData={QnaSampleData}
              titleText="최근 등록된 Q&A"
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <Sample
              clickAction={serviceClickAction}
              sampleData={serviceSampleData}
              titleText="최근 등록된 1:1 문의"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;

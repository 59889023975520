import React from "react";

import { useState } from "react";
import { IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CustomSnackbar = () => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");

  const handleSnackOpen = (text) => {
    setOpen(true);
    setText(text);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const snackbarComponent = (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={open}
      autoHideDuration={1500}
      onClose={handleClose}
      message={text}
      action={action}
    />
  );
  return { handleSnackOpen, snackbarComponent };
};

export default CustomSnackbar;

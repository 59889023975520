import { Breadcrumbs, Link, Typography } from "@mui/material";
import menuItems from "menu-items";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { SET_MENU } from "store/actions";

const SearchSection = () => {
  const [firstItem, setFirstItem] = useState("");
  const [secondItem, setSecondItem] = useState("");
  const [thirdItem, setThirdItem] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    setFirstItem("");
    setSecondItem("");
    setThirdItem("");
    dispatch({ type: SET_MENU, opened: true });
    menuItems.items[0].children.map((value) => {
      if (window.location.href.includes(value.id)) {
        setFirstItem(value);
        if (value.children !== undefined) {
          value.children.map((innerValue) => {
            if (window.location.href.includes(innerValue.id)) {
              setSecondItem(innerValue);
              if (innerValue.children !== undefined) {
                innerValue.children.map((deepValue) => {
                  if (window.location.href.includes(deepValue.id)) {
                    setThirdItem(deepValue);
                  }
                  return 0;
                });
              }
            }
            return 0;
          });
        }
      }
      return 0;
    });
  }, [location.pathname, dispatch]);
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ pl: 4 }}>
        <Link underline="hover" color="inherit" href="/dashboard/default">
          Admin 홈
        </Link>
        {firstItem !== "" ? (
          secondItem === "" ? (
            <Typography color="text.primary">{firstItem.title}</Typography>
          ) : (
            <Link underline="hover" color="inherit" href={firstItem.url}>
              {firstItem.title}
            </Link>
          )
        ) : (
          ""
        )}
        {secondItem !== "" ? (
          thirdItem === "" ? (
            <Typography color="text.primary">{secondItem.title}</Typography>
          ) : (
            <Link underline="hover" color="inherit" href={secondItem.url}>
              {secondItem.title}
            </Link>
          )
        ) : (
          ""
        )}
        {thirdItem !== "" && (
          <Typography color="text.primary">{thirdItem.title}</Typography>
        )}
      </Breadcrumbs>
    </>
  );
};

export default SearchSection;

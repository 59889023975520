import { customedAxios } from "api";
import { useQuery } from "react-query";

export const useGetMainMemberStatisticsListAll = () => {
  const { data } = useQuery("getMainMemberStatisticsListAll", () => {
    return customedAxios
      .get(`/main/getMainMemberStatisticsListAll`)
      .then((response) => {
        return response.data;
      });
  });
  return { data };
};

export const useGetMainMemberStatisticsListMonth = () => {
  const { data } = useQuery("getMainMemberStatisticsListMonth", () => {
    return customedAxios
      .get(`/main/getMainMemberStatisticsListMonth`)
      .then((response) => {
        return response.data;
      });
  });
  return { data };
};

export const useGetMainTrainingStatisticsWeek = () => {
  const { data } = useQuery("getMainTrainingStatisticsWeek", () => {
    return customedAxios
      .get(`/main/getMainTrainingStatisticsWeek`)
      .then((response) => {
        return response.data;
      });
  });
  return { data };
};

export const useGetStudentList = (page, pageSize) => {
  const { data } = useQuery("getStudentList", () => {
    return customedAxios
      .get(`/training/getStudentList?page=${page}&pageSize=${pageSize}`)
      .then((response) => {
        return response.data;
      });
  });
  return { data };
};

export const useGetPopularCase = () => {
  const { data } = useQuery("getPopularCase", () => {
    return customedAxios.get(`/main/getPopularCase`).then((response) => {
      return response.data;
    });
  });
  return { data };
};

export const useGetExpertList = (page, pageSize) => {
  const { data } = useQuery("getExpertList", () => {
    return customedAxios
      .get(`/training/getExpertList?page=${page}&pageSize=${pageSize}`)
      .then((response) => {
        return response.data;
      });
  });
  return { data };
};

export const useGetQnaList = (page, pageSize) => {
  const { data } = useQuery("getQnaList", () => {
    return customedAxios
      .get(`/qna/getQnaList?page=${page}&pageSize=${pageSize}`)
      .then((response) => {
        return response.data;
      });
  });
  return { data };
};

export const useGetInquiryList = (page, pageSize) => {
  const { data } = useQuery("getInquiryList", () => {
    return customedAxios
      .get(`/inquiry/getInquiryList?page=${page}&pageSize=${pageSize}`)
      .then((response) => {
        return response.data;
      });
  });
  return { data };
};

import { customedAxios } from "api";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

export const useGetNoticeList = (searchValue) => {
  const { data, refetch } = useQuery("getNoticeList", () => {
    return customedAxios
      .get(
        `/notice/getNoticeList?page=1&pageSize=1000&startDate=${searchValue.startDate}&endDate=${searchValue.endDate}&searchType=${searchValue.selectedText}&searchValue=${searchValue.searchText}`
      )
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch };
};

export const useDeleteNotice = () => {
  const { data, mutate, reset } = useMutation(({ list }) => {
    return customedAxios
      .delete(`/notice/deleteNotice`, {
        data: { list },
      })
      .then((response) => {
        return response.data;
      });
  });
  return { mutate, data, reset };
};

export const useGetNoticeByIdx = () => {
  const [searchParams] = useSearchParams();
  const no_idx = Number(searchParams.get("no_idx"));
  const { data, refetch,remove } = useQuery("getNoticeByIdx", () => {
    return customedAxios
      .get(`/notice/getNoticeByIdx?no_idx=${no_idx}`)
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch, remove };
};

export const useRegisterNotice = () => {
  const loginUserData = useSelector((state) => state.loginUserData);
  const config = {
    headers: {
      Authorization: "Bearer " + loginUserData.token,
    },
  };
  const { data, mutate, reset } = useMutation(
    ({ no_type, no_title, no_contents }) => {
      return customedAxios
        .post(
          `/notice/register`,
          {
            no_type,
            no_title,
            no_contents,
          },
          config
        )
        .then((response) => {
          return response.data;
        });
    }
  );
  return { mutate, reset, data };
};

export const useUpdateNotice = () => {
  const [searchParams] = useSearchParams();
  const loginUserData = useSelector((state) => state.loginUserData);
  const config = {
    headers: {
      Authorization: "Bearer " + loginUserData.token,
    },
  };
  const no_idx = Number(searchParams.get("no_idx"));
  const { data, mutate } = useMutation(
    ({ no_type, no_title, no_contents }) => {
      return customedAxios
        .put(
          `/notice/updateNotice`,
          {
            no_idx,
            no_type,
            no_title,
            no_contents,
          },
          config
        )
        .then((response) => {
          return response.data;
        });
    }
  );
  return { mutate, data };
};
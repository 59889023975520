import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./customizationReducer";
import loginUserDataReducer from "./loginUserData";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  loginUserData: loginUserDataReducer,
});

export default reducer;

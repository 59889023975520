import PropTypes from "prop-types";
// material-ui
import {
  CardContent,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

// project imports
import MainCard from "ui-component/cards/MainCard";
import { gridSpacing } from "store/constant";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";
import React, { useEffect, useState } from "react";
import { MemberStatChartStyle, PieBottomLabel, PieTopLabel } from "./styled";
import {
  useGetMainMemberStatisticsListAll,
  useGetMainMemberStatisticsListMonth,
} from "api/dashboard";

// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const PopularCard = () => {
  const { data: listAllData } = useGetMainMemberStatisticsListAll();
  const { data: listMonthData } = useGetMainMemberStatisticsListMonth();

  const [data, setData] = useState([
    { value: 65, label: "학습자" },
    { value: 25, label: "전문가" },
    { value: 10, label: "전문가(승인 대기)" },
  ]);
  const size = {
    // width: 500,
    height: 468,
    margin: { right: 150 },
  };
  const color = ["#0156D0", "#409AEA", "#9FD3FA"];
  const [alignment, setAlignment] = useState("ALL");
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (listAllData !== undefined && listMonthData !== undefined) {
      const listAll = listAllData.result;
      const listMonth = listMonthData.result;
      if (alignment === "ALL") {
        const total = listAll.studentsCnt + listAll.expertCnt + listAll.watingExpertCnt;
        setData([
          {
            count: listAll.studentsCnt,
            value: Math.round((listAll.studentsCnt / total) * 100),
            label: "학습자",
          },
          {
            count: listAll.expertCnt,
            value: Math.round((listAll.expertCnt / total) * 100),
            label: "전문가",
          },
          {
            count: listAll.watingExpertCnt,
            value: Math.round((listAll.watingExpertCnt / total) * 100),
            label: "전문가(승인 대기)",
          },
        ]);
      } else {
        const total =
          listMonth.studentsCnt + listMonth.expertCnt + listMonth.watingExpertCnt;
        setData([
          { count: listMonth.studentsCnt, value: Math.round((listMonth.studentsCnt / total) * 100), label: "학습자" },
          { count: listMonth.expertCnt, value: Math.round((listMonth.expertCnt / total) * 100), label: "전문가" },
          { count: listMonth.watingExpertCnt, value: Math.round((listMonth.watingExpertCnt / total) * 100), label: "전문가(승인 대기)" },
        ]);
      }
    }
  }, [alignment, listAllData, listMonthData]);
  useEffect(() => {
    var total = 0;
    data.map((value) => {
      return (total += value.count);
    });
    setTotal(total);
  }, [data]);
  return (
    <MemberStatChartStyle>
      <MainCard content={false}>
        <CardContent>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography variant="h3">회원 통계</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <TextField
                    id="standard-select-currency"
                    select
                    value={alignment}
                    onChange={(e) => {
                      setAlignment(e.target.value);
                    }}
                  >
                    <MenuItem value={"ALL"}>전체 기간</MenuItem>
                    <MenuItem value={"MONTH"}>당월</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <PieChart
                colors={color}
                series={[
                  {
                    data,
                    innerRadius: 140,
                    outerRadius: 200,
                    arcLabel: (item) => `${item.value}%`,
                    arcLabelMinAngle: 1,
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fontWeight: "bold",
                    fill: "white",
                  },
                }}
                {...size}
              >
                <PieTopLabel>전체 회원 수</PieTopLabel>
                <PieBottomLabel>{total}명</PieBottomLabel>
              </PieChart>
            </Grid>
          </Grid>
        </CardContent>
      </MainCard>
    </MemberStatChartStyle>
  );
};

PopularCard.propTypes = {
  isLoading: PropTypes.bool,
};

export default PopularCard;

import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useState } from "react";
import { DialogContentText } from "@mui/material";

const CustomDialog = (title, text) => {
  const [open, setOpen] = useState(false);
  const [customAction, setCustomAction] = useState();

  const handleDialogOpen = (customAction) => {
    setCustomAction({ action: customAction });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const actionClose = () => {
    customAction.action();
    setOpen(false);
  };
  const dialogComponent = (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ m: 0, p: 2, fontSize: "16px", fontWeight: "bold" }}
      >
        {title}
      </DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ fontSize: "16px", whiteSpace: "pre-wrap", textAlign: "center" }}
        >
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>취소</Button>
        <Button onClick={actionClose} autoFocus>
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
  return { handleDialogOpen, dialogComponent };
};

export default CustomDialog;

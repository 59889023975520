import { useGetExpertList } from "api/dashboard";
import { useEffect } from "react";
import { useState } from "react";
import { dateToStringFormat } from "utils";

const UseExpertAction = () => {
  const [sampleData, setSampleData] = useState([]);
  const { data } = useGetExpertList(0, 3);
  useEffect(() => {
    var sampleData = [];
    if (data !== undefined) {
      data.result.map((value) => {
        var imageSource =
          process.env.REACT_APP_IMAGE_BASE_URL + value.training_img_name;
        var title = value.c_name;
        var subText = `${value.c_face} / ${value.c_eyebrow}`;
        var writer = `전문가명 : ${value.m_name}`;
        var date =
          dateToStringFormat(new Date(value.t_regDate.split("T")[0])) +
          " " +
          new Date(value.t_regDate).getUTCHours() +
          ":" +
          new Date(value.t_regDate).getUTCMinutes();
        sampleData.push({
          indexNumber: value.t_idx,
          imageSource: imageSource,
          title: title,
          subText: subText,
          date: date,
          writer: writer,
        });
        return 0;
      });
      setSampleData(sampleData);
    }
  }, [data]);
  const clickAction = (sampleCode) => {
    if (sampleCode === undefined) {
      window.location.href = "/studentManagement/expertManagement";
    } else {
      window.location.href =
        "/studentManagement/expertManagement/expertDetail?t_idx=" + sampleCode;
    }
  };
  return { sampleData, clickAction };
};

export default UseExpertAction;

import Search from "components/common/search";
import React from "react";
import { Box } from "@mui/system";
import CustomTable from "components/common/tables/customTable";
import MainCard from "ui-component/cards/MainCard";
import useWithdrawalList from "hooks/userManagement/useWithdrawalList";

const WithdrawalList = () => {
  const {
    searchAction,
    dropdownList,
    DateTextList,
    tableColumn,
    tableData,
    memberList,
  } = useWithdrawalList();

  return (
    <Box>
      <Search
        dropdownList={dropdownList}
        searchAction={searchAction}
        DateTextList={DateTextList}
      />
      <MainCard sx={{ marginTop: "24px" }}>
        {memberList !== undefined && (
          <CustomTable data={tableData} column={tableColumn} />
        )}
      </MainCard>
    </Box>
  );
};

export default WithdrawalList;

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ButtonBase, Typography } from '@mui/material';

// project imports
import config from 'config';
import { MENU_OPEN } from 'store/actions';
import LogoLmage from 'assets/images/icons/Logo_01.png';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const defaultId = useSelector((state) => state.customization.defaultId);
  const dispatch = useDispatch();
  return (
    <ButtonBase
      disableRipple
      onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })}
      component={Link}
      to={config.defaultPath}
      sx={{ width: '100%', flexDirection: 'column' }}
    >
      <img src={LogoLmage} alt="1234" style={{ width: 'calc(100% - 42px)' }} />
      <Typography sx={{ mt: 1, fontWeight: 'bold' }}>관리자 페이지</Typography>
    </ButtonBase>
  );
};

export default LogoSection;

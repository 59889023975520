import { useGetMemberByIdx } from "api/userManagement";
import { useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const useUserDetail = () => {
  const [userData, setUserData] = useState();
  const [selectedText, setSelectedText] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("01026346322");
  const [count, setCount] = useState("");
  const [QA, setQA] = useState("");
  const [imageSource, setImageSource] = useState(undefined);
  const [searchParams] = useSearchParams();
  const m_idx = searchParams.get("m_idx");
  const { data } = useGetMemberByIdx(m_idx);
  useEffect(() => {
    if (data !== undefined && data.status.code === 200) {
      setUserData(data.status.result);
    }
  }, [data]);

  useEffect(() => {
    if (userData !== undefined) {
      localStorage.setItem("imgIdx", userData.img_idx);
      setSelectedText(userData.m_type);
      setName(userData.m_name);
      setEmail(userData.m_email);
      setPhone(userData.m_phone.replaceAll("-", ""));
      setCount(userData.trainingCnt);
      setQA(userData.qnaCnt);
      setImageSource(process.env.REACT_APP_IMAGE_BASE_URL + userData.img_name);
    }
  }, [userData]);

  return {
    userData,
    selectedText,
    setSelectedText,
    name,
    email,
    phone,
    count,
    QA,
    imageSource,
    setName,
    setEmail,
    setPhone,
    setCount,
    setQA,
  };
};

export default useUserDetail;

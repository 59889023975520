import { Button } from "@mui/material";
import { useDeleteFeedback, useGetFeedbackList } from "api/feedbackManagement";
import FeedbackDetail from "components/feedbackManagement/feedbackDetail";
import { useEffect } from "react";
import { useState } from "react";
import { dateToStringFormat, leftPad } from "utils";

const UseFeedbackManagement = (handleSnackOpen, handleDialogOpen) => {
  const [searchValue, setSearchValue] = useState({
    startDate: "",
    endDate: "",
    selectedText: "",
    searchText: "",
  });
  const searchAction = (startDate, endDate, selectedText, searchText) => {
    setSearchValue({
      startDate:
        startDate === undefined || startDate === null
          ? ""
          : startDate.$y +
            "-" +
            leftPad(startDate.$M + 1) +
            "-" +
            leftPad(startDate.$D),
      endDate:
        endDate == null
          ? ""
          : endDate.$y +
            "-" +
            leftPad(endDate.$M + 1) +
            "-" +
            leftPad(endDate.$D),
      selectedText,
      searchText,
    });
  };
  const { data: memberList, refetch } = useGetFeedbackList(searchValue);
  const { data: quitResponse, mutate } = useDeleteFeedback();
  useEffect(() => {
    refetch();
  }, [searchValue, refetch]);

  useEffect(() => {
    if (quitResponse !== undefined) {
      if (quitResponse.status.code === 200) {
        refetch();
      }
    }
  }, [quitResponse, refetch]);
  const { handleFeedbackDialogOpen, feedbackDialogComponent } =
    FeedbackDetail();
  const dropdownList = [
    { value: "c_name", text: "실습 케이스명" },
    { value: "tb_member.m_name", text: "작성자" },
  ];
  const DateTextList = ["작성일", "시작일", "종료일"];
  const tableColumn = [
    { field: "id", headerName: "No", width: 100 },
    {
      field: "c_name",
      headerName: "실습 케이스명",
      width: 250,
    },
    { field: "f_contents", headerName: "피드백 내용", width: 450, flex: 1 },
    { field: "m_name", headerName: "작성자", width: 200 },
    { field: "f_regDate", headerName: "작성일", width: 150 },
    {
      field: "manage",
      headerName: "관리",
      width: 100,
      sortable: false,
      renderCell: (param) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              handleFeedbackDialogOpen(param.row);
            }}
          >
            상세
          </Button>
        );
      },
    },
  ];
  const [selectedId, setSelectedId] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (memberList !== undefined) {
      var tableData = memberList.result;

      tableData.map((value, index) => {
        tableData[index].id = index + 1;
        var date = new Date(value.f_regDate.split("T")[0]);
        tableData[index].f_regDate = dateToStringFormat(date, "-");
        return 0;
      });
      setTableData(tableData);
    }
  }, [memberList]);

  const removeAction = () => {
    var list = [];
    selectedId.map((value) => {
      list.push(tableData[value - 1].f_idx);
      return 0;
    });
    mutate({ list: list });
    setSelectedId([]);
    handleSnackOpen("삭제 완료되었습니다.");
  };
  const tableCheckAction = (ids) => {
    setSelectedId(ids);
  };

  const removeClickAction = () => {
    if (selectedId.length === 0) {
      handleSnackOpen("선택한 항목이 없습니다.");
    } else {
      handleDialogOpen(removeAction);
    }
  };

  return {
    selectedId,
    searchAction,
    dropdownList,
    DateTextList,
    tableColumn,
    tableData,
    tableCheckAction,
    removeClickAction,
    feedbackDialogComponent,
    memberList,
  };
};

export default UseFeedbackManagement;

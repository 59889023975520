import { customedAxios } from "api";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

export const useGetStudentList = (searchValue) => {
  const { data, refetch } = useQuery("getStudentList", () => {
    return customedAxios
      .get(
        `/training/getStudentList?page=1&pageSize=1000&startDate=${searchValue.startDate}&endDate=${searchValue.endDate}&searchType=${searchValue.selectedText}&searchValue=${searchValue.searchText}&m_type=${searchValue.selectedType}`
      )
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch };
};

export const useGetExpertList = (searchValue) => {
  const { data, refetch } = useQuery("getExpertList", () => {
    return customedAxios
      .get(
        `/training/getExpertList?page=1&pageSize=1000&startDate=${searchValue.startDate}&endDate=${searchValue.endDate}&searchType=${searchValue.selectedText}&searchValue=${searchValue.searchText}&m_type=${searchValue.selectedType}`
      )
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch };
};

export const useDeleteTraining = () => {
  const { data, mutate, reset } = useMutation(({ list }) => {
    return customedAxios
      .delete(`/training/deleteTraining`, {
        data: { list },
      })
      .then((response) => {
        return response.data;
      });
  });
  return { mutate, data, reset };
};

export const useGetTrainingByIdx = () => {
  const [searchParams] = useSearchParams();
  const t_idx = Number(searchParams.get("t_idx"));
  const { data, refetch } = useQuery("getTrainingByIdx", () => {
    return customedAxios
      .get(`/training/getTrainingByIdx?t_idx=${t_idx}`)
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch };
};

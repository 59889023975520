import Login from 'views/login';

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <Login />
};

export default AuthenticationRoutes;

import {
  Button,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CenterBox from "components/common/centerBox";
import React from "react";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import DataLabel from "components/common/dataLabel";
import { useState } from "react";
import { useEffect } from "react";
import CustomDialog from "components/common/customDialog";
import ImageUpload from "components/common/imageUploader/custom";
import { eyebrowList, faceList, getToday } from "utils";
import { Box } from "@mui/system";
import { useRegisterCase } from "api/caseManagement";

const CaseSign = () => {
  const [name, setName] = useState("");
  const [face, setFace] = useState("default");
  const [eyebrow, setEyebrow] = useState("default");
  const [manager, setManager] = useState("이선용");
  const [fit, setFit] = useState("");
  const [count, setCount] = useState("");
  const { dialogComponent, handleDialogOpen } = CustomDialog(
    "케이스 등록",
    "실습 케이스를 등록하시겠습니까?"
  );
  const { data, mutate } = useRegisterCase();
  useEffect(() => {
    if (data !== undefined) {
      if (data.status.code === 200) {
        localStorage.setItem("popupText", "실습 케이스가 등록되었습니다.");
        // window.history.back();
        window.location.replace("/caseManagement");
      } else if (
        data.status.code === 400 &&
        data.status.message.includes("jwt")
      ) {
        alert("로그인 권한이 만료되었습니다. 다시 로그인해주세요.");
        window.location.replace("/");
      }
    }
  }, [data, handleDialogOpen]);

  const [caseCheck, setCaseCheck] = useState(false);
  const { imageUploaded, uploadComponent } = ImageUpload();
  useEffect(() => {
    if (caseCheck) {
      setFace("연습용");
    } else {
      setFace("default");
    }
  }, [caseCheck]);

  useEffect(() => {
    if (imageUploaded !== 0) {
      if (caseCheck) {
        setFace("연습용");
      } else {
        setFace(localStorage.getItem("AIFace"));
      }
    }
  }, [imageUploaded, caseCheck]);

  return (
    <CenterBox>
      {dialogComponent}
      <MainCard>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={4}>
            {uploadComponent}
          </Grid>
          <Grid item xs={8}>
            <Grid item>
              <DataLabel
                title={"케이스명"}
                children={
                  <TextField
                    value={name}
                    placeholder="케이스명을 입력해주세요."
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    sx={{ width: "calc(100% - 120px)" }}
                  ></TextField>
                }
              />
            </Grid>
            <Grid
              item
              sx={{
                paddingTop: "24px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={5}>
                <DataLabel
                  title={"얼굴형"}
                  children={
                    <Select
                      value={face}
                      defaultValue=""
                      disabled={caseCheck}
                      onChange={(e) => {
                        setFace(e.target.value);
                      }}
                      sx={{ width: "calc(100% - 120px)" }}
                    >
                      <MenuItem disabled value="default">
                        이미지 업로드 시 자동 입력
                      </MenuItem>
                      {face !== "default" && !faceList.includes(face) && (
                        <MenuItem value={face}>{face}</MenuItem>
                      )}
                      {faceList.map((value) => {
                        return <MenuItem value={value}>{value}</MenuItem>;
                      })}
                    </Select>
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <DataLabel
                  title={"눈썹 유형"}
                  children={
                    <Select
                      value={eyebrow}
                      defaultValue=""
                      onChange={(e) => {
                        setEyebrow(e.target.value);
                      }}
                      sx={{ width: "calc(100% - 120px)" }}
                    >
                      <MenuItem disabled value="default">
                        눈썹 유형을 선택해 주세요
                      </MenuItem>
                      {eyebrowList.map((value) => {
                        return <MenuItem value={value}>{value}</MenuItem>;
                      })}
                    </Select>
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                paddingTop: "24px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={5}>
                <DataLabel
                  title={"등록일"}
                  children={
                    <TextField
                      value={getToday()}
                      disabled
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <DataLabel
                  title={"등록 관리자"}
                  children={
                    <TextField
                      value={manager}
                      disabled
                      onChange={(e) => {
                        setManager(e.target.value);
                      }}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                paddingTop: "24px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={5}>
                <DataLabel
                  title={"최적 사례 개수"}
                  children={
                    <TextField
                      value={fit}
                      disabled
                      onChange={(e) => {
                        setFit(e.target.value);
                      }}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <DataLabel
                  title={"실습 참여 건수"}
                  children={
                    <TextField
                      value={count}
                      disabled
                      onChange={(e) => {
                        setCount(e.target.value);
                      }}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                paddingTop: "24px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={5}>
                <DataLabel
                  title={"연습용 케이스 설정"}
                  children={
                    <Box sx={{ width: "calc(100% - 120px)" }}>
                      <Checkbox
                        checked={caseCheck}
                        onChange={(event) => setCaseCheck(event.target.checked)}
                        name="checked"
                        color="primary"
                      />
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
      <Grid sx={{ width: "100%", paddingTop: "24px", textAlign: "right" }}>
        <Button
          variant={"contained"}
          disabled={
            face === "default" ||
            name === "" ||
            face === "" ||
            eyebrow === "default" ||
            imageUploaded === 0
          }
          onClick={() => {
            handleDialogOpen(() => {
              mutate({
                c_name: name,
                c_face: face,
                c_eyebrow: eyebrow,
                c_practice: caseCheck,
              });
            });
            
          }}
        >
          저장
        </Button>
      </Grid>
    </CenterBox>
  );
};

export default CaseSign;

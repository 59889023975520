import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "../CustomNoRowsOverlay";

export default function CustomTable({ tableCheckAction, data, column, selectedId }) {
  const customColumn = column;
  customColumn.map((value) => {
    value.flex = 1;
    return value;
  });
  return (
    <div style={{ height: "auto", width: "100%" }}>
      <DataGrid
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        sx={{
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: " center",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-cell--textLeft": {
            justifyContent: " center",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "& .MuiDataGrid-cellContent": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
          "&.MuiDataGrid-root .MuiDataGrid-virtualScrollerContent": {
            minHeight:
              data.length === 0 ? "200px !important" : "100% !important",
          },
        }}
        rows={data}
        columns={customColumn}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        rowSelectionModel={selectedId}
        disableColumnMenu
        pageSizeOptions={[10, 25, 50, 100]}
        onRowSelectionModelChange={tableCheckAction}
      />
    </div>
  );
}

import styled from '@emotion/styled';

export const LoginBoxStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  width: 460px;
  margin: 0 auto;
  box-sizing: border-box;
  > img {
    width: 75%;
  }
  > h1 {
    width: 75%;
    height: 24px;
    margin-top: 8px;
    font-size: 12px;
    text-align: left;
    margin-left: 12px;
    color: #ff7377;
  }
  .MuiFormControl-root {
    width: 75% !important;
  }
  button {
    width: 75%;
  }
`;

import styled from "@emotion/styled";

export const CustomImageWrap = styled.div`
  width: 220px;
  height: 300px;
  border: 1px solid gray;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  > label {
    width: 180px;
    height: 30px;
    border-radius: 12px;
    background: gray;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CustomCircleWrap = styled.div`
  width: 200px;
  height: 200px;
  border: 1px solid gray;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  > label {
    width: 180px;
    height: 30px;
    border-radius: 12px;
    background: gray;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CustomImageLabel = styled.label`
  position: absolute;
  bottom: 20px;
  right: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.46);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    transform: scale(4);
    filter: brightness(0) invert(1);
  }
`;
import { Button, Grid, Slider, TextField, Typography } from "@mui/material";
import CenterBox from "components/common/centerBox";
import React from "react";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import DataLabel from "components/common/dataLabel";
import ImageUploader from "components/common/imageUploader/customCircle";
import CustomDialog from "components/common/customDialog";
import CustomSnackbar from "components/common/customSnackbar";
import { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { useEffect } from "react";
import { ShapeWrap } from "./styled";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomImage from "components/common/customImage";
import { useRef } from "react";
import { EmptyWrap } from "../neddleAppend/styled";
import { useGetNeedleByIdx, useUpdateNeddle } from "api/neddleManagement";
import { useImageUpload } from "api/imageUpload";
import { getS3Image } from "utils";
import loadingSvg from "assets/images/icons/loading.svg";
import { LoadingWrapper } from "components/common/imageUploader/styled";

const NeddleDetail = () => {
  const [product, setProduct] = useState("");
  const [explain, setExplain] = useState("");
  const [link, setLink] = useState("");
  const [thumbnail, setThumbnail] = useState(undefined);
  const { dialogComponent, handleDialogOpen } = CustomDialog(
    "니들 정보 수정",
    "수정된 내용으로 저장하시겠습니까?"
  );
  const { snackbarComponent } = CustomSnackbar();
  const [selectedFile, setSelectedFile] = useState(undefined);
  const ref = useRef(null);
  const { data } = useGetNeedleByIdx();
  const { data: neddleChange, mutate } = useUpdateNeddle();
  const {
    data: imageData,
    isLoading,
    mutate: imageUpload,
    reset: resetUpload,
  } = useImageUpload();

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (neddleChange !== undefined) {
      if (neddleChange.status.code === 200) {
        localStorage.setItem("popupText", "니들 정보가 수정되었습니다.");
        window.location.replace("/neddleManagement");
      }
    }
  }, [neddleChange]);

  useEffect(() => {
    if (
      selectedFile !== undefined &&
      !(selectedFile instanceof File) &&
      imageData !== undefined
    ) {
      if (imageData.status.code === 200) {
        setSelectedFile(undefined);
        ref.current.value = "";
        setItems([
          ...items,
          {
            thumbnail: imageData.imgdata.imgPath,
            img_idx: imageData.imgdata.imgIdx,
            width: 0.2,
          },
        ]);
      } else if (imageData.status.code === 400) {
        alert(imageData.status.message);
      }
      resetUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageData, selectedFile]);

  const uploadClick = (e) => {
    if (items.length <= 9) {
      var file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        setSelectedFile([reader.result]);
      };
      setSelectedFile(file);
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      imageUpload({ formData });
    }
    e.target.value = "";
  };
  const { uploadComponent } = ImageUploader(thumbnail);

  useEffect(() => {
    if (data !== undefined) {
      if (data.status.code === 200) {
        setProduct(data.status.result.needleData.n_productName);
        setExplain(data.status.result.needleData.n_productDescription);
        setLink(data.status.result.needleData.n_productUrl);
        setThumbnail(getS3Image(data.status.result.needleData.img_name));
        localStorage.setItem("imgIdx", data.status.result.needleData.img_idx);
        var defaultItems = [];
        data.status.result.needleShapeData.map((value) => {
          defaultItems.push({
            thumbnail: getS3Image(value.img_name),
            img_idx: value.img_idx,
            width: value.ns_size,
            ns_idx: value.ns_idx,
          });
          return 0;
        });
        setItems(defaultItems);
      }
    }
  }, [data]);

  return (
    <>
      {dialogComponent}
      {snackbarComponent}
      {isLoading && (
        <LoadingWrapper>
          <img src={loadingSvg} alt="" />
        </LoadingWrapper>
      )}
      <CenterBox>
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={4}>
              {uploadComponent}
              {/* <img src={sampleImage1} alt="image" style={{ width: "100%" }} /> */}
            </Grid>
            <Grid item xs={8}>
              <Grid item>
                <DataLabel
                  title={"제품명"}
                  children={
                    <TextField
                      value={product}
                      placeholder="제품명을 입력해주세요. (최대 50자)"
                      onChange={(e) => {
                        setProduct(e.target.value);
                      }}
                      inputProps={{ maxLength: 50 }}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
              <Grid item sx={{ paddingTop: "24px" }}>
                <DataLabel
                  title={"제품 설명"}
                  children={
                    <TextField
                      value={explain}
                      placeholder="제품 설명을 입력해주세요. (최대 200자)"
                      onChange={(e) => {
                        setExplain(e.target.value);
                      }}
                      multiline
                      minRows={5}
                      maxRows={5}
                      inputProps={{
                        maxLength: 200,
                      }}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
              <Grid item sx={{ paddingTop: "24px" }}>
                <DataLabel
                  title={"제품 링크"}
                  children={
                    <TextField
                      value={link}
                      placeholder="제품 구매 링크를 입력해주세요."
                      onChange={(e) => {
                        setLink(e.target.value);
                      }}
                      multiline
                      minRows={2}
                      maxRows={3}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
              <Grid item sx={{ paddingTop: "24px" }}>
                <DataLabel
                  title={"니들 모양"}
                  children={
                    <Grid sx={{ width: "calc(100% - 120px)" }}>
                      <Button variant="contained" component="label">
                        + 추가
                        <input
                          style={{
                            opacity: 0,
                            display: "none",
                          }}
                          ref={ref}
                          accept="image/*"
                          id="needle-button-file"
                          multiple
                          type="file"
                          onChange={uploadClick}
                        />
                      </Button>
                    </Grid>
                  }
                />
              </Grid>
              {items.length === 0 && (
                <EmptyWrap>
                  <InfoIcon />
                  <p>니들 모양 추가 후, 직경 설정이 가능합니다.</p>
                </EmptyWrap>
              )}
              {items.length !== 0 &&
                items.map((value, index) => {
                  return (
                    <ShapeWrap>
                      <Typography>모양 {index + 1}</Typography>
                      <div>
                        <CustomImage src={value.thumbnail} maxSize={64} />
                        {/* <img src={} alt="" /> */}
                        <Slider
                          onChange={(e) => {
                            var newItems = items;
                            newItems[index].width = e.target.value;
                            setItems([...newItems]);
                          }}
                          value={value.width}
                          valueLabelDisplay="on"
                          valueLabelFormat={(value) => <div>{value}mm</div>}
                          step={0.05}
                          min={0.1}
                          max={0.5}
                        />
                        <div>
                          <p>preview</p>
                          <div>
                            <img
                              className={
                                "custom_transform_image_" +
                                String(value.width).split("0.")[1]
                              }
                              style={{
                                maxWidth: "90px",
                                maxHeight: "90px",
                                transform: `scale(${value.width + 0.3})`,
                              }}
                              src={value.thumbnail}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <CancelIcon
                        onClick={() => {
                          var newItems = items;
                          newItems.splice(index, 1);
                          setItems([...newItems]);
                        }}
                      />
                    </ShapeWrap>
                  );
                })}
            </Grid>
          </Grid>
        </MainCard>

        <Grid sx={{ width: "100%", paddingTop: "24px", textAlign: "right" }}>
          <Button
            variant="contained"
            disabled={
              product === "" ||
              explain === "" ||
              link === "" ||
              items.length === 0
            }
            onClick={() => {
              handleDialogOpen(() => {
                const updateArray = [];
                data.status.result.needleShapeData.map((value) => {
                  var status = "";
                  items.map((innerValue) => {
                    if (innerValue.ns_idx === value.ns_idx) {
                      status = "updated";
                      updateArray.push({
                        status: "update",
                        ns_idx: value.ns_idx,
                        ns_size: String(innerValue.width),
                        img_idx: value.img_idx,
                      });
                    }
                    return 0;
                  });

                  if (status === "") {
                    updateArray.push({
                      status: "delete",
                      ns_idx: value.ns_idx,
                    });
                  }

                  return 0;
                });

                items.map((value) => {
                  if (value.ns_idx === undefined) {
                    updateArray.push({
                      status: "new",
                      img_idx: value.img_idx,
                      ns_size: String(value.width),
                    });
                  }
                  return 0;
                });
                mutate({
                  n_name: '',
                  n_productName: product,
                  n_productDescription: explain,
                  n_productUrl: link,
                  needleShapeData: updateArray,
                });
              });
            }}
          >
            저장
          </Button>
        </Grid>
      </CenterBox>
    </>
  );
};

export default NeddleDetail;

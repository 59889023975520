// imports the React Javascript Library
import React from "react";
import { Grid, Typography } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useState } from "react";
import CustomImage from "../customImage";
import loadingSvg from "assets/images/icons/loading.svg";
import { CustomImageLabel, CustomImageWrap, LoadingWrapper } from "./styled";
import { useEffect } from "react";
import { useImageUpload } from "api/imageUpload";
import { useAIUpload } from "api/ai";

const ImageUpload = (init) => {
  const [imageUploaded, setImageUploaded] = useState(
    init === undefined ? 0 : 1
  );
  const [selectedFile, setSelectedFile] = useState(
    init === undefined ? "" : init
  );

  const { data, mutate,isLoading, reset } = useImageUpload();
  const { data: AIData, mutate: AIMutate, isLoading: isAILoading, reset: AIReset } = useAIUpload();

  useEffect(() => {
    if (data !== undefined) {
      if (data.status.code === 200) {
        localStorage.setItem("imgIdx", data.imgdata.imgIdx);
        setImageUploaded(imageUploaded + 1);
        reset();
      } else if (data.status.code === 400) {
        alert(data.status.message);
        setImageUploaded(0);
        setSelectedFile("");
        reset();
      }
    }
  }, [data, reset, imageUploaded]);
  useEffect(() => {
    if (AIData !== undefined) {
      if (AIData.status.code === 200) {
        localStorage.setItem("AIFace", AIData.result.c_face);
        if(AIData.result.eyebrowArray.length !== 0){
          localStorage.setItem(
            "AIEyebrow",
            AIData.result.eyebrowArray[0].c_eyebrow
          );
         }
        localStorage.setItem("imgIdx", AIData.result.imgIdx);
        AIReset();
        setImageUploaded(imageUploaded + 1);
      } else if (AIData.status.code === 400) {
        alert(AIData.status.message);
        setImageUploaded(0);
        setSelectedFile("");
        AIReset();
      }
    }
  }, [AIData, AIReset, imageUploaded]);

  useEffect(() => {
    setImageUploaded(init === undefined ? 0 : 1);
    setSelectedFile(init === undefined ? "" : init);
  }, [init]);

  const uploadClick = (e) => {
    var file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      setSelectedFile([reader.result]);
    };
    setSelectedFile(e.target.files[0]);

    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    console.log(e.target.files[0]);
    
    if (window.location.href.includes("caseManagement")) {
      AIMutate({ formData });
    } else {
      mutate({ formData });
    }
    e.target.value = "";
  };

  const uploadComponent = (
    <>
      {window.location.href.includes("caseManagement") ? (
        <>
          {isAILoading && (
            <LoadingWrapper>
              <img src={loadingSvg} alt="" />
            </LoadingWrapper>
          )}
        </>
      ) : (
        <>
          {isLoading && (
            <LoadingWrapper>
              <img src={loadingSvg} alt="" />
            </LoadingWrapper>
          )}
        </>
      )}
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        position="relative"
        width={"300px"}
        height={"300px"}
      >
        <Grid item>
          {selectedFile !== "" ? (
            <CustomImage maxSize={300} src={selectedFile} />
          ) : (
            <CustomImageWrap>
              <label htmlFor="contained-button-file">이미지 업로드</label>
            </CustomImageWrap>
          )}
          <input
            style={{
              opacity: 0,
              display: "none",
            }}
            accept="image/*"
            // accept=".jpeg"
            id="contained-button-file"
            multiple
            type="file"
            onChange={uploadClick}
          />
          {selectedFile !== "" && (
            <CustomImageLabel htmlFor="contained-button-file">
              <CameraAltIcon />
            </CustomImageLabel>
          )}
        </Grid>
        {window.location.href.includes("userSign") && (
          <Typography
            sx={{
              position: "absolute",
              bottom: "-48px",
            }}
          >
            * 프로필 이미지 미등록 시,
            <br />
            기본 이미지로 설정됩니다
          </Typography>
        )}
      </Grid>
    </>
  );

  return { imageUploaded, uploadComponent };
};

export default ImageUpload;

import { Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import CenterBox from "components/common/centerBox";
import React from "react";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import DataLabel from "components/common/dataLabel";
import { useState } from "react";
import { useEffect } from "react";
import { dateToStringFormat, getToday } from "utils";
import CustomDialog from "components/common/customDialog";
import { useGetNoticeByIdx, useRegisterNotice, useUpdateNotice } from "api/noticeManagement";
import { useSelector } from "react-redux";

const NoticeDetail = () => {
  const [selectedText, setSelectedText] = useState("info");
  const [date, setDate] = useState(getToday());
  const [name, setName] = useState("");
  const [adName, setAdName] = useState("");
  const [detail, setDetail] = useState("");
  const [isSign, setIsSign] = useState(true);
  const loginUserData = useSelector((state) => state.loginUserData);
  const { dialogComponent, handleDialogOpen } = CustomDialog(
    isSign ? "공지 등록" : "공지사항 수정",
    isSign
      ? "해당 내용으로 등록하시겠습니까?"
      : "수정된 내용으로 저장하시겠습니까?"
  );
  const { data } = useGetNoticeByIdx();
  const { data:updateResponse,mutate:updateMutate}=useUpdateNotice();
  const { data: registerResponse, mutate: registerMutate } = useRegisterNotice();

  useEffect(() => {
    let response = undefined;
    let mySign = false;
    if (registerResponse !== undefined) {
      response = registerResponse;
      mySign = true;
    } else if (updateResponse !== undefined) {
      response = updateResponse;
      mySign = false;
    }
    if (response !== undefined){
      if (response.status.code === 200) {
        localStorage.setItem(
          "popupText",
          mySign ? "공지사항이 등록되었습니다." : "공지사항이 수정되었습니다."
        );
        window.location.replace("/serviceManagement/noticeManagement");
      } else if (
        response.status.code === 400 &&
        response.status.message.includes("jwt")
      ) {
        alert("로그인 권한이 만료되었습니다. 다시 로그인해주세요.");
        window.location.replace("/");
      }
    }
  }, [updateResponse, registerResponse]);
  

  useEffect(() => {
    if (data !== undefined) {
      if (data.status.code === 200) {
        setIsSign(false);
        setDate(
          dateToStringFormat(
            new Date(data.status.result.no_regDate.split("T")[0])
          )
        );
        setAdName(data.status.result.ad_name);
        setName(data.status.result.no_title);
        setDetail(data.status.result.no_contents);
        setSelectedText(data.status.result.no_type);
      } else {
        setDate(dateToStringFormat(new Date()));
        setAdName(loginUserData.ad_name);
        setIsSign(true);
      }
    }
  }, [data, loginUserData]);

  return (
    <CenterBox>
      {dialogComponent}
      <MainCard>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={3.5}>
                <DataLabel
                  title={"분류"}
                  children={
                    <Select
                      sx={{ width: "calc(100% - 70px)" }}
                      id="demo-simple-select"
                      value={selectedText}
                      onChange={(e) => {
                        setSelectedText(e.target.value);
                      }}
                    >
                      <MenuItem value={"info"}>안내</MenuItem>
                      <MenuItem value={"update"}>업데이트</MenuItem>
                      <MenuItem value={"emergency"}>긴급</MenuItem>
                      <MenuItem value={"event"}>이벤트</MenuItem>
                    </Select>
                  }
                />
              </Grid>
              <Grid item xs={3.5}>
                <DataLabel
                  title={"작성일"}
                  children={
                    <TextField
                      inputProps={{ readOnly: true }}
                      value={date}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                      sx={{ width: "calc(100% - 70px)" }}
                    ></TextField>
                  }
                />
              </Grid>
              <Grid item xs={3.5}>
                <DataLabel
                  title={"작성자"}
                  children={
                    <TextField
                      inputProps={{ readOnly: true }}
                      value={adName}
                      sx={{ width: "calc(100% - 70px)" }}
                    ></TextField>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "space-between",
                "& >div": { width: "100%" },
              }}
            >
              <DataLabel
                title={"제목"}
                children={
                  <TextField
                    placeholder="제목을 입력해주세요."
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    sx={{ width: "calc(100% - 70px)" }}
                  ></TextField>
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "space-between",
                "& >div": { width: "100%" },
              }}
            >
              <DataLabel
                title={"내용"}
                children={
                  <TextField
                    placeholder="내용을 입력해주세요."
                    multiline
                    minRows={6}
                    maxRows={6}
                    value={detail}
                    onChange={(e) => {
                      setDetail(e.target.value);
                    }}
                    sx={{ width: "calc(100% - 70px)" }}
                  ></TextField>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
      <Grid sx={{ width: "100%", paddingTop: "24px", textAlign: "right" }}>
        <Button
          variant={"contained"}
          disabled={name === "" || detail === ""}
          onClick={() => {
            handleDialogOpen(() => {
              if (isSign) {
                registerMutate({
                  no_type:selectedText,
                  no_title:name,
                  no_contents:detail,
                });
              } else {
                updateMutate({
                  no_type: selectedText,
                  no_title: name,
                  no_contents: detail,
                });
              }
            });
          }}
        >
          {isSign ? "등록" : "저장"}
        </Button>
      </Grid>
    </CenterBox>
  );
};

export default NoticeDetail;

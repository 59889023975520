import Search from "components/common/search";
import UseCaseManagement from "hooks/caseManagement/useCaseManagement";
import React from "react";
import { Box } from "@mui/system";
import CustomTable from "components/common/tables/customImageTable";
import MainCard from "ui-component/cards/MainCard";
import { Button } from "@mui/material";
import CustomDialog from "components/common/customRemoveDialog";
import CustomSnackbar from "components/common/customSnackbar";
import { useEffect } from "react";

const CaseManagement = () => {
  const { snackbarComponent, handleSnackOpen } = CustomSnackbar();
  const { dialogComponent, handleDialogOpen } = CustomDialog(
    "케이스 삭제",
    "해당 실습 관련 모든 데이터가 삭제됩니다.\n선택한 항목을 삭제하시겠습니까?"
  );
  const {
    searchAction,
    dropdownList,
    DateTextList,
    tableColumn,
    tableData,
    selectedId,
    tableCheckAction,
    removeClickAction,
    data,
  } = UseCaseManagement(handleSnackOpen, handleDialogOpen);

  useEffect(() => {
    const popupText = localStorage.getItem("popupText");
    if (popupText != null) {
      localStorage.removeItem("popupText");
      handleSnackOpen(popupText);
    }
  }, [handleSnackOpen]);

  return (
    <Box>
      {dialogComponent}
      {snackbarComponent}
      <Search
        dropdownList={dropdownList}
        searchAction={searchAction}
        DateTextList={DateTextList}
      />

      <MainCard sx={{ marginTop: "24px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "24px",
          }}
        >
          <Button variant="contained" color="error" onClick={removeClickAction}>
            삭제
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              window.location.href = "/caseManagement/caseSign";
            }}
          >
            케이스 등록
          </Button>
        </Box>
        {data !== undefined && (
          <CustomTable
            tableCheckAction={tableCheckAction}
            data={tableData}
            column={tableColumn}
            selectedId={selectedId}
          />
        )}
      </MainCard>
    </Box>
  );
};

export default CaseManagement;

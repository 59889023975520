import { useState, useEffect, useRef } from "react";
import TestHeader from "../Components/Header";
import { Button, Typography } from "@mui/material";
import axios from "axios";
import grayImage from "assets/images/test/gray_image.png";
import Loading from "../Components/Loading";

const TestDiffrenceAnalysis = () => {
  // useState
  const [selectedOriginalImage, setSelectedOriginalImage] = useState(null);
  const [selectedPracticeImage, setSelectedPracticeImage] = useState(null);
  const [score, setScore] = useState(0);
  const [loading, setLoading] = useState(false);
  
  const originFileRef = useRef(null);
  const practiceFileRef = useRef(null);

  const [originFile, setOriginFile] = useState(null);
  const [practiceFile, setPracticeFile] = useState(null);
  
  
  
  useEffect(() => {
    console.log("begin TestDiffrenceAnalysis");
  }, [])

  useEffect(() => {
    if (originFile === null || practiceFile === null) {
      return;
    }
    handleSubmit();
    // eslint-disable-next-line
  }, [originFile, practiceFile])
  
  const handleOriginImageChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedOriginalImage(reader.result);
        setOriginFile(file)
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePracticeImageChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedPracticeImage(reader.result);
        setPracticeFile(file);
        
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = () => {
    console.log("begin handleSubmit");
    setLoading(true);
    const formData = new FormData();
    formData.append('originalImage', originFile)
    formData.append('updateImage', practiceFile)
    axios({
      method: "post",
      url: "http://3.39.63.82:5000/score",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    }).then( result => {
      console.log(result);
      const score = result.data.score
      setLoading(false);
      setScore(score)
    }).catch(error => {
      console.error(error)
      setLoading(false);
    });
  }

  return (
    <>
      <TestHeader />
      <div style={{
        marginTop: '50px', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        gap:'30px'}}>
        <div>
          <Typography variant="h4" sx={{mt: 2, fontFamily: 'monospace', fontSize:'20px'}}>
            학습자 시술물과 최적 시술 사례간 유사도 평가 및 차이점 분석 알고리즘
          </Typography>
        </div>
        <div style={{display: 'flex', justifyContent: 'center', gap: '30px'}}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <img 
              src={selectedOriginalImage || grayImage} 
              alt="Selected" 
              style={{ 
                width: '300px', 
                height: '400px', 
                backgroundColor: '#aaa', 
                borderRadius: '20px' }} />
            <Typography variant="h4" sx={{mt: 2, fontFamily: 'monospace', fontSize:'20px'}}>
              원본 이미지
            </Typography>
            <div>
              <Button variant="contained" onClick={() => {
                originFileRef.current.click();
              }}>
                파일 선택
              </Button>
              <input ref={originFileRef} style={{display: 'none'}} type="file" onChange={handleOriginImageChange}/>
            </div>
          </div>
         
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <img src={selectedPracticeImage || grayImage} alt="Selected" style={{ width: '300px', height: '400px', backgroundColor: '#aaa', borderRadius: '20px' }} />
            <Typography variant="h4" sx={{mt: 2, fontFamily: 'monospace', fontSize:'20px'}}>결과 이미지</Typography>
            <div>
              <Button variant="contained" onClick={() => {
                practiceFileRef.current.click();
              }}>
                파일 선택
              </Button>
              <input ref={practiceFileRef} style={{display: 'none'}} type="file" onChange={handlePracticeImageChange}/>
            </div>
          </div>
        </div>

        <div>
          <Typography variant="h4" sx={{mt: 2, fontFamily: 'monospace', fontSize:'20px'}}>
            점수 {score}점
          </Typography>
        </div>
      </div>
      { loading ? <Loading /> : null}
    </>
      
  )
}
export default TestDiffrenceAnalysis; 

import { Button, Grid, TextField } from "@mui/material";
import CenterBox from "components/common/centerBox";
import React from "react";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import DataLabel from "components/common/dataLabel";
import { useState } from "react";
import { useEffect } from "react";
import CustomImage from "components/common/customImage";
import { useGetTrainingByIdx } from "api/studentManagement";
import { dateToStringFormat } from "utils";

const ExpertDetail = () => {
  const [expertData, setExpertData] = useState();
  const [thumbnail, setThumbnail] = useState("");
  const [face, setFace] = useState("");
  const [eyebrow, setEyebrow] = useState("");
  const [name, setName] = useState("");
  const [time, setTime] = useState("");
  const [expert, setExpert] = useState("");
  const [date, setDate] = useState("");

  const { data } = useGetTrainingByIdx();

  useEffect(() => {
    if (data !== undefined) {
      if (data.status.code === 200) {
        setExpertData(data.status.result);
      }
    }
  }, [data]);

  useEffect(() => {
    if (expertData !== undefined) {
      setThumbnail(
        process.env.REACT_APP_IMAGE_BASE_URL + expertData.training_img_name
      );
      setName(expertData.c_name);
      setFace(expertData.c_face);
      setExpert(expertData.m_name);
      setTime(expertData.t_time);
      setDate(dateToStringFormat(new Date(expertData.t_regDate.split("T")[0])));
      setEyebrow(expertData.c_eyebrow);
    }
  }, [expertData]);

  return (
    <CenterBox>
      <MainCard>
        <Grid container spacing={gridSpacing}>
          <Grid
            item
            xs={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CustomImage maxSize={250} src={thumbnail} />
          </Grid>
          <Grid item xs={8}>
            <Grid item>
              <DataLabel
                title={"케이스명"}
                children={
                  <TextField
                    disabled
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    sx={{ width: "calc(100% - 120px)" }}
                  ></TextField>
                }
              />
            </Grid>
            <Grid
              item
              sx={{
                paddingTop: "24px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={5}>
                <DataLabel
                  title={"얼굴형"}
                  children={
                    <TextField
                      disabled
                      value={face}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <DataLabel
                  title={"눈썹 유형"}
                  children={
                    <TextField
                      disabled
                      value={eyebrow}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                paddingTop: "24px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={5}>
                <DataLabel
                  title={"등록일"}
                  children={
                    <TextField
                      disabled
                      value={date}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <DataLabel
                  title={"전문가"}
                  children={
                    <TextField
                      disabled
                      value={expert}
                      onChange={(e) => {
                        setExpert(e.target.value);
                      }}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                paddingTop: "24px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={5}>
                <DataLabel
                  title={"소요시간"}
                  children={
                    <TextField
                      disabled
                      value={time}
                      onChange={(e) => {
                        time(e.target.value);
                      }}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <DataLabel
                  title={"점수"}
                  children={
                    <TextField
                      disabled
                      value={"-"}
                      sx={{ width: "calc(100% - 120px)" }}
                    ></TextField>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
      <Grid sx={{ width: "100%", paddingTop: "24px", textAlign: "right" }}>
        <Button
          variant={"contained"}
          onClick={() => {
            window.history.back();
          }}
        >
          확인
        </Button>
      </Grid>
    </CenterBox>
  );
};

export default ExpertDetail;

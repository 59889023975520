import { useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// routing
import Routes from "routes";

// defaultTheme
import themes from "themes";

// project imports
import NavigationScroll from "layout/NavigationScroll";
import { useLocation } from "react-router";

// ==============================|| APP ||============================== //

const App = () => {
  const location = useLocation();
  const customization = useSelector((state) => state.customization);
  // const loginUserData = useSelector((state) => state.loginUserData);
  // console.log(loginUserData);
  console.log(location.pathname)
  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <NavigationScroll>
            <Routes />
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default App;

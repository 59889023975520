import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import grayImage from "assets/images/test/gray_image.png";

const ResultTable = (props) => {
  const {title, items} = props;
  return (
    <div style={{ width: '152px'}}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
            <TableCell
              key={title}
            >
              {title}
            </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items
          .map((item, index) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={item.imgIdx}>
                <TableCell>
                  <img 
                    src={item.imgPath || grayImage} 
                    alt="Selected" 
                    style={{ 
                      width: '120px', 
                      height: '160px', 
                      backgroundColor: '#aaa', 
                      borderRadius: '20px' }} />
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  </div>
  )
}

export default ResultTable;

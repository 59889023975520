import { Button } from "@mui/material";
import { useState } from "react";
import CustomImage from "components/common/customImage";
import { Box } from "@mui/system";
import { useDeleteCase, useGetCaseList } from "api/caseManagement";
import { dateToStringFormat, leftPad } from "utils";
import { useEffect } from "react";

const UseCaseManagement = (handleSnackOpen, handleDialogOpen) => {
  const [searchValue, setSearchValue] = useState({
    startDate: "",
    endDate: "",
    selectedText: "",
    searchText: "",
  });
  const searchAction = (startDate, endDate, selectedText, searchText) => {
    setSearchValue({
      startDate:
        startDate === undefined || startDate === null
          ? ""
          : startDate.$y +
            "-" +
            leftPad(startDate.$M + 1) +
            "-" +
            leftPad(startDate.$D),
      endDate:
        endDate == null
          ? ""
          : endDate.$y +
            "-" +
            leftPad(endDate.$M + 1) +
            "-" +
            leftPad(endDate.$D),
      selectedText,
      searchText,
    });
  };
  const dropdownList = [
    { value: "c_name", text: "케이스명" },
    { value: "c_face", text: "얼굴형" },
    { value: "c_eyebrow", text: "눈썹 유형" },
  ];
  const DateTextList = ["등록일", "시작일", "종료일"];
  const tableColumn = [
    { field: "id", headerName: "No", width: 100 },
    {
      field: "img_name",
      headerName: "썸네일",
      width: 180,
      sortable: false,
      renderCell: (params) => (
        <Box
          sx={{
            width: 160,
            height: 160,
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomImage
            maxSize={140}
            src={process.env.REACT_APP_IMAGE_BASE_URL + params.value}
          />
        </Box>
      ),
    },
    { field: "c_name", headerName: "케이스명", width: 250 },
    { field: "c_face", headerName: "얼굴형", width: 150 },
    { field: "c_eyebrow", headerName: "눈썹 유형", width: 150 },
    { field: "expertTrainingCnt", headerName: "최적 사례 개수", width: 150 },
    { field: "totTrainingCnt", headerName: "실습 참여 건수", width: 150 },
    { field: "c_regDate", headerName: "등록일", width: 150 },
    {
      field: "manage",
      headerName: "관리",
      width: 100,
      sortable: false,
      renderCell: (param) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              window.location.href =
                "/caseManagement/caseDetail?c_idx=" + param.row.c_idx;
            }}
          >
            상세
          </Button>
        );
      },
    },
  ];
  const [selectedId, setSelectedId] = useState([]);
  const [tableData, setTableData] = useState([]);
  const { data, refetch } = useGetCaseList(searchValue);
  const { data: deleteData, mutate, reset } = useDeleteCase();
  useEffect(() => {
    if (data !== undefined) {
      var tableData = data.result;

      tableData.map((value, index) => {
        tableData[index].id = value.c_idx;
        tableData[index].id = index + 1;
        var date = new Date(value.c_regDate.split("T")[0]);
        tableData[index].c_regDate = dateToStringFormat(date, "-");
        return 0;
      });
      setTableData(tableData);
    }
  }, [data]);

  useEffect(() => {
    if (deleteData !== undefined) {
      if (deleteData.status.code === 200) {
        refetch();
        reset();
      }
    }
  }, [deleteData, refetch, reset]);

  useEffect(() => {
    refetch();
  }, [searchValue, refetch]);

  const removeAction = () => {
    var list = [];
    selectedId.map((value) => {
      list.push(tableData[value - 1].c_idx);
      return 0;
    });
    mutate({ list });
    setSelectedId([]);
    handleSnackOpen("삭제 완료되었습니다.");
  };

  const tableCheckAction = (ids) => {
    setSelectedId(ids);
  };

  const removeClickAction = () => {
    if (selectedId.length === 0) {
      handleSnackOpen("선택한 항목이 없습니다.");
    } else {
      handleDialogOpen(removeAction);
    }
  };

  return {
    searchAction,
    selectedId,
    dropdownList,
    DateTextList,
    tableColumn,
    tableData,
    tableCheckAction,
    removeClickAction,
    data,
  };
};

export default UseCaseManagement;

import {
  Button,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CenterBox from "components/common/centerBox";
import useCaseDetail from "hooks/caseManagement/useCaseDetail";
import React from "react";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import DataLabel from "components/common/dataLabel";
import CustomDialog from "components/common/customDialog";
import CustomSnackbar from "components/common/customSnackbar";
import { useState } from "react";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { dateToStringFormat, eyebrowList, faceList } from "utils";
import { useUpdateCase } from "api/caseManagement";

const CaseDetail = () => {
  const { dialogComponent, handleDialogOpen } = CustomDialog(
    "케이스 수정",
    "수정된 내용으로 저장하시겠습니까?"
  );
  const { snackbarComponent } = CustomSnackbar();
  const [nameCheck, setNameCheck] = useState(false);
  const [faceCheck, setFaceCheck] = useState(false);
  
  const [eyebrowCheck, setEyebrowCheck] = useState(false);
  const {
    caseData,
    date,
    name,
    setName,
    face,
    setFace,
    manager,
    setManager,
    fit,
    setFit,
    count,
    setCount,
    eyebrow,
    setEyebrow,
    caseCheck,
    setCaseCheck,
  } = useCaseDetail();
  const { data, mutate } = useUpdateCase();
  useEffect(() => {
    if (data !== undefined) {
      if (data.status.code === 200) {
        localStorage.setItem("popupText", "실습 케이스 정보가 수정되었습니다.");
        window.location.replace("/caseManagement");
      } else if (
        data.status.code === 400 &&
        data.status.message.includes("jwt")
      ) {
        alert("로그인 권한이 만료되었습니다. 다시 로그인해주세요.");
        window.location.replace("/");
      }
    }
  }, [data]);

  useEffect(() => {
    if (caseCheck) {
      setFace("연습용");
    } else {
      if (caseData !== undefined) setFace(caseData.c_face);
    }
  }, [caseCheck, caseData, setFace]);

  return (
    <>
      {dialogComponent}
      {snackbarComponent}
      {caseData && (
        <CenterBox>
          <MainCard>
            <Grid container spacing={gridSpacing}>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* <ImageUploader /> */}
                <img
                  src={process.env.REACT_APP_IMAGE_BASE_URL + caseData.img_name}
                  alt=""
                  style={{
                    width: "auto",
                    height: "300px",
                    maxWidth: "100%",
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <Grid item>
                  <DataLabel
                    title={"케이스명"}
                    children={
                      <TextField
                        value={name}
                        error={nameCheck}
                        placeholder="케이스명을 입력해주세요."
                        helperText={nameCheck && "* 케이스명을 확인해주세요."}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        sx={{ width: "calc(100% - 120px)" }}
                      ></TextField>
                    }
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    paddingTop: "24px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={5}>
                    <DataLabel
                      title={"얼굴형"}
                      children={
                        <Select
                          value={face}
                          error={faceCheck}
                          disabled={caseCheck}
                          helperText={faceCheck && "* 얼굴형을 입력해주세요."}
                          onChange={(e) => {
                            setFace(e.target.value);
                          }}
                          sx={{ width: "calc(100% - 120px)" }}
                        >
                          {!faceList.includes(face) && (
                            <MenuItem value={face}>{face}</MenuItem>
                          )}
                          {faceList.map((value) => {
                            return <MenuItem value={value}>{value}</MenuItem>;
                          })}
                        </Select>
                      }
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <DataLabel
                      title={"눈썹 유형"}
                      children={
                        <Select
                          value={eyebrow}
                          error={eyebrowCheck}
                          helperText={
                            eyebrowCheck && "* 눈썹 유형을 입력해주세요."
                          }
                          defaultValue={eyebrow}
                          onChange={(e) => {
                            setEyebrow(e.target.value);
                          }}
                          sx={{ width: "calc(100% - 120px)" }}
                        >
                          {eyebrowList.map((value) => {
                            return <MenuItem value={value}>{value}</MenuItem>;
                          })}
                        </Select>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{
                    paddingTop: "24px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={5}>
                    <DataLabel
                      title={"등록일"}
                      children={
                        <TextField
                          disabled
                          value={dateToStringFormat(
                            new Date(date.split("T")[0])
                          )}
                          sx={{ width: "calc(100% - 120px)" }}
                        ></TextField>
                      }
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <DataLabel
                      title={"등록 관리자"}
                      children={
                        <TextField
                          value={manager}
                          disabled
                          onChange={(e) => {
                            setManager(e.target.value);
                          }}
                          sx={{ width: "calc(100% - 120px)" }}
                        ></TextField>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{
                    paddingTop: "24px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={5}>
                    <DataLabel
                      title={"최적 사례 개수"}
                      children={
                        <TextField
                          value={fit}
                          disabled
                          onChange={(e) => {
                            setFit(e.target.value);
                          }}
                          sx={{ width: "calc(100% - 120px)" }}
                        ></TextField>
                      }
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <DataLabel
                      title={"실습 참여 건수"}
                      children={
                        <TextField
                          value={count}
                          disabled
                          onChange={(e) => {
                            setCount(e.target.value);
                          }}
                          sx={{ width: "calc(100% - 120px)" }}
                        ></TextField>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{
                    paddingTop: "24px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={5}>
                    <DataLabel
                      title={"연습용 케이스 설정"}
                      children={
                        <Box sx={{ width: "calc(100% - 120px)" }}>
                          <Checkbox
                            value={caseCheck}
                            checked={caseCheck}
                            onChange={(event) =>
                              setCaseCheck(event.target.checked)
                            }
                            name="checked"
                            color="primary"
                          />
                        </Box>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MainCard>

          <Grid sx={{ width: "100%", paddingTop: "24px", textAlign: "right" }}>
            <Button
              variant="contained"
              onClick={() => {
                let check = false;
                if (name === "") {
                  setNameCheck(true);
                  check = true;
                } else {
                  setNameCheck(false);
                }
                if (face === "") {
                  setFaceCheck(true);
                  check = true;
                } else {
                  setFaceCheck(false);
                }
                if (eyebrow === "") {
                  setEyebrowCheck(true);
                  check = true;
                } else {
                  setEyebrowCheck(false);
                }
                if (!check) {
                  handleDialogOpen(() => {
                    // window.history.back();
                    mutate({
                      img_idx: caseData.img_idx,
                      c_name: name,
                      c_face: face,
                      c_eyebrow: eyebrow,
                      c_practice: caseCheck,
                    });
                  });
                }
              }}
            >
              저장
            </Button>
          </Grid>
        </CenterBox>
      )}
    </>
  );
};

export default CaseDetail;

import { Button, Grid, TextField } from "@mui/material";
import CenterBox from "components/common/centerBox";
import React from "react";
import MainCard from "ui-component/cards/MainCard";
import DataLabel from "components/common/dataLabel";
import { useState } from "react";
import { useEffect } from "react";
import CustomDialog from "components/common/customDialog";
import {
  checkEmail,
  checkPassword,
  checkPhone,
  replaceId,
  replaceKorean,
  replaceNumber,
} from "utils/replaceText";
import { useGetAdminByIdx, useRegisterAdmin, useUpdateAdmin } from "api/adminManagement";

const AdminDetail = () => {
  const [name, setName] = useState("");
  const [nameCheck, setNameCheck] = useState("");
  const [userid, setUserid] = useState("");
  const [useridCheck, setUseridCheck] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCheck, setPhoneCheck] = useState("");
  const [email, setEmail] = useState("");
  const [emailCheck, setEmailCheck] = useState("");
  const [isSign, setIsSign] = useState(false);
  const { dialogComponent, handleDialogOpen } = CustomDialog(
    isSign ? "관리자 등록" : "관리자 정보 수정",
    isSign
      ? "해당 내용으로 등록하시겠습니까?"
      : "수정된 내용으로 저장하시겠습니까?"
  );

  const { data } = useGetAdminByIdx();
  const { data: updateResponse, mutate: registerMutate,reset:registerReset } = useRegisterAdmin();
  const {
    data: registerResponse,
    mutate: updateMutate,
    reset: updateReset,
  } = useUpdateAdmin();

  useEffect(() => {
    let response = undefined;
    let mySign = false;
    if (registerResponse !== undefined) {
      response = registerResponse;
      mySign = true;
    } else if (updateResponse !== undefined) {
      response = updateResponse;
      mySign = false;
    }
    if (response !== undefined) {
      if (response.status.code === 200) {
        localStorage.setItem(
          "popupText",
          mySign ? "수정 완료되었습니다." : "등록 완료되었습니다."
        );
        window.location.replace("/adminManagement");
      } else if (response.status.code === 400) { 
        alert('이미 가입된 정보가 있습니다.');
      }
      registerReset();
      updateReset();
    }
  }, [updateResponse, registerResponse,registerReset,updateReset]);

  useEffect(() => {
    if (data !== undefined) {
      if (data.status.code === 200) {
        setName(data.status.result.ad_name);
        setUserid(data.status.result.ad_username);
        setPassword(data.status.result.ad_passwd);
        setPhone(data.status.result.ad_phone);
        setEmail(data.status.result.ad_email);
        setIsSign(false);
      } else {
        setIsSign(true);
      }
    }
  }, [data]);

  return (
    <CenterBox>
      {dialogComponent}
      <MainCard>
        <Grid item xs={12}>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "space-between",
              "& >div": { width: "100%" },
            }}
          >
            <DataLabel
              title={"이름"}
              children={
                <TextField
                  value={name}
                  error={nameCheck}
                  helperText={nameCheck && "* 이름을 확인해주세요."}
                  placeholder="이름을 입력해주세요."
                  onChange={(e) => {
                    setName(replaceKorean(e.target.value));
                  }}
                  sx={{ width: "calc(100% - 70px)" }}
                ></TextField>
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              paddingTop: "24px",
              justifyContent: "space-between",
              "& >div": { width: "100%" },
            }}
          >
            <DataLabel
              title={"아이디"}
              children={
                <TextField
                  value={userid}
                  placeholder="아이디를 입력해주세요."
                  error={useridCheck}
                  helperText={useridCheck && "* 아이디를 확인해주세요."}
                  onChange={(e) => {
                    setUserid(replaceId(e.target.value));
                  }}
                  sx={{ width: "calc(100% - 70px)" }}
                ></TextField>
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              paddingTop: "24px",
              justifyContent: "space-between",
              "& >div": { width: "100%" },
            }}
          >
            <DataLabel
              title={"비밀번호"}
              children={
                <TextField
                  value={password}
                  error={passwordCheck}
                  helperText={passwordCheck && "* 비밀번호를 확인해주세요."}
                  placeholder="8~12자, 영문/숫자/특수문자 모두 포함"
                  onChange={(e) => {
                    setPassword(replaceId(e.target.value));
                  }}
                  sx={{ width: "calc(100% - 70px)" }}
                ></TextField>
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              paddingTop: "24px",
              justifyContent: "space-between",
              "& >div": { width: "100%" },
            }}
          >
            <DataLabel
              title={"휴대전화"}
              children={
                <TextField
                  value={phone}
                  error={phoneCheck}
                  helperText={phoneCheck && "* 휴대폰 번호를 확인해주세요."}
                  placeholder="휴대전화 번호를 입력해주세요."
                  onChange={(e) => {
                    setPhone(replaceNumber(e.target.value.slice(0, 11)));
                  }}
                  sx={{ width: "calc(100% - 70px)" }}
                ></TextField>
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              paddingTop: "24px",
              justifyContent: "space-between",
              "& >div": { width: "100%" },
            }}
          >
            <DataLabel
              title={"이메일"}
              children={
                <TextField
                  value={email}
                  error={emailCheck}
                  helperText={emailCheck && "* 이메일을 확인해주세요."}
                  placeholder="이메일을 입력해주세요."
                  onChange={(e) => {
                    setEmail(replaceId(e.target.value));
                  }}
                  sx={{ width: "calc(100% - 70px)" }}
                ></TextField>
              }
            />
          </Grid>
        </Grid>
      </MainCard>
      <Grid sx={{ width: "100%", paddingTop: "24px", textAlign: "right" }}>
        <Button
          variant={"contained"}
          onClick={() => {
            let check = false;
            if (name === "") {
              setNameCheck(true);
              check = true;
            } else {
              setNameCheck(false);
            }
            if (userid === "") {
              setUseridCheck(true);
              check = true;
            } else {
              setUseridCheck(false);
            }
            if (email === "" || checkEmail(email)) {
              setEmailCheck(true);
              check = true;
            } else {
              setEmailCheck(false);
            }
            if (password === "" || checkPassword(password)) {
              setPasswordCheck(true);
              check = true;
            } else {
              setPasswordCheck(false);
            }
            if (phone === "" || checkPhone(phone)) {
              setPhoneCheck(true);
              check = true;
            } else {
              setPhoneCheck(false);
            }
            if (!check) {
              handleDialogOpen(() => {
                if (isSign) {
                  registerMutate({
                    ad_name: name,
                    ad_username: userid,
                    ad_passwd: password,
                    ad_phone: phone,
                    ad_email: email,
                  });
                }
                else{
                  updateMutate({
                    ad_name: name,
                    ad_username: userid,
                    ad_passwd: password,
                    ad_phone: phone,
                    ad_email: email,
                  });
                }
              });
            }
          }}
        >
          {isSign ? "등록" : "수정"}
        </Button>
      </Grid>
    </CenterBox>
  );
};

export default AdminDetail;

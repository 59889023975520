export const replaceKorean = (text) => {
  var regex = /[^ㄱ-힣]/gi;
  return text.replace(regex, "");
};
export const replaceNumber = (text) => {
  var regex = /[^0-9]/gi;
  return text.replace(regex, "");
};

export const replaceId = (text) => {
  var regex = /[^a-zA-Z-0-9\\d`~!@#$%^&*()-_=+]/gi;
  return text.replace(regex, "");
};
export const replacePassword = (text) => {
  var regex = /[^a-zA-Z\\d`~!@#$%^&*()-_=+]/gi;
  return text.replace(regex, "");
};

export const replaceStrictType = (text) => {
  var regex = /[^a-zA-Zㄱ-힣]/gi;
  return text.replace(regex, "");
};
export const checkPassword = (text) => {
  var regex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,12}$/;
  return !regex.test(text);
};
export const checkEmail = (text) => {
  var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return !regex.test(text);
};

export const checkKorean = (text) => {
  var regex = /[^ㄱ-힣]/gi;
  return regex.test(text);
};

export const checkPhone = (text) => {
  var regex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  return !regex.test(text);
};

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router';

const pages = ['시술 이미지 데이터랜드마크 처리 정확도', '얼굴형 이미지 유사도 판별 알고리즘 정확도', '학습자 시술물과 최적 시술 사례간 유사도 평가 및 차이점 분석 알고리즘', 'AI 시술 시뮬레이션 구현 정확도'];
const menus = ['/test/landmarkaccuracy', '/test/similarityaccuracy', '/test/diffrenceanalysis', '/test/aisimulationaccuracy'];

function TestHeader() {
  const navigate = useNavigate();

  return (
    <AppBar position="static" style={{minWidth: '1440px'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/test"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              fontSize: '20px'
            }}
          >
            블라썸클라우드
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Divider orientation="vertical" variant="middle" flexItem />
            {pages.map((page, index) => (
              <React.Fragment key={page}>
                <Button
                  onClick={() => {
                    console.log(menus[index]);
                    navigate(menus[index])
                  }}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page}
                </Button>
                <Divider orientation="vertical" variant="middle" flexItem />
              </React.Fragment>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default TestHeader;
import { Button } from "@mui/material";
import { useState } from "react";
import { dateToStringFormat, leftPad } from "utils";
import { useDeleteNeddle, useGetNeddleList } from "api/neddleManagement";
import { useEffect } from "react";

const UseNeddleManagement = (handleSnackOpen, handleDialogOpen) => {
  const [searchValue, setSearchValue] = useState({
    startDate: "",
    endDate: "",
    selectedText: "n_productName",
    searchText: "",
  });
  const searchAction = (startDate, endDate, selectedText, searchText) => {
    setSearchValue({
      startDate:
        startDate === undefined || startDate === null
          ? ""
          : startDate.$y +
            "-" +
            leftPad(startDate.$M + 1) +
            "-" +
            leftPad(startDate.$D),
      endDate:
        endDate == null
          ? ""
          : endDate.$y +
            "-" +
            leftPad(endDate.$M + 1) +
            "-" +
            leftPad(endDate.$D),
      selectedText:'n_productName',
      searchText,
    });
  };
  const { data: memberList, refetch } = useGetNeddleList(searchValue);
  const { data: quitResponse, mutate } = useDeleteNeddle();
  useEffect(() => {
    refetch();
  }, [searchValue, refetch]);

  useEffect(() => {
    if (quitResponse !== undefined) {
      if (quitResponse.status.code === 200) {
        refetch();
      }
    }
  }, [quitResponse, refetch]);
  const dropdownList = [
    { value: "n_name", text: "이름" },
    { value: "n_productName", text: "제품명" },
  ];
  const DateTextList = ["등록일", "시작일", "종료일"];
  const tableColumn = [
    { field: "id", headerName: "No", width: 100 },
    {
      field: "img_name",
      headerName: "썸네일",
      width: 180,
      sortable: false,
      renderCell: (params) => (
        <img
          src={process.env.REACT_APP_IMAGE_BASE_URL + params.value}
          style={{
            width: "90px",
            height: "90px",
            padding: "10px",
            borderRadius: "50%",
          }}
          alt=""
        />
      ),
    },
    { field: "n_productName", headerName: "제품명", width: 150 },
    { field: "needleCount", headerName: "니들 모양 개수", width: 150 },
    { field: "n_regDate", headerName: "등록일", width: 150 },
    {
      field: "manage",
      headerName: "관리",
      width: 100,
      sortable: false,
      renderCell: (param) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              localStorage.setItem(
                "neddleDetailInfo",
                JSON.stringify(param.row)
              );
              window.location.href =
                "/neddleManagement/neddleDetail?n_idx=" + param.row.n_idx;
            }}
          >
            상세
          </Button>
        );
      },
    },
  ];
  const [selectedId, setSelectedId] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (memberList !== undefined) {
      var tableData = memberList.result;

      tableData.map((value, index) => {
        tableData[index].id = index + 1;
        var date = new Date(value.n_regDate.split("T")[0]);
        tableData[index].n_regDate = dateToStringFormat(date, "-");
        return 0;
      });
      setTableData(tableData);
    }
  }, [memberList]);

  const removeAction = () => {
    var list = [];
    selectedId.map((value) => {
      list.push(tableData[value - 1].n_idx);
      return 0;
    });
    mutate({ list: list });
    setSelectedId([]);
    handleSnackOpen("삭제 완료되었습니다.");
  };
  const tableCheckAction = (ids) => {
    setSelectedId(ids);
  };

  const removeClickAction = () => {
    if (selectedId.length === 0) {
      handleSnackOpen("선택한 항목이 없습니다.");
    } else {
      handleDialogOpen(removeAction);
    }
  };

  return {
    searchAction,
    dropdownList,
    DateTextList,
    tableColumn,
    tableData,
    tableCheckAction,
    removeClickAction,
    selectedId,
    memberList,
  };
};

export default UseNeddleManagement;

import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";

const FeedbackDetail = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(undefined);
  const handleFeedbackDialogOpen = (data) => {
    setData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const actionClose = () => {
    setOpen(false);
  };
  const feedbackDialogComponent = (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "900px",
          },
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ m: 0, p: 2, fontSize: "16px", fontWeight: "bold" }}
      >
        피드백 상세 내용
      </DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid display="flex" alignItems="center">
          <Typography fontWeight="bold" textAlign="center">
            작성자
            <span style={{ fontSize: "11px", lineHeight: "11px" }}>
              <br />
              (전문가)
            </span>
          </Typography>
          <TextField
            value={data === undefined ? "" : data.m_name}
            sx={{ flex: 1, marginLeft: "24px" }}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid display="flex" alignItems="flex-start" marginTop={"12px"}>
          <Typography fontWeight="bold" textAlign="center" marginTop={"9px"}>
            피드백
          </Typography>
          <TextField
            value={data === undefined ? "" : data.f_contents}
            sx={{ flex: 1, marginLeft: "24px" }}
            multiline
            inputProps={{ readOnly: true }}
            minRows={6}
            maxRows={6}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={actionClose}
          autoFocus
          color="primary"
          variant="contained"
        >
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
  return { handleFeedbackDialogOpen, feedbackDialogComponent };
};

export default FeedbackDetail;

import React from "react";
import { SampleStyle, SampleWrapStyle } from "./styled";
import { Button, Chip, Grid, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MainCard from "ui-component/cards/MainCard";
import { gridSpacing } from "store/constant";

const SampleHorizontal = ({ sampleData, titleText, clickAction }) => {
  return (
    <SampleWrapStyle id="testSample">
      <MainCard
        sx={{
          "& .MuiCardContent-root": {
            width: "100%",
          },
        }}
      >
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography variant="h3">{titleText}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  onClick={() => {
                    clickAction();
                  }}
                >
                  <Typography
                    sx={{
                      position: "relative",
                      paddingRight: "24px",
                      color: "black",
                    }}
                  >
                    More
                    <ChevronRightIcon
                      sx={{
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    />
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {sampleData.length===0 &&
        <div className="empty_box">등록된 케이스가 없습니다.</div>
        }
        {sampleData.map((value, index) => {
          return (
            <>
              <SampleStyle
                imageSource={value.imageSource}
                onClick={() => {
                  if (value.indexNumber !== undefined) {
                    clickAction(value.indexNumber, value.textBadge);
                  } else {
                    clickAction(index + 1, value.textBadge);
                  }
                }}
              >
                <div>
                  {value.imageSource !== undefined && (
                    <img src={value.imageSource} alt="" />
                  )}
                  {value.imageBadge !== undefined && (
                    <Chip label={value.imageBadge} />
                  )}
                </div>
                <div>
                  {value.textBadge !== undefined ? (
                    value.textBadge ? (
                      <Chip label="답변예정" />
                    ) : (
                      <Chip label="답변완료" color="primary" />
                    )
                  ) : (
                    <></>
                  )}
                  <h1>{value.title}</h1>
                  <h2>{value.subText}</h2>
                  <p>{value.writer}</p>
                  {value.date !== undefined && <span>{value.date}</span>}
                </div>
              </SampleStyle>
            </>
          );
        })}
      </MainCard>
    </SampleWrapStyle>
  );
};

export default SampleHorizontal;

import Search from "components/common/search";
import React from "react";
import { Box } from "@mui/system";
import CustomTable from "components/common/tables/customCheckSizeTable";
import MainCard from "ui-component/cards/MainCard";
import { Button } from "@mui/material";
import CustomDialog from "components/common/customRemoveDialog";
import CustomSnackbar from "components/common/customSnackbar";
import { useEffect } from "react";
import UseFeedbackManagement from "hooks/feedbackManagement/useFeedbackManagement";

const FeedbackManagement = () => {
  const { snackbarComponent, handleSnackOpen } = CustomSnackbar();
  const { dialogComponent, handleDialogOpen } = CustomDialog(
    "피드백 삭제",
    "선택한 항목을 삭제하시겠습니까?"
  );
  const {
    selectedId,
    searchAction,
    dropdownList,
    DateTextList,
    tableColumn,
    tableData,
    tableCheckAction,
    removeClickAction,
    feedbackDialogComponent,
    memberList,
  } = UseFeedbackManagement(handleSnackOpen, handleDialogOpen);

  useEffect(() => {
    const popupText = localStorage.getItem("popupText");
    if (popupText != null) {
      localStorage.removeItem("popupText");
      handleSnackOpen(popupText);
    }
  }, [handleSnackOpen]);

  return (
    <Box>
      {dialogComponent}
      {feedbackDialogComponent}
      {snackbarComponent}
      <Search
        dropdownList={dropdownList}
        searchAction={searchAction}
        DateTextList={DateTextList}
      />
      <MainCard sx={{ marginTop: "24px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "24px",
          }}
        >
          <Button variant="contained" color="error" onClick={removeClickAction}>
            삭제
          </Button>
        </Box>
        {memberList !== undefined &&<CustomTable
          selectedId={selectedId}
          tableCheckAction={tableCheckAction}
          data={tableData}
          column={tableColumn}
        />}
      </MainCard>
    </Box>
  );
};

export default FeedbackManagement;

import { customedAxios } from "api";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

export const useGetQnaList = (searchValue) => {
  const { data, refetch } = useQuery("getQnaList", () => {
    return customedAxios
      .get(
        `/qna/getQnaList?page=1&pageSize=1000&startDate=${searchValue.startDate}&endDate=${searchValue.endDate}&searchType=${searchValue.selectedText}&searchValue=${searchValue.searchText}`
      )
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch };
};

export const useDeleteQna = () => {
  const { data, mutate, reset } = useMutation(({ list }) => {
    return customedAxios
      .delete(`/qna/deleteQna`, {
        data: { list },
      })
      .then((response) => {
        return response.data;
      });
  });
  return { mutate, data, reset };
};
export const useGetQnaByIdx = () => {
  const [searchParams] = useSearchParams();
  const q_idx = Number(searchParams.get("q_idx"));
  const { data, refetch } = useQuery("getQnaByIdx", () => {
    return customedAxios
      .get(`/qna/getQnaByIdx?q_idx=${q_idx}`)
      .then((response) => {
        return response.data;
      });
  });
  return { data, refetch };
};

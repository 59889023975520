import { Button, Chip } from "@mui/material";
import { useGetMemberList, usePutQuitMember } from "api/userManagement";
import { useEffect } from "react";
import { useState } from "react";
import { dateToStringFormat, leftPad } from "utils";

const UseUserManagement = (handleSnackOpen, handleDialogOpen) => {
  const [searchValue, setSearchValue] = useState({
    startDate: "",
    endDate: "",
    selectedText: "",
    searchText: "",
    selectedType: "",
  });
  const searchAction = (
    startDate,
    endDate,
    selectedText,
    searchText,
    selectedType
  ) => {
    setSearchValue({
      startDate:
        startDate === undefined || startDate === null
          ? ""
          : startDate.$y +
            "-" +
            leftPad(startDate.$M + 1) +
            "-" +
            leftPad(startDate.$D),
      endDate:
        endDate == null
          ? ""
          : endDate.$y +
            "-" +
            leftPad(endDate.$M + 1) +
            "-" +
            leftPad(endDate.$D),
      selectedText,
      searchText,
      selectedType: selectedType === "All" ? "" : selectedType,
    });
  };
  const { data: memberList, refetch } = useGetMemberList(searchValue);
  const { data: quitResponse, mutate } = usePutQuitMember();

  useEffect(() => {
    refetch();
  }, [searchValue, refetch]);

  useEffect(() => {
    if (quitResponse !== undefined) {
      if (quitResponse.status.code === 200) {
        refetch();
      }
    }
  }, [quitResponse, refetch]);

  const dropdownList = [
    { value: "m_name", text: "이름" },
    { value: "m_email", text: "아이디" },
  ];
  const DateTextList = ["가입일", "시작일", "종료일"];
  const tableColumn = [
    { field: "id", headerName: "No", width: 100 },
    { field: "m_name", headerName: "이름", width: 150 },
    { field: "m_email", headerName: "아이디(이메일)", width: 250 },
    { field: "trainingCnt", headerName: "실습 완료", width: 150 },
    { field: "qnaCnt", headerName: "Q&A 작성", width: 150 },
    { field: "m_regDate", headerName: "가입일", width: 150 },
    {
      field: "m_type",
      headerName: "회원 유형",
      width: 150,
      renderCell: (param) => {
        return param.value === "students" ? (
          <Chip label="학습자" />
        ) : (
          <Chip label="전문가" color="primary" />
        );
      },
    },
    {
      field: "manage",
      headerName: "관리",
      width: 100,
      sortable: false,
      renderCell: (param) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              window.location.href =
                "/userManagement/userDetail?m_idx=" + param.row.m_idx;
            }}
          >
            상세
          </Button>
        );
      },
    },
  ];
  const [selectedId, setSelectedId] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (memberList !== undefined) {
      var tableData = memberList.result;

      tableData.map((value, index) => {
        tableData[index].id = value.m_idx;
        tableData[index].id = index + 1;
        var date = new Date(value.m_regDate.split("T")[0]);
        tableData[index].m_regDate = dateToStringFormat(date, "-");
        return 0;
      });
      setTableData(tableData);
    }
  }, [memberList]);

  const removeAction = () => {
    var list = [];
    selectedId.map((value) => {
      list.push(tableData[value - 1].m_idx);
      return 0;
    });
    mutate({ list: list });
    setSelectedId([]);
    handleSnackOpen("탈퇴 처리되었습니다.");
  };

  const tableCheckAction = (ids) => {
    setSelectedId(ids);
  };

  const removeClickAction = () => {
    if (selectedId.length === 0) {
      handleSnackOpen("선택한 항목이 없습니다.");
    } else {
      handleDialogOpen(removeAction);
    }
  };

  return {
    selectedId,
    searchAction,
    dropdownList,
    DateTextList,
    tableColumn,
    tableData,
    tableCheckAction,
    removeClickAction,
    memberList,
  };
};

export default UseUserManagement;

import { Button, Chip, Divider, Grid, TextField } from "@mui/material";
import CenterBox from "components/common/centerBox";
import React from "react";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import DataLabel from "components/common/dataLabel";
import { useState } from "react";
import { useEffect } from "react";
import { Box } from "@mui/system";
import { useGetQnaByIdx } from "api/qaManagement";
import { dateToStringFormat } from "utils";

const QADetail = () => {
  const [date, setDate] = useState("");
  const [student, setStudent] = useState("");
  const [state, setState] = useState("");
  const [name, setName] = useState("");
  const [detail, setDetail] = useState("");
  const [answer, setAnswer] = useState("");
  const [expert, setExpert] = useState("");
  const [result, setResult] = useState("");

  const { data }=useGetQnaByIdx();
  
  
  useEffect(() => {
    if (data !== undefined) {
      if (data.status.code === 200) {
        setDate(dateToStringFormat(new Date(data.status.result.q_regDate.split("T")[0])));
        setStudent(data.status.result.m_name);
        setState(data.status.result.q_status ==='Y');
        setName(data.status.result.q_title);
        setDetail(data.status.result.q_contents);
        if (data.status.result.q_status === 'Y') {
          setAnswer(
            dateToStringFormat(
              new Date(data.status.result.q_answerDate.split("T")[0])
            )
          );
          setExpert(data.status.result.expert_name);
          setResult(data.status.result.q_answer);
        }
      }
    }
  }, [data]);

  return (
    <CenterBox>
      <MainCard>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={3.5}>
                <DataLabel
                  title={"작성일"}
                  children={
                    <TextField
                      inputProps={{ readOnly: true }}
                      value={date}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                      sx={{ width: "calc(100% - 100px)" }}
                    ></TextField>
                  }
                />
              </Grid>
              <Grid item xs={3.5}>
                <DataLabel
                  title={"작성자"}
                  children={
                    <TextField
                      inputProps={{ readOnly: true }}
                      value={student}
                      onChange={(e) => {
                        setStudent(e.target.value);
                      }}
                      sx={{ width: "calc(100% - 100px)" }}
                    ></TextField>
                  }
                />
              </Grid>
              <Grid item xs={3.5} sx={{ "& >div": { height: "100%" } }}>
                <DataLabel
                  title={"상태"}
                  children={
                    <Box
                      sx={{
                        width: "calc(100% - 100px)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        background: "#f8fafc",
                        border: "1px solid rgba(0, 0, 0, 0.26)",
                        borderRadius: "12px",
                      }}
                    >
                      {state ? (
                        <Chip
                          label="답변완료"
                          color="primary"
                          sx={{ fontSize: "inherit" }}
                        />
                      ) : (
                        <Chip label="답변예정" sx={{ fontSize: "inherit" }} />
                      )}
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "space-between",
                "& >div": { width: "100%" },
              }}
            >
              <DataLabel
                title={"제목"}
                children={
                  <TextField
                    inputProps={{ readOnly: true }}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    sx={{ width: "calc(100% - 100px)" }}
                  ></TextField>
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "space-between",
                "& >div": { width: "100%" },
              }}
            >
              <DataLabel
                title={"내용"}
                children={
                  <TextField
                    inputProps={{ readOnly: true }}
                    multiline
                    minRows={6}
                    maxRows={6}
                    value={detail}
                    onChange={(e) => {
                      setDetail(e.target.value);
                    }}
                    sx={{ width: "calc(100% - 100px)" }}
                  ></TextField>
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ margin: "24px 0" }} />
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={3.5}>
                <DataLabel
                  title={"답변등록일"}
                  children={
                    <TextField
                      inputProps={{ readOnly: true }}
                      value={answer}
                      onChange={(e) => {
                        setAnswer(e.target.value);
                      }}
                      sx={{ width: "calc(100% - 100px)" }}
                    ></TextField>
                  }
                />
              </Grid>
              <Grid item xs={3.5}>
                <DataLabel
                  title={"작성자"}
                  children={
                    <TextField
                      inputProps={{ readOnly: true }}
                      value={expert}
                      onChange={(e) => {
                        setExpert(e.target.value);
                      }}
                      sx={{ width: "calc(100% - 100px)" }}
                    ></TextField>
                  }
                />
              </Grid>
              <Grid item xs={3.5}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "space-between",
                "& >div": { width: "100%" },
              }}
            >
              <DataLabel
                title={"내용"}
                children={
                  <TextField
                    inputProps={{ readOnly: true }}
                    placeholder="답변이 등록되지 않았습니다."
                    multiline
                    minRows={6}
                    maxRows={6}
                    value={result}
                    onChange={(e) => {
                      setResult(e.target.value);
                    }}
                    sx={{ width: "calc(100% - 100px)" }}
                  ></TextField>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
      <Grid sx={{ width: "100%", paddingTop: "24px", textAlign: "right" }}>
        <Button
          variant={"contained"}
          onClick={() => {
            window.history.back();
          }}
        >
          확인
        </Button>
      </Grid>
    </CenterBox>
  );
};

export default QADetail;

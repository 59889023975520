import { Button, Chip } from "@mui/material";
import { useDeleteInquiry, useGetInquiryList } from "api/serviceManagement";
import { useEffect } from "react";
import { useState } from "react";
import { dateToStringFormat, leftPad } from "utils";

const UseServiceManagement = (handleSnackOpen, handleDialogOpen) => {
  const [searchValue, setSearchValue] = useState({
    startDate: "",
    endDate: "",
    selectedText: "",
    searchText: "",
  });
  const searchAction = (startDate, endDate, selectedText, searchText) => {
    setSearchValue({
      startDate:
        startDate === undefined || startDate === null
          ? ""
          : startDate.$y +
            "-" +
            leftPad(startDate.$M + 1) +
            "-" +
            leftPad(startDate.$D),
      endDate:
        endDate == null
          ? ""
          : endDate.$y +
            "-" +
            leftPad(endDate.$M + 1) +
            "-" +
            leftPad(endDate.$D),
      selectedText,
      searchText,
    });
  };
  const { data: memberList, refetch } = useGetInquiryList(searchValue);
  const { data: quitResponse, mutate } = useDeleteInquiry();
  useEffect(() => {
    refetch();
  }, [searchValue, refetch]);

  useEffect(() => {
    if (quitResponse !== undefined) {
      if (quitResponse.status.code === 200) {
        refetch();
      }
    }
  }, [quitResponse, refetch]);
  const dropdownList = [
    { value: "i_title", text: "제목" },
    { value: "m_name", text: "작성자" },
    { value: "ad_name", text: "답변 관리자" },
  ];
  const DateTextList = ["작성일", "시작일", "종료일"];
  const tableColumn = [
    { field: "id", headerName: "No", width: 150 },
    {
      field: "i_title",
      headerName: "제목",
      width: 250,
      flex: 1,
    },
    { field: "m_name", headerName: "작성자", width: 150 },
    { field: "i_regDate", headerName: "작성일", width: 150 },
    { field: "i_answerDate", headerName: "답변일", width: 150 },
    {
      field: "i_status",
      headerName: "상태",
      width: 150,
      renderCell: (param) => {
        return param.value === "Y" ? (
          <Chip label="답변완료" color="primary" />
        ) : (
          <Chip label="답변예정" />
        );
      },
    },
    { field: "ad_name", headerName: "답변 관리자", width: 150 },
    {
      field: "manage",
      headerName: "관리",
      width: 100,
      sortable: false,
      renderCell: (param) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              localStorage.setItem(
                "serviceDetailInfo",
                JSON.stringify(param.row)
              );
              window.location.href = "/serviceManagement/serviceDetail?i_idx="+param.row.i_idx;
            }}
          >
            상세
          </Button>
        );
      },
    },
  ];
  const [selectedId, setSelectedId] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (memberList !== undefined) {
      var tableData = memberList.result;

      tableData.map((value, index) => {
        tableData[index].id = index + 1;
        var regDate = new Date(value.i_regDate.split("T")[0]);
        tableData[index].i_regDate = dateToStringFormat(regDate, "-");
        if (tableData[index].i_status === "Y") {
          var answerDate = new Date(value.i_answerDate.split("T")[0]);
          tableData[index].i_answerDate = dateToStringFormat(answerDate, "-");
        } else {
          tableData[index].i_answerDate = "-";
          tableData[index].ad_name = "-";
        }
        
        return 0;
      });
      setTableData(tableData);
    }
  }, [memberList]);

  const removeAction = () => {
    var list = [];
    selectedId.map((value) => {
      list.push(tableData[value - 1].i_idx);
      return 0;
    });
    mutate({ list: list });
    setSelectedId([]);
    handleSnackOpen("삭제 완료되었습니다.");
  };
  const tableCheckAction = (ids) => {
    setSelectedId(ids);
  };

  const removeClickAction = () => {
    if (selectedId.length === 0) {
      handleSnackOpen("선택한 항목이 없습니다.");
    } else {
      handleDialogOpen(removeAction);
    }
  };

  return {
    searchAction,
    dropdownList,
    DateTextList,
    tableColumn,
    tableData,
    tableCheckAction,
    removeClickAction,
    selectedId,
    memberList,
  };
};

export default UseServiceManagement;

import styled from "@emotion/styled";

export const DataLabelStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > p {
    font-weight: bold;
    white-space: pre-line;
  }
`;

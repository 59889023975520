import { customedAxios } from "api";
import { useMutation, useQuery } from "react-query";

export const useLogin = (id, password) => {
  const { data } = useQuery(["signin"], () =>
    customedAxios.get(`/admin/signin`, {
      data: {
        id,
        password,
      },
    })
  );

  return { data };
};

export const useSignIn = () => {
  const { data, mutate } = useMutation(({ id, password }) => {
    return customedAxios
      .post(`/admin/signin`, {
        id: id,
        password: password,
      })
      .then((response) => {
        return response.data;
      });
  });
  return { mutate, data };
};

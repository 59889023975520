import styled from "@emotion/styled";

export const SampleWrapStyle = styled.div`
  width: 100%;
  > div:first-of-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
  > div:first-of-type span {
    display: flex;
    cursor: pointer;
  }
  hr {
    width: 100%;
    margin: 12px 0;
  }
`;
export const SampleStyle = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  position: relative;
  cursor: pointer;
  height: 140px;

  > div:nth-of-type(1) {
    position: relative;
    display: ${(props) => (props.imageSource === undefined ? "none" : "block")};
    width: 140px;
    height: 140px;
  }
  > div:nth-of-type(1) img {
    width: 100%;
    height: 140px;
    border-radius: 16px;
  }
  > div:nth-of-type(1) div {
    position: absolute;
    bottom: 9px;
    right: 6px;
    background: rgba(255, 255, 255, 0.8);
    font-weight: bold;
  }
  > div:nth-of-type(2) {
    width: ${(props) =>
      props.imageSource === undefined ? "100%" : "calc(100% - 160px)"};
    margin-left: ${(props) => (props.imageSource === undefined ? "0" : "20px")};
  }
  h1 {
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 4px;
  }
  h2 {
    font-size: 12px;
    margin-bottom: 12px;
  }
  p {
    font-size: 12px;
  }
  > div:nth-of-type(2) > div {
    margin-bottom: 4px;
  }
  > div:nth-of-type(2) > span {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
